import { Progress } from "@mantine/core";
import { useEffect, useState } from "react";

export default function CountdownBar({ onZero, isStopped }) {
  const [counter, setCounter] = useState(100);

  useEffect(() => {
    if (counter === 0) {
      onZero();
    } else if (!isStopped) {
      counter > 0 && setTimeout(() => setCounter(counter - 0.5), 50);
    }
  }, [counter]);

  return <Progress size="lg" color="teal" value={counter} />;
}
