import { TextInput, Group, Button, Alert } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { useState } from "react";
import Emoji from "../../../components/universal-components/emoji.component";
import { AlertCircle } from 'tabler-icons-react';

export default function MovieRequest() {
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false)

  const form = useForm({
    initialValues: {
      email: "",
      title: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = (values) => {
    fetch(
      `https://hooks.zapier.com/hooks/catch/9899583/b8e0dc7?title=${values.title}&email=${values.email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSubmitted(true);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  return (
    <div className="flex flex-col items-center justify-center">
      {submitted ? (
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-lg font-bold">
            <Emoji symbol="👨‍🚀" /> Submitted!
          </h2>
          <Button
            onClick={() => {
              form.reset();
              setSubmitted(false);
              setIsError(false);
            }}
            color="orange"
            className="mt-2"
          >
            Request another
          </Button>
        </div>
      ) : (
        <div className="bg-gray-100 px-8 pt-6 pb-8 space-y-4 rounded-lg border-4">
          {isError && (
            <Alert
              icon={<AlertCircle size={16} />}
              title="Hmmm..."
              color="yellow"
              withCloseButton
              variant="filled"
              className="mb-6"
            >
              Please make sure all fields are filled out properly.
            </Alert>
          )}
          <h2 className="text-lg font-bold">
            <Emoji symbol="🙀" /> Your movie seems to be missing. Request it
            here:{" "}
          </h2>
          <form
            className="flex flex-col space-y-3"
            onSubmit={form.onSubmit((values) => handleSubmit(values))}
          >
            <TextInput
              placeholder="E.g., Interstellar"
              label="Enter Movie Title"
              description="If there are more than one please add the year it was released."
              required
              {...form.getInputProps("title")}
            />
            <TextInput
              placeholder="E.g., murph@nasa.com"
              label="Get Notified"
              description="Enter your email to get notified when it's added (should get it done in about a day)."
              required
              {...form.getInputProps("email")}
            />
            <Group position="right" mt="md">
              <Button type="submit" color="orange" className="mt-2">
                Submit
              </Button>
            </Group>
          </form>
        </div>
      )}
    </div>
  );
}
