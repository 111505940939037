import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { setCurrentDecks } from "../../../redux/decks/decks.actions";
import EditWordPopup from "./edit-word-popup.component";
import FlashcardSession from "./flashcard-session.component";
import EndScreen from "./session-components/end-screen.component";
import SessionNavigation from "./session-navigation.component";
import QuizSession from "./quiz-session.component";
import LearnNavigation from "./learn-navigation.component";

export default function Session(props) {
  const userApiId = useSelector((state) => state.decks.user_api_id);
  const dispatch = useDispatch();
  const router = useRouter();

  const deckId = props.match.params.deckId;
  const textId = props.match.params.textId;
  const sessionType = props.match.params.sessionType;
  const sessionLength = props.match.params.sessionLength;
  const itemType = props.match.params.itemType;

  const [index, setIndex] = useState(0);
  const [exercises, setExercises] = useState(null);
  const [skipped, setSkipped] = useState(0);

  const [isEnd, setIsEnd] = useState(false);
  const [emptyBatch, setEmptyBatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditWordPopup, setIsEditWordPopup] = useState(false);
  const [isDisableEventListener, setIsDisableEventListener] = useState(false);

  const [feedbackStore, setFeedbackStore] = useState([]);

  useEffect(() => {
    getBatch();
    setIsLoading(true);
  }, [sessionType]);

  const getBatch = () => {
    const headers = new Headers({ Authorization: props.token });
    let url = `${process.env.REACT_APP_API_URL}/session?batch_size=${sessionLength}&deck_id=${deckId}&mode=${sessionType}`;
    if (itemType === "all") {
      url = `${process.env.REACT_APP_API_URL}/session-all/${userApiId}?batch_size=${sessionLength}&mode=${sessionType}`;
    } else if (itemType === "text") {
      url = `${process.env.REACT_APP_API_URL}/session?batch_size=${sessionLength}&deck_id=${deckId}&text_id=${textId}&mode=${sessionType}`;
    }

    fetch(url, { method: "GET", redirect: "follow", headers: headers })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.batch.length === 0) {
          setExercises([]);
          setEmptyBatch(true);
        } else {
          setExercises(data.batch);
        }
        setIsLoading(false);
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleEndSession = () => {
    const headers = new Headers({ Authorization: props.token });
    fetch(`${process.env.REACT_APP_API_URL}/decks/${userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => dispatch(setCurrentDecks(data.decks)))
      .catch((error) => console.log(error));

    router.history.push("/app/learn/" + router.match.params.deckId);
  };

  const handleNext = () => {
    if (index + 1 === exercises.length) {
      setIsLoading(true);
      setIsEnd(true);
      getBatch();
    } else {
      setIndex(index + 1);
    }
  };

  const handleUndo = () => {
    if (skipped > 0) {
      setSkipped(skipped - 1);
      setIndex(index - 1);
    } else if (index >= 1) {
      const e = exercises[index - 1];
      fetch(
        `${process.env.REACT_APP_API_URL}/history?word_id=${e["word_info"]["id"]}&user_id=${userApiId}`,
        { method: "DELETE", redirect: "follow" }
      )
        .then(() => setIndex(index - 1))
        .catch((error) => window.alert(error));
    } else {
      console.log("can't undo, first exercise");
    }
  };

  const handleSkip = () => {
    const e = exercises[index];
    if (sessionType === "flashcards") {
      fetch(
        `${process.env.REACT_APP_API_URL}/history?word_id=${e["word_info"]["id"]}&user_id=${userApiId}`,
        { method: "PUT", redirect: "follow" }
      )
        .then(() => handleNext())
        .catch((error) => window.alert(error));
    } else {
      handleNext();
    }
    setSkipped(skipped + 1);
  };

  const handleEdit = () => {
    setIsEditWordPopup(true);
  };

  const handleNewSession = () => {
    if (sessionType === "flashcards") {
      setIsLoading(true);
      getBatch();
    }
    setIsEnd(false);
    setIndex(0);
  };

  return (
    <div className="bg-yellow-50 max-w-full min-h-screen pt-3">
      <div className="max-w-7xl flex flex-row justify-between mx-auto mt-8 mb-2">
        <div>
          <LearnNavigation location={sessionType} onBack={handleEndSession} />
        </div>
        {/* <div className="">Settings</div> */}
      </div>
      {(isEnd || emptyBatch) && (
        <div className="flex flex-col bg-white relative sm:rounded-t-xl max-w-7xl mx-auto min-h-screen shadow-md">
          <div className="pt-24">
            <EndScreen
              noNewExercises={emptyBatch}
              onNewSession={handleNewSession}
              deckId={deckId}
              itemType={itemType}
            />
          </div>
        </div>
      )}
      {!isEnd && isLoading && (
        <div className="flex flex-col bg-white relative sm:rounded-t-xl max-w-7xl mx-auto min-h-screen shadow-md items-center justify-center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="white"
            color="yellow.500"
            size="lg"
          />
          <p className="mt-4 text-gray-900 font-medium">Loading exercises...</p>
        </div>
      )}
      {!isLoading && !isEnd && exercises?.length > 0 && (
        <div className="flex flex-col bg-white relative sm:rounded-t-xl max-w-7xl mx-auto min-h-screen shadow-md">
          <SessionNavigation
            onSkip={handleSkip}
            onUndo={handleUndo}
            onEdit={handleEdit}
            isEdit={
              exercises[index]["type"] !== "assess_by_list" &&
              exercises[index]["type"] !== "assess"
            }
            isSkip={sessionType === "flashcards"}
            isUndo={sessionType === "flashcards"}
          />
          {sessionType === "flashcards" && (
            <div className="pt-12">
              <FlashcardSession
                key={sessionType}
                onNext={handleNext}
                exercise={exercises[index]}
                itemsDone={index}
                itemsOpen={exercises.length}
                isDisableEventListener={isDisableEventListener}
              />
            </div>
          )}
          {sessionType === "quiz" && (
            <QuizSession
              key={sessionType}
              onNext={handleNext}
              exercise={exercises[index]}
              itemsDone={index}
              itemsOpen={exercises.length}
              isDisableEventListener={isDisableEventListener}
              onFeedback={(value) =>
                setFeedbackStore((prev) => [...prev, value])
              }
            />
          )}
          {exercises[index]["type"] !== "assess_by_list" &&
            exercises[index]["type"] !== "assess" &&
            exercises[index]["word_info"]["id"] && (
              <EditWordPopup
                isOpen={isEditWordPopup}
                onClose={() => setIsEditWordPopup(false)}
                onDelete={() => handleNext()}
                wordId={exercises[index]["word_info"]["id"]}
                removeEventListener={() => setIsDisableEventListener(true)}
                resumeEventListener={() => setIsDisableEventListener(false)}
              />
            )}
        </div>
      )}
    </div>
  );
}
