import React from "react";
import { connect } from "react-redux";
import WordInput from "../../../universal-components/form-inputs/word-input.component";
import {
  setCurrentDecks,
  setCurrentTranslationLanguage,
} from "../../../../redux/decks/decks.actions";
import SectionHeading from "../../../../pages/app/app-page-components/section-heading.component";
import { languages_dict } from "../../../app-configuration/dropdown-inputs";
import Notification from "../../../universal-components/notification.component";

class AddDeck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deckName: "",
      translationLanguage: null,

      // notifications
      isNotification: false,
      notificationTitle: "",
      notificationDescription: "",
      notificationStatus: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ translationLanguage: this.props.translationLanguage });
  }

  assertSubmit = () => {
    if (this.state.deckName === "") {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Enter a name for your deck",
        notificationStatus: "error",
      });
    } else if (
      this.state.defaultCardType === "" ||
      this.state.defaultCardType === "CT"
    ) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Select at least one default card type",
        notificationStatus: "error",
      });
    } else if (
      !this.state.translationLanguage &&
      this.props.targetLanguage === "en"
    ) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Please select a translation language",
        notificationStatus: "error",
      });
    } else if (!this.props.targetLanguage) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Select at least one default card type",
        notificationStatus: "error",
      });
    } else if (
      this.props.currentDecks.map((d) => d.name).includes(this.state.deckName)
    ) {
      window.alert("A deck with this name exists already.");
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const headers = new Headers({ Authorization: this.props.token });

    const name = this.state.deckName;
    const userApiId = this.props.userApiId;
    let translationLanguage;

    if (this.props.targetLanguage === "en") {
      translationLanguage = this.state.translationLanguage;
    } else {
      translationLanguage = "en";
    }

    // TODO: ADD STORY
    if (["list", "of", "the", "users", "decks"].includes(name)) {
      window.alert("This deck name already exists.");
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/deck/000?name=${name}&study_language=${this.props.targetLanguage}&translation_language=${translationLanguage}&user_id=${userApiId}`,
        { method: "POST", redirect: "follow", headers: headers }
      )
        .then((response) => response.text())
        .then((result) => {
          this.setState({
            isNotification: true,
            notificationTitle: "Added deck successfully!",
            notificationDescription: "",
            notificationStatus: "success",
          });
          this.handleReset();
        })
        .catch((error) => {
          this.setState({
            isNotification: true,
            notificationTitle: "Error",
            notificationDescription: "An error occurred uploading your deck.",
            notificationStatus: "error",
          });
          this.handleReset();
        });
    }
  };

  handleReset = () => {
    this.setState({
      deckName: "",
      defaultCardType: null,
    });

    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/decks/${this.props.userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentDecks(data.decks);
      })
      .catch((error) => console.error(error));
  };

  handleTranslationLanguageChange = (event) => {
    const translationLanguageNew = event.target.value;
    this.setState({ translationLanguage: translationLanguageNew });

    // send new default to backend
    fetch(
      `${process.env.REACT_APP_API_URL}/user-language/${
        this.props.userApiId
      }?study_language=${""}&translation_language=${translationLanguageNew}`,
      { method: "PUT", redirect: "follow" }
    )
      .then(() => console.log(translationLanguageNew))
      .catch((error) => window.alert(error));

    // update global state
    this.props.setCurrentTranslationLanguage(translationLanguageNew);
  };

  render() {
    // TODO: import from shared file
    const codeToLanguage = {
      en: "English",
      de: "German",
      es: "Spanish",
      fr: "French",
      it: "Italian",
      pt: "Portuguese",
    };

    return (
      <div>
        {this.state.isNotification && (
          <Notification
            title={this.state.notificationTitle}
            description={this.state.notificationDescription}
            status={this.state.notificationStatus}
            onMount={() => this.setState({ isNotification: false })}
          />
        )}
        <SectionHeading headingText="Add A New Deck" isButton={false} />

        <div className="max-w-lg">
          <div className="flex flex-col pb-6 space-y-4">
            <WordInput
              inputValue={this.state.deckName}
              onInputChange={(event) =>
                this.setState({ deckName: event.target.value })
              }
              textMainHeadline=""
              textSubHeadline=""
              textMain="Add Deck Name"
              textOptional=""
            />
            <div className="max-w-lg space-y-2">
              <div>
                <p className="text-sm font-medium text-gray-700">
                  Target Language
                </p>
                <p className="text-sm text-gray-900">
                  {codeToLanguage[this.props.targetLanguage]}
                </p>
              </div>

              {this.props.targetLanguage === "en" ? (
                <div className="space-y-2">
                  <p className="text-sm font-medium text-gray-700">
                    Select Translation Language
                  </p>
                  <select
                    onChange={this.handleTranslationLanguageChange}
                    value={this.state.translationLanguage}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
                  >
                    {!this.state.translationLanguage && (
                      <option hidden="true">Select Translation Language</option>
                    )}
                    {languages_dict[this.props.targetLanguage].translations.map(
                      (lang) => (
                        <option value={lang.code}>{lang.name}</option>
                      )
                    )}
                  </select>
                </div>
              ) : (
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Translation Language
                  </p>
                  <p className="text-sm text-gray-900">English</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={this.props.history.goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Cancel
            </button>
            <button
              onClick={this.assertSubmit}
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
  setCurrentTranslationLanguage: (target_language) =>
    dispatch(setCurrentTranslationLanguage(target_language)),
});

const mapStateToProps = ({ decks, user }) => ({
  userApiId: decks.user_api_id,
  targetLanguage: decks.target_language,
  translationLanguage: decks.translation_language,
  currentDecks: decks.currentDecks,
  token: user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDeck);
