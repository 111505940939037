import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ProgrammaticQuizList() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/decks-quiz/${parseInt(
        process.env.REACT_APP_ADMIN_API_ID
      )}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setItems(data.decks);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {items?.map((e) => (
        <Link
          to={`/quiz/${e.id}/${e.name
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .split(" ")
            .join("-")}`}
          className="font-medium text-lg"
        >
          {e.name}
        </Link>
      ))}
    </>
  );
}
