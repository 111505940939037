export const editDistance = (stringA, stringB) => {
  let s1 = stringA;
  let s2 = stringB;
  if (s1.length > s2.length) {
    s1 = stringB;
    s2 = stringA;
  }
  let distances = [...Array(s1.length + 1).keys()];

  const stringArray1 = [...s1]
  const stringArray2 = [...s2]

  for (const [i2, c2] of stringArray2.entries()) {
    let distances_ = [i2 + 1];
    for (const [i1, c1] of stringArray1.entries()) {
      if (c1 === c2) {
        distances_.push(distances[i1]);
      } else {
        distances_.push(
          1 + Math.min(distances[i1], distances[i1 + 1], distances_[distances_.length - 1])
        );
      }
    }
    distances = distances_;
  }

  return distances[distances.length - 1];
};
