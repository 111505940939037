import { Checkbox, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import SectionHeading from "../../../../pages/app/app-page-components/section-heading.component";
import WordSelectionList from "../../../../pages/onboarding/onboarding-subpages/onboarding-subpages-components/word-selection-list.component";
import DeckDropdown from "../../../universal-components/dropdowns/deck-dropdown.component";

export default function LibraryItem() {
  const router = useRouter();
  const userApiId = useSelector((state) => state.decks.user_api_id);

  const [words, setWords] = useState([]);
  const [knownWords, setKnownWords] = useState([]);
  const [markKnown, setMarkKnown] = useState(true);
  const [deckId, setDeckId] = useState([]);
  const [movieInfo, setMovieInfo] = useState(null);
  const [selectedWords, setSelectedWords] = useState([]);

  const toast = useToast();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/known-words/${userApiId}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        setKnownWords(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/library/${router.match.params.itemTitle}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setWords(
          data.words
            .filter(
              (e) =>
                e.word_difficulty > 0 && !knownWords.includes(e.word_pos_key)
            )
            .slice(0, 50)
        );
        setMovieInfo(data.info);
      })
      .catch((error) => console.error(error));
  }, [router, knownWords]);

  const handleSubmit = () => {
    for (let i = 0; i < words.length; i++) {
      const wordRoot = words[i].word_root;
      const wordPos = words[i].pos;
      if (selectedWords.includes(words[i].word_pos_key)) {
        const examples = JSON.stringify(
          words[i].original_words_list.map((e) => e.sent).slice(0, 15)
        );
        fetch(
          `${
            process.env.REACT_APP_API_URL
          }/word/1?word_root=${wordRoot}&translation=${""}&definition=${""}&examples=${examples}&pos=${wordPos}&deck_id=${parseInt(
            deckId
          )}
            &user_id=${userApiId}`,
          { method: "POST", redirect: "follow" }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error));
      } else {
        if (markKnown) {
          const headers = new Headers({ Authorization: token });
          fetch(
            `${process.env.REACT_APP_API_URL}/known-words/${userApiId}?word_root=${wordRoot}&word_original=${wordRoot}&pos=${wordPos}&language=${movieInfo.text_language}`,
            { method: "POST", redirect: "follow", headers: headers }
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => console.log(error));
        }
      }
    }
    toast({
      title: "Added successfully",
      description: "",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <div>
      <SectionHeading
        headingText={"Library: " + movieInfo?.name}
        isButton={true}
        buttonText="Back to Library"
        buttonLink="/app/library"
      />
      <p className="text-lg font-medium pb-4">
        These are the 50 most difficult words from the {movieInfo?.type} (that
        are not in your known words). Click the words you want to add to your
        deck.
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="space-y-3">
          <WordSelectionList
            words={words}
            selectedWords={selectedWords}
            setSelectedWords={(value) => setSelectedWords(value)}
            componentStyle="app"
          />
        </div>
        <div className="flex flex-col space-y-4">
          <DeckDropdown onDeckChange={(value) => setDeckId(value)} />
          <Checkbox
            onChange={() => setMarkKnown((prev) => !prev)}
            isChecked={markKnown}
            colorScheme="orange"
          >
            Mark other words as known
          </Checkbox>
          <button
            onClick={() => handleSubmit()}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-gray-200 hover:bg-gray-300"
          >
            Add Selected Words
          </button>
        </div>
      </div>
    </div>
  );
}