import React from "react";

// <WordInput inputValue={} onInputChange={} textMainHeadline="" textSubHeadline="" textMain="" textOptional=""/>

const WordInput = (props) => (
  <div>
    {(props.textMainHeadline || props.textSubHeadline) && (
      <div className="pb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {props.textMainHeadline}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{props.textSubHeadline}</p>
      </div>
    )}

    <div className="flex justify-between">
      <label className="block text-sm font-medium text-gray-700">
        {props.textMain}
      </label>
      <span className="text-sm text-gray-500" id="email-optional">
        {props.textOptional}
      </span>
    </div>
    <div className="mt-1">
      <input
        name="word"
        type="text"
        value={props.inputValue}
        onChange={props.onInputChange}
        id="word"
        class="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  </div>
);

export default WordInput;
