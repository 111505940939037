import { IconButton, Tooltip } from "@chakra-ui/react";
import { useHotkeys } from "@mantine/hooks";

export default function SessionNavigation({
  onSkip,
  onUndo,
  onEdit,
  isEdit = true,
  isSkip = true,
  isUndo = true,
}) {
  useHotkeys([
    [
      "S",
      () => {
        /* TODO: disableEventListener */
        if (!false && isSkip) {
          onSkip();
        }
      },
    ],
    [
      "mod+Z",
      () => {
        /* TODO: disableEventListener */
        if (!false && isUndo) {
          onUndo();
        }
      },
    ],
    [
      "E",
      () => {
        if (isEdit) onEdit();
      },
    ],
  ]);

  return (
    <>
      <div className="absolute top-6 right-6">
        {isUndo && (
          <Tooltip
            hasArrow
            label="Undo action (cmd/ctrl + z)"
            bg="gray.600"
            openDelay={500}
          >
            <IconButton
              aria-label="Undo"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-700 -mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
                  />
                </svg>
              }
              colorScheme="white"
              variant="solid"
              size="md"
              onClick={onUndo}
            />
          </Tooltip>
        )}
        {isSkip && (
          <Tooltip
            hasArrow
            label="Skip word (press 's' key)"
            bg="gray.600"
            openDelay={500}
          >
            <IconButton
              aria-label="Skip"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-700"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
                  />
                </svg>
              }
              colorScheme="white"
              variant="solid"
              size="md"
              onClick={onSkip}
            />
          </Tooltip>
        )}
        {isEdit && (
          <Tooltip
            hasArrow
            label="Edit word (press 'e' key)"
            bg="gray.600"
            openDelay={500}
          >
            <IconButton
              aria-label="Edit Word"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-700"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              }
              colorScheme="white"
              variant="solid"
              size="md"
              onClick={() => onEdit(true)}
            />
          </Tooltip>
        )}
      </div>
    </>
  );
}
