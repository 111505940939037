import { Component } from "react";
import { Redirect } from "react-router-dom";
import ConfirmDeletePopup from "./confirm-delete-popup.component";
import { connect } from "react-redux";
import { setCurrentUploads } from "../../redux/decks/decks.actions";

class TextDeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: false,
      renderRedirectDashboard: false,
      responseMessage: "",
      confirmDeletePopup: false,
    };
  }

  submitDelete = () => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/text/${this.props.textId}`, {
      method: "DELETE",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => this.refresh(data.message))
      .catch((error) => window.alert(error));
  };

  refresh = (message) => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/all-uploads/${this.props.userApiId}`,
      { method: "GET", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentUploads(data.uploads);
      })
      .then(
        this.setState({
          renderRedirectDashboard: true,
          responseMessage: message,
        })
      )
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <div>
        <button
          onClick={() => this.setState({ confirmDeletePopup: true })}
          type="button"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Delete Text
        </button>
        {this.state.renderRedirectDashboard && (
          <Redirect to={`/app?message=${this.state.responseMessage}`} />
        )}
        {this.state.confirmDeletePopup && (
          <ConfirmDeletePopup
            popUpHeadline="Delete text"
            popUpText="If you click delete, this text and all its words will be removed. Words you have already added will remain in the deck."
            deleteButtonText="Delete Text"
            onDelete={this.submitDelete}
            onCancel={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUploads: (uploads) => dispatch(setCurrentUploads(uploads)),
});

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
});

export default connect(mapStateToProps, mapDispatchToProps)(TextDeleteButton);
