export const textLanguages = [
  {
    code: "en",
    name: "English",
    symbol: "🇬🇧",
  },
  {
    code: "fr",
    name: "French",
    symbol: "🇫🇷",
  },
  {
    code: "es",
    name: "Spanish",
    symbol: "🇪🇸",
  },
  {
    code: "de",
    name: "German",
    symbol: "🇩🇪",
  },
  {
    code: "it",
    name: "Italian",
    symbol: "🇮🇹",
  },
  {
    code: "pt",
    name: "Portuguese",
    symbol: "🇵🇹",
  },
];

export const textOptions = [
  {
    name: "Alice in Wonderland",
    url: "https://firebasestorage.googleapis.com/v0/b/vocably-66cac.appspot.com/o/assets%2Falice_in_wonderland.txt?alt=media&token=8bfeea31-7435-408c-b7be-54dd021f5fce",
    description:
      "The well-known English novel by Lewis Carroll from 1865 counting 1351 unique words",
    symbol: "📖",
    fileType: "plain",
    wordAssessment: [],
    textLanguages: "en",
  },
  {
    name: "The Autobiography of Benjamin Franklin - Chapter 1-5",
    url: "https://firebasestorage.googleapis.com/v0/b/vocably-66cac.appspot.com/o/assets%2Fbenjamin_franklin_ch1-5.txt?alt=media&token=a99f2e49-c716-4ffb-91f4-30ae3a1816cc",
    description:
      "Chapters 1 to 5 of Benjamin Franklin's unfinished account of his life, including 1182 unique words.",
    symbol: "📖",
    fileType: "plain",
    wordAssessment: [],
    textLanguages: "en",
  },
  {
    name: "The Great Gatsby - Chapter 1",
    url: "https://firebasestorage.googleapis.com/v0/b/vocably-66cac.appspot.com/o/assets%2Fgreat_gatsby_ch1.txt?alt=media&token=a0ffc3ca-18ed-4c2d-8c9f-c60f07fd3d4b",
    description:
      "Chapter 1 of the famous English novel by F. Scott Fitzgerald from 1925 with 744 unique words.",
    symbol: "📖",
    fileType: "plain",
    wordAssessment: [
      {
        word_root: "use",
        pos: "NOUN",
        word_pos_key: "use-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0014,
      },
      {
        word_root: "part",
        pos: "NOUN",
        word_pos_key: "part-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00485,
      },
      {
        word_root: "world",
        pos: "NOUN",
        word_pos_key: "world-NOUN",
        text_frequency: 2,
        word_difficulty: 0.005,
      },
      {
        word_root: "cost",
        pos: "NOUN",
        word_pos_key: "cost-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01205,
      },
      {
        word_root: "restriction",
        pos: "NOUN",
        word_pos_key: "restriction-NOUN",
        text_frequency: 1,
        word_difficulty: 0.18265,
      },
      {
        word_root: "whatsoever",
        pos: "ADV",
        word_pos_key: "whatsoever-ADV",
        text_frequency: 1,
        word_difficulty: 0.3763433333270161,
      },
      {
        word_root: "copy",
        pos: "VERB",
        word_pos_key: "copy-VERB",
        text_frequency: 1,
        word_difficulty: 0.02465,
      },
      {
        word_root: "away",
        pos: "ADV",
        word_pos_key: "away-ADV",
        text_frequency: 1,
        word_difficulty: 0.01405,
      },
      {
        word_root: "use",
        pos: "VERB",
        word_pos_key: "use-VERB",
        text_frequency: 1,
        word_difficulty: 0.0014,
      },
      {
        word_root: "term",
        pos: "NOUN",
        word_pos_key: "term-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02655,
      },
      {
        word_root: "include",
        pos: "VERB",
        word_pos_key: "include-VERB",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "online",
        pos: "ADV",
        word_pos_key: "online-ADV",
        text_frequency: 1,
        word_difficulty: 0.00235,
      },
      {
        word_root: "locate",
        pos: "VERB",
        word_pos_key: "locate-VERB",
        text_frequency: 2,
        word_difficulty: 0.13745,
      },
      {
        word_root: "check",
        pos: "VERB",
        word_pos_key: "check-VERB",
        text_frequency: 1,
        word_difficulty: 0.0116,
      },
      {
        word_root: "law",
        pos: "NOUN",
        word_pos_key: "law-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0126,
      },
      {
        word_root: "country",
        pos: "NOUN",
        word_pos_key: "country-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0132,
      },
      {
        word_root: "title",
        pos: "NOUN",
        word_pos_key: "title-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02,
      },
      {
        word_root: "author",
        pos: "NOUN",
        word_pos_key: "author-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0186,
      },
      {
        word_root: "date",
        pos: "NOUN",
        word_pos_key: "date-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00675,
      },
      {
        word_root: "recently",
        pos: "ADV",
        word_pos_key: "recently-ADV",
        text_frequency: 1,
        word_difficulty: 0.0327,
      },
      {
        word_root: "update",
        pos: "VERB",
        word_pos_key: "update-VERB",
        text_frequency: 1,
        word_difficulty: 0.03185,
      },
      {
        word_root: "language",
        pos: "NOUN",
        word_pos_key: "language-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02165,
      },
      {
        word_root: "produce",
        pos: "VERB",
        word_pos_key: "produce-VERB",
        text_frequency: 2,
        word_difficulty: 0.0494,
      },
      {
        word_root: "project",
        pos: "NOUN",
        word_pos_key: "project-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0101,
      },
      {
        word_root: "base",
        pos: "VERB",
        word_pos_key: "base-VERB",
        text_frequency: 1,
        word_difficulty: 0.0298,
      },
      {
        word_root: "transcription",
        pos: "NOUN",
        word_pos_key: "transcription-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1998,
      },
      {
        word_root: "table",
        pos: "NOUN",
        word_pos_key: "table-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0233,
      },
      {
        word_root: "wear",
        pos: "VERB",
        word_pos_key: "wear-VERB",
        text_frequency: 1,
        word_difficulty: 0.07755,
      },
      {
        word_root: "gold",
        pos: "NOUN",
        word_pos_key: "gold-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0553,
      },
      {
        word_root: "hat",
        pos: "NOUN",
        word_pos_key: "hat-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1377,
      },
      {
        word_root: "bounce",
        pos: "VERB",
        word_pos_key: "bounce-VERB",
        text_frequency: 3,
        word_difficulty: 0.27135,
      },
      {
        word_root: "high",
        pos: "ADV",
        word_pos_key: "high-ADV",
        text_frequency: 1,
        word_difficulty: 0.0051,
      },
      {
        word_root: "till",
        pos: "SCONJ",
        word_pos_key: "till-SCONJ",
        text_frequency: 1,
        word_difficulty: 0.10305,
      },
      {
        word_root: "cry",
        pos: "VERB",
        word_pos_key: "cry-VERB",
        text_frequency: 1,
        word_difficulty: 0.15395,
      },
      {
        word_root: "hat",
        pos: "VERB",
        word_pos_key: "hat-VERB",
        text_frequency: 1,
        word_difficulty: 0.1377,
      },
      {
        word_root: "high",
        pos: "ADJ",
        word_pos_key: "high-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0051,
      },
      {
        word_root: "lover",
        pos: "NOUN",
        word_pos_key: "lover-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1857,
      },
      {
        word_root: "young",
        pos: "ADJ",
        word_pos_key: "young-ADJ",
        text_frequency: 2,
        word_difficulty: 0.0155,
      },
      {
        word_root: "vulnerable",
        pos: "ADJ",
        word_pos_key: "vulnerable-ADJ",
        text_frequency: 1,
        word_difficulty: 0.16295,
      },
      {
        word_root: "year",
        pos: "NOUN",
        word_pos_key: "year-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00225,
      },
      {
        word_root: "father",
        pos: "NOUN",
        word_pos_key: "father-NOUN",
        text_frequency: 2,
        word_difficulty: 0.05165,
      },
      {
        word_root: "give",
        pos: "VERB",
        word_pos_key: "give-VERB",
        text_frequency: 1,
        word_difficulty: 0.011,
      },
      {
        word_root: "advice",
        pos: "NOUN",
        word_pos_key: "advice-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03245,
      },
      {
        word_root: "turn",
        pos: "VERB",
        word_pos_key: "turn-VERB",
        text_frequency: 1,
        word_difficulty: 0.0254,
      },
      {
        word_root: "mind",
        pos: "NOUN",
        word_pos_key: "mind-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0266,
      },
      {
        word_root: "feel",
        pos: "VERB",
        word_pos_key: "feel-VERB",
        text_frequency: 1,
        word_difficulty: 0.01865,
      },
      {
        word_root: "like",
        pos: "SCONJ",
        word_pos_key: "like-SCONJ",
        text_frequency: 1,
        word_difficulty: 0.0015,
      },
      {
        word_root: "criticize",
        pos: "VERB",
        word_pos_key: "criticize-VERB",
        text_frequency: 1,
        word_difficulty: 0.39605,
      },
      {
        word_root: "tell",
        pos: "VERB",
        word_pos_key: "tell-VERB",
        text_frequency: 1,
        word_difficulty: 0.02295,
      },
      {
        word_root: "remember",
        pos: "VERB",
        word_pos_key: "remember-VERB",
        text_frequency: 1,
        word_difficulty: 0.03785,
      },
      {
        word_root: "people",
        pos: "NOUN",
        word_pos_key: "people-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "advantage",
        pos: "NOUN",
        word_pos_key: "advantage-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0608,
      },
      {
        word_root: "unusually",
        pos: "ADV",
        word_pos_key: "unusually-ADV",
        text_frequency: 1,
        word_difficulty: 0.34715,
      },
      {
        word_root: "communicative",
        pos: "ADJ",
        word_pos_key: "communicative-ADJ",
        text_frequency: 1,
        word_difficulty: 0.49125,
      },
      {
        word_root: "reserved",
        pos: "ADJ",
        word_pos_key: "reserved-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0078,
      },
      {
        word_root: "way",
        pos: "NOUN",
        word_pos_key: "way-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02655,
      },
      {
        word_root: "understand",
        pos: "VERB",
        word_pos_key: "understand-VERB",
        text_frequency: 1,
        word_difficulty: 0.02735,
      },
      {
        word_root: "mean",
        pos: "VERB",
        word_pos_key: "mean-VERB",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "great",
        pos: "ADJ",
        word_pos_key: "great-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0054,
      },
      {
        word_root: "deal",
        pos: "NOUN",
        word_pos_key: "deal-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "consequence",
        pos: "NOUN",
        word_pos_key: "consequence-NOUN",
        text_frequency: 1,
        word_difficulty: 0.17325,
      },
      {
        word_root: "incline",
        pos: "VERB",
        word_pos_key: "incline-VERB",
        text_frequency: 1,
        word_difficulty: 0.5504,
      },
      {
        word_root: "reserve",
        pos: "VERB",
        word_pos_key: "reserve-VERB",
        text_frequency: 1,
        word_difficulty: 0.10105,
      },
      {
        word_root: "judgement",
        pos: "NOUN",
        word_pos_key: "judgement-NOUN",
        text_frequency: 2,
        word_difficulty: 0.25785,
      },
      {
        word_root: "habit",
        pos: "NOUN",
        word_pos_key: "habit-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2189,
      },
      {
        word_root: "open",
        pos: "VERB",
        word_pos_key: "open-VERB",
        text_frequency: 1,
        word_difficulty: 0.0114,
      },
      {
        word_root: "curious",
        pos: "ADJ",
        word_pos_key: "curious-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1743,
      },
      {
        word_root: "nature",
        pos: "NOUN",
        word_pos_key: "nature-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03435,
      },
      {
        word_root: "victim",
        pos: "NOUN",
        word_pos_key: "victim-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1314,
      },
      {
        word_root: "veteran",
        pos: "NOUN",
        word_pos_key: "veteran-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1991,
      },
      {
        word_root: "bore",
        pos: "NOUN",
        word_pos_key: "bore-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2578,
      },
      {
        word_root: "abnormal",
        pos: "ADJ",
        word_pos_key: "abnormal-ADJ",
        text_frequency: 1,
        word_difficulty: 0.2517,
      },
      {
        word_root: "quick",
        pos: "ADJ",
        word_pos_key: "quick-ADJ",
        text_frequency: 1,
        word_difficulty: 0.05215,
      },
      {
        word_root: "detect",
        pos: "VERB",
        word_pos_key: "detect-VERB",
        text_frequency: 1,
        word_difficulty: 0.1543,
      },
      {
        word_root: "attach",
        pos: "VERB",
        word_pos_key: "attach-VERB",
        text_frequency: 1,
        word_difficulty: 0.1716,
      },
      {
        word_root: "quality",
        pos: "NOUN",
        word_pos_key: "quality-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0095,
      },
      {
        word_root: "appear",
        pos: "VERB",
        word_pos_key: "appear-VERB",
        text_frequency: 1,
        word_difficulty: 0.03885,
      },
      {
        word_root: "normal",
        pos: "ADJ",
        word_pos_key: "normal-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0381,
      },
      {
        word_root: "person",
        pos: "NOUN",
        word_pos_key: "person-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0097,
      },
      {
        word_root: "come",
        pos: "VERB",
        word_pos_key: "come-VERB",
        text_frequency: 2,
        word_difficulty: 0.00955,
      },
      {
        word_root: "college",
        pos: "NOUN",
        word_pos_key: "college-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0369,
      },
      {
        word_root: "unjustly",
        pos: "ADV",
        word_pos_key: "unjustly-ADV",
        text_frequency: 1,
        word_difficulty: 0.39715,
      },
      {
        word_root: "accuse",
        pos: "VERB",
        word_pos_key: "accuse-VERB",
        text_frequency: 1,
        word_difficulty: 0.42185,
      },
      {
        word_root: "politician",
        pos: "NOUN",
        word_pos_key: "politician-NOUN",
        text_frequency: 1,
        word_difficulty: 0.30535,
      },
      {
        word_root: "privy",
        pos: "ADJ",
        word_pos_key: "privy-ADJ",
        text_frequency: 1,
        word_difficulty: 0.5892,
      },
      {
        word_root: "secret",
        pos: "ADJ",
        word_pos_key: "secret-ADJ",
        text_frequency: 1,
        word_difficulty: 0.08915,
      },
      {
        word_root: "griefs",
        pos: "NOUN",
        word_pos_key: "griefs-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2501,
      },
      {
        word_root: "wild",
        pos: "ADJ",
        word_pos_key: "wild-ADJ",
        text_frequency: 1,
        word_difficulty: 0.08185,
      },
      {
        word_root: "unknown",
        pos: "ADJ",
        word_pos_key: "unknown-ADJ",
        text_frequency: 1,
        word_difficulty: 0.07415,
      },
      {
        word_root: "man",
        pos: "NOUN",
        word_pos_key: "man-NOUN",
        text_frequency: 2,
        word_difficulty: 0.01085,
      },
      {
        word_root: "confidence",
        pos: "NOUN",
        word_pos_key: "confidence-NOUN",
        text_frequency: 1,
        word_difficulty: 0.08275,
      },
      {
        word_root: "feign",
        pos: "VERB",
        word_pos_key: "feign-VERB",
        text_frequency: 1,
        word_difficulty: 0.7699,
      },
      {
        word_root: "sleep",
        pos: "NOUN",
        word_pos_key: "sleep-NOUN",
        text_frequency: 1,
        word_difficulty: 0.07575,
      },
      {
        word_root: "preoccupation",
        pos: "NOUN",
        word_pos_key: "preoccupation-NOUN",
        text_frequency: 1,
        word_difficulty: 0.57795,
      },
      {
        word_root: "hostile",
        pos: "ADJ",
        word_pos_key: "hostile-ADJ",
        text_frequency: 1,
        word_difficulty: 0.259,
      },
      {
        word_root: "levity",
        pos: "NOUN",
        word_pos_key: "levity-NOUN",
        text_frequency: 1,
        word_difficulty: 0.76695,
      },
      {
        word_root: "realize",
        pos: "VERB",
        word_pos_key: "realize-VERB",
        text_frequency: 1,
        word_difficulty: 0.10175,
      },
      {
        word_root: "unmistakable",
        pos: "ADJ",
        word_pos_key: "unmistakable-ADJ",
        text_frequency: 1,
        word_difficulty: 0.54,
      },
      {
        word_root: "sign",
        pos: "NOUN",
        word_pos_key: "sign-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0218,
      },
      {
        word_root: "intimate",
        pos: "ADJ",
        word_pos_key: "intimate-ADJ",
        text_frequency: 2,
        word_difficulty: 0.21945,
      },
      {
        word_root: "revelation",
        pos: "NOUN",
        word_pos_key: "revelation-NOUN",
        text_frequency: 2,
        word_difficulty: 0.29635,
      },
      {
        word_root: "quiver",
        pos: "VERB",
        word_pos_key: "quiver-VERB",
        text_frequency: 1,
        word_difficulty: 0.62175,
      },
      {
        word_root: "horizon",
        pos: "NOUN",
        word_pos_key: "horizon-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2316,
      },
      {
        word_root: "express",
        pos: "VERB",
        word_pos_key: "express-VERB",
        text_frequency: 1,
        word_difficulty: 0.0723,
      },
      {
        word_root: "usually",
        pos: "ADV",
        word_pos_key: "usually-ADV",
        text_frequency: 1,
        word_difficulty: 0.02775,
      },
      {
        word_root: "plagiaristic",
        pos: "ADJ",
        word_pos_key: "plagiaristic-ADJ",
        text_frequency: 1,
        word_difficulty: 0.9043472831214964,
      },
      {
        word_root: "marred",
        pos: "ADJ",
        word_pos_key: "marred-ADJ",
        text_frequency: 1,
        word_difficulty: 0.5544,
      },
      {
        word_root: "obvious",
        pos: "ADJ",
        word_pos_key: "obvious-ADJ",
        text_frequency: 1,
        word_difficulty: 0.09605,
      },
      {
        word_root: "suppression",
        pos: "NOUN",
        word_pos_key: "suppression-NOUN",
        text_frequency: 1,
        word_difficulty: 0.27995,
      },
      {
        word_root: "reserving",
        pos: "NOUN",
        word_pos_key: "reserving-NOUN",
        text_frequency: 1,
        word_difficulty: 0.10105,
      },
      {
        word_root: "matter",
        pos: "NOUN",
        word_pos_key: "matter-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02675,
      },
      {
        word_root: "infinite",
        pos: "ADJ",
        word_pos_key: "infinite-ADJ",
        text_frequency: 1,
        word_difficulty: 0.2041,
      },
      {
        word_root: "hope",
        pos: "NOUN",
        word_pos_key: "hope-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02955,
      },
      {
        word_root: "little",
        pos: "ADJ",
        word_pos_key: "little-ADJ",
        text_frequency: 1,
        word_difficulty: 0.00855,
      },
      {
        word_root: "afraid",
        pos: "ADJ",
        word_pos_key: "afraid-ADJ",
        text_frequency: 1,
        word_difficulty: 0.12835,
      },
      {
        word_root: "miss",
        pos: "VERB",
        word_pos_key: "miss-VERB",
        text_frequency: 1,
        word_difficulty: 0.07565,
      },
      {
        word_root: "forget",
        pos: "VERB",
        word_pos_key: "forget-VERB",
        text_frequency: 1,
        word_difficulty: 0.07915,
      },
      {
        word_root: "snobbishly",
        pos: "ADV",
        word_pos_key: "snobbishly-ADV",
        text_frequency: 2,
        word_difficulty: 0.9041599600377421,
      },
      {
        word_root: "suggest",
        pos: "VERB",
        word_pos_key: "suggest-VERB",
        text_frequency: 1,
        word_difficulty: 0.06045,
      },
      {
        word_root: "repeat",
        pos: "VERB",
        word_pos_key: "repeat-VERB",
        text_frequency: 1,
        word_difficulty: 0.12885,
      },
      {
        word_root: "sense",
        pos: "NOUN",
        word_pos_key: "sense-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "fundamental",
        pos: "ADJ",
        word_pos_key: "fundamental-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1064,
      },
      {
        word_root: "decency",
        pos: "NOUN",
        word_pos_key: "decency-NOUN",
        text_frequency: 1,
        word_difficulty: 0.49465,
      },
      {
        word_root: "parcel",
        pos: "VERB",
        word_pos_key: "parcel-VERB",
        text_frequency: 1,
        word_difficulty: 0.25445,
      },
      {
        word_root: "unequally",
        pos: "ADV",
        word_pos_key: "unequally-ADV",
        text_frequency: 1,
        word_difficulty: 0.43705,
      },
      {
        word_root: "birth",
        pos: "NOUN",
        word_pos_key: "birth-NOUN",
        text_frequency: 1,
        word_difficulty: 0.07405,
      },
      {
        word_root: "boast",
        pos: "VERB",
        word_pos_key: "boast-VERB",
        text_frequency: 1,
        word_difficulty: 0.39055,
      },
      {
        word_root: "tolerance",
        pos: "NOUN",
        word_pos_key: "tolerance-NOUN",
        text_frequency: 1,
        word_difficulty: 0.18285,
      },
    ],
    textLanguage: "en",
  },
  /*   {
    name: "Peter Thiel at Stanford",
    url: "https://firebasestorage.googleapis.com/v0/b/vocably-66cac.appspot.com/o/vocab-mine%2F1165cc03-5eb8-490d-8d8a-d8788b5b3004%2FCS183%20Peter%20Thiel%20Stanford?alt=media&token=9cf31099-bfcb-488f-9363-d59e0aea32c1",
    description:
      "Blake Master's Notes on the CS183 Lectures by Peter Thiel, including 3285 unique words around technology and startups.",
    symbol: "🇬🇧",
    fileType: "plain",
    wordAssessment: [
      {
        word_root: "essay",
        pos: "ADJ",
        word_pos_key: "essay-ADJ",
        text_frequency: 1,
        word_difficulty: 0.15105,
      },
      {
        word_root: "version",
        pos: "NOUN",
        word_pos_key: "version-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0099,
      },
      {
        word_root: "class",
        pos: "NOUN",
        word_pos_key: "class-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0117,
      },
      {
        word_root: "note",
        pos: "NOUN",
        word_pos_key: "note-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "error",
        pos: "NOUN",
        word_pos_key: "error-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02415,
      },
      {
        word_root: "omission",
        pos: "NOUN",
        word_pos_key: "omission-NOUN",
        text_frequency: 1,
        word_difficulty: 0.329,
      },
      {
        word_root: "credit",
        pos: "NOUN",
        word_pos_key: "credit-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0134,
      },
      {
        word_root: "good",
        pos: "ADJ",
        word_pos_key: "good-ADJ",
        text_frequency: 2,
        word_difficulty: 0.02655,
      },
      {
        word_root: "stuff",
        pos: "NOUN",
        word_pos_key: "stuff-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0359,
      },
      {
        word_root: "entirely",
        pos: "ADV",
        word_pos_key: "entirely-ADV",
        text_frequency: 1,
        word_difficulty: 0.0977,
      },
      {
        word_root: "startup",
        pos: "NOUN",
        word_pos_key: "startup-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1952,
      },
      {
        word_root: "describe",
        pos: "VERB",
        word_pos_key: "describe-VERB",
        text_frequency: 1,
        word_difficulty: 0.07615,
      },
      {
        word_root: "world",
        pos: "NOUN",
        word_pos_key: "world-NOUN",
        text_frequency: 2,
        word_difficulty: 0.005,
      },
      {
        word_root: "have",
        pos: "VERB",
        word_pos_key: "have-VERB",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "retail",
        pos: "ADJ",
        word_pos_key: "retail-ADJ",
        text_frequency: 1,
        word_difficulty: 0.07725,
      },
      {
        word_root: "sanity",
        pos: "NOUN",
        word_pos_key: "sanity-NOUN",
        text_frequency: 1,
        word_difficulty: 0.5749321009131889,
      },
      {
        word_root: "wholesale",
        pos: "ADJ",
        word_pos_key: "wholesale-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1111,
      },
      {
        word_root: "madness",
        pos: "NOUN",
        word_pos_key: "madness-NOUN",
        text_frequency: 1,
        word_difficulty: 0.15175,
      },
      {
        word_root: "detail",
        pos: "NOUN",
        word_pos_key: "detail-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0581,
      },
      {
        word_root: "understand",
        pos: "VERB",
        word_pos_key: "understand-VERB",
        text_frequency: 1,
        word_difficulty: 0.02735,
      },
      {
        word_root: "big",
        pos: "ADJ",
        word_pos_key: "big-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0094,
      },
      {
        word_root: "picture",
        pos: "NOUN",
        word_pos_key: "picture-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0211,
      },
      {
        word_root: "remain",
        pos: "VERB",
        word_pos_key: "remain-VERB",
        text_frequency: 1,
        word_difficulty: 0.0544,
      },
      {
        word_root: "unclear",
        pos: "ADJ",
        word_pos_key: "unclear-ADJ",
        text_frequency: 1,
        word_difficulty: 0.2472,
      },
      {
        word_root: "fundamental",
        pos: "ADJ",
        word_pos_key: "fundamental-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1064,
      },
      {
        word_root: "challenge",
        pos: "NOUN",
        word_pos_key: "challenge-NOUN",
        text_frequency: 1,
        word_difficulty: 0.06765,
      },
      {
        word_root: "business",
        pos: "NOUN",
        word_pos_key: "business-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00285,
      },
      {
        word_root: "life",
        pos: "NOUN",
        word_pos_key: "life-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00545,
      },
      {
        word_root: "integrate",
        pos: "VERB",
        word_pos_key: "integrate-VERB",
        text_frequency: 2,
        word_difficulty: 0.1675,
      },
      {
        word_root: "macro",
        pos: "NOUN",
        word_pos_key: "macro-NOUN",
        text_frequency: 1,
        word_difficulty: 0.24846308103710668,
      },
      {
        word_root: "thing",
        pos: "NOUN",
        word_pos_key: "thing-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01655,
      },
      {
        word_root: "sense",
        pos: "NOUN",
        word_pos_key: "sense-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02655,
      },
      {
        word_root: "major",
        pos: "NOUN",
        word_pos_key: "major-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0172,
      },
      {
        word_root: "learn",
        pos: "VERB",
        word_pos_key: "learn-VERB",
        text_frequency: 4,
        word_difficulty: 0.02145,
      },
      {
        word_root: "great",
        pos: "ADJ",
        word_pos_key: "great-ADJ",
        text_frequency: 2,
        word_difficulty: 0.0054,
      },
      {
        word_root: "deal",
        pos: "NOUN",
        word_pos_key: "deal-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "career",
        pos: "NOUN",
        word_pos_key: "career-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0433,
      },
      {
        word_root: "skill",
        pos: "NOUN",
        word_pos_key: "skill-NOUN",
        text_frequency: 2,
        word_difficulty: 0.11325,
      },
      {
        word_root: "study",
        pos: "NOUN",
        word_pos_key: "study-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0135,
      },
      {
        word_root: "engineering",
        pos: "NOUN",
        word_pos_key: "engineering-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0615,
      },
      {
        word_root: "conversely",
        pos: "ADV",
        word_pos_key: "conversely-ADV",
        text_frequency: 1,
        word_difficulty: 0.792163132463046,
      },
      {
        word_root: "technical",
        pos: "ADJ",
        word_pos_key: "technical-ADJ",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "apply",
        pos: "VERB",
        word_pos_key: "apply-VERB",
        text_frequency: 1,
        word_difficulty: 0.029,
      },
      {
        word_root: "workforce",
        pos: "NOUN",
        word_pos_key: "workforce-NOUN",
        text_frequency: 1,
        word_difficulty: 0.17975,
      },
      {
        word_root: "student",
        pos: "NOUN",
        word_pos_key: "student-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01965,
      },
      {
        word_root: "worker",
        pos: "NOUN",
        word_pos_key: "worker-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1213,
      },
      {
        word_root: "thinker",
        pos: "NOUN",
        word_pos_key: "thinker-NOUN",
        text_frequency: 1,
        word_difficulty: 0.6849807573479685,
      },
      {
        word_root: "question",
        pos: "NOUN",
        word_pos_key: "question-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "cohesive",
        pos: "ADJ",
        word_pos_key: "cohesive-ADJ",
        text_frequency: 1,
        word_difficulty: 0.4411,
      },
      {
        word_root: "narrative",
        pos: "NOUN",
        word_pos_key: "narrative-NOUN",
        text_frequency: 1,
        word_difficulty: 0.19735,
      },
      {
        word_root: "course",
        pos: "NOUN",
        word_pos_key: "course-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00935,
      },
      {
        word_root: "aim",
        pos: "VERB",
        word_pos_key: "aim-VERB",
        text_frequency: 1,
        word_difficulty: 0.09585,
      },
      {
        word_root: "facilitate",
        pos: "VERB",
        word_pos_key: "facilitate-VERB",
        text_frequency: 1,
        word_difficulty: 0.12345,
      },
      {
        word_root: "process",
        pos: "NOUN",
        word_pos_key: "process-NOUN",
        text_frequency: 1,
        word_difficulty: 0.008,
      },
      {
        word_root: "recent",
        pos: "ADJ",
        word_pos_key: "recent-ADJ",
        text_frequency: 2,
        word_difficulty: 0.01925,
      },
      {
        word_root: "human",
        pos: "ADJ",
        word_pos_key: "human-ADJ",
        text_frequency: 2,
        word_difficulty: 0.01665,
      },
      {
        word_root: "history",
        pos: "NOUN",
        word_pos_key: "history-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01735,
      },
      {
        word_root: "invention",
        pos: "NOUN",
        word_pos_key: "invention-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1623,
      },
      {
        word_root: "steam",
        pos: "NOUN",
        word_pos_key: "steam-NOUN",
        text_frequency: 1,
        word_difficulty: 0.17545,
      },
      {
        word_root: "engine",
        pos: "NOUN",
        word_pos_key: "engine-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03825,
      },
      {
        word_root: "late",
        pos: "ADJ",
        word_pos_key: "late-ADJ",
        text_frequency: 2,
        word_difficulty: 0.0299,
      },
      {
        word_root: "century",
        pos: "NOUN",
        word_pos_key: "century-NOUN",
        text_frequency: 2,
        word_difficulty: 0.04865,
      },
      {
        word_root: "technological",
        pos: "ADJ",
        word_pos_key: "technological-ADJ",
        text_frequency: 3,
        word_difficulty: 0.15615,
      },
      {
        word_root: "progress",
        pos: "NOUN",
        word_pos_key: "progress-NOUN",
        text_frequency: 3,
        word_difficulty: 0.0491,
      },
      {
        word_root: "tremendous",
        pos: "ADJ",
        word_pos_key: "tremendous-ADJ",
        text_frequency: 1,
        word_difficulty: 0.17865,
      },
      {
        word_root: "relentless",
        pos: "NOUN",
        word_pos_key: "relentless-NOUN",
        text_frequency: 1,
        word_difficulty: 0.42785,
      },
      {
        word_root: "prior",
        pos: "ADJ",
        word_pos_key: "prior-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0314,
      },
      {
        word_root: "society",
        pos: "NOUN",
        word_pos_key: "society-NOUN",
        text_frequency: 2,
        word_difficulty: 0.04475,
      },
      {
        word_root: "people",
        pos: "NOUN",
        word_pos_key: "people-NOUN",
        text_frequency: 3,
        word_difficulty: 0.02655,
      },
      {
        word_root: "money",
        pos: "NOUN",
        word_pos_key: "money-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02655,
      },
      {
        word_root: "take",
        pos: "VERB",
        word_pos_key: "take-VERB",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "industrial",
        pos: "ADJ",
        word_pos_key: "industrial-ADJ",
        text_frequency: 1,
        word_difficulty: 0.06445,
      },
      {
        word_root: "revolution",
        pos: "NOUN",
        word_pos_key: "revolution-NOUN",
        text_frequency: 1,
        word_difficulty: 0.15985,
      },
      {
        word_root: "work",
        pos: "VERB",
        word_pos_key: "work-VERB",
        text_frequency: 2,
        word_difficulty: 0.0021,
      },
      {
        word_root: "paradigm",
        pos: "NOUN",
        word_pos_key: "paradigm-NOUN",
        text_frequency: 1,
        word_difficulty: 0.26435,
      },
      {
        word_root: "shift",
        pos: "NOUN",
        word_pos_key: "shift-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0975,
      },
      {
        word_root: "trade",
        pos: "NOUN",
        word_pos_key: "trade-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03215,
      },
      {
        word_root: "plunder",
        pos: "NOUN",
        word_pos_key: "plunder-NOUN",
        text_frequency: 1,
        word_difficulty: 0.5447,
      },
      {
        word_root: "importance",
        pos: "NOUN",
        word_pos_key: "importance-NOUN",
        text_frequency: 1,
        word_difficulty: 0.06585,
      },
      {
        word_root: "hard",
        pos: "ADJ",
        word_pos_key: "hard-ADJ",
        text_frequency: 1,
        word_difficulty: 0.01585,
      },
      {
        word_root: "overstate",
        pos: "VERB",
        word_pos_key: "overstate-VERB",
        text_frequency: 1,
        word_difficulty: 0.7007,
      },
      {
        word_root: "billion",
        pos: "NUM",
        word_pos_key: "billion-NUM",
        text_frequency: 1,
        word_difficulty: 0.056,
      },
      {
        word_root: "live",
        pos: "VERB",
        word_pos_key: "live-VERB",
        text_frequency: 2,
        word_difficulty: 0.0141,
      },
      {
        word_root: "earth",
        pos: "NOUN",
        word_pos_key: "earth-NOUN",
        text_frequency: 1,
        word_difficulty: 0.06645,
      },
      {
        word_root: "essentially",
        pos: "ADV",
        word_pos_key: "essentially-ADV",
        text_frequency: 1,
        word_difficulty: 0.14435,
      },
      {
        word_root: "stagnant",
        pos: "ADJ",
        word_pos_key: "stagnant-ADJ",
        text_frequency: 2,
        word_difficulty: 0.5235,
      },
      {
        word_root: "success",
        pos: "NOUN",
        word_pos_key: "success-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03775,
      },
      {
        word_root: "involve",
        pos: "VERB",
        word_pos_key: "involve-VERB",
        text_frequency: 1,
        word_difficulty: 0.1166,
      },
      {
        word_root: "claim",
        pos: "VERB",
        word_pos_key: "claim-VERB",
        text_frequency: 1,
        word_difficulty: 0.047,
      },
      {
        word_root: "value",
        pos: "NOUN",
        word_pos_key: "value-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00885,
      },
      {
        word_root: "create",
        pos: "VERB",
        word_pos_key: "create-VERB",
        text_frequency: 1,
        word_difficulty: 0.0184,
      },
      {
        word_root: "massive",
        pos: "ADJ",
        word_pos_key: "massive-ADJ",
        text_frequency: 1,
        word_difficulty: 0.09315,
      },
      {
        word_root: "acceleration",
        pos: "NOUN",
        word_pos_key: "acceleration-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2569,
      },
      {
        word_root: "past",
        pos: "ADJ",
        word_pos_key: "past-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0185,
      },
      {
        word_root: "year",
        pos: "NOUN",
        word_pos_key: "year-NOUN",
        text_frequency: 3,
        word_difficulty: 0.00225,
      },
      {
        word_root: "truly",
        pos: "ADV",
        word_pos_key: "truly-ADV",
        text_frequency: 1,
        word_difficulty: 0.0776,
      },
      {
        word_root: "incredible",
        pos: "ADJ",
        word_pos_key: "incredible-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1433,
      },
      {
        word_root: "zenith",
        pos: "NOUN",
        word_pos_key: "zenith-NOUN",
        text_frequency: 1,
        word_difficulty: 0.5482,
      },
      {
        word_root: "optimism",
        pos: "NOUN",
        word_pos_key: "optimism-NOUN",
        text_frequency: 1,
        word_difficulty: 0.365,
      },
      {
        word_root: "future",
        pos: "NOUN",
        word_pos_key: "future-NOUN",
        text_frequency: 3,
        word_difficulty: 0.01635,
      },
      {
        word_root: "technology",
        pos: "NOUN",
        word_pos_key: "technology-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01475,
      },
      {
        word_root: "People",
        pos: "NOUN",
        word_pos_key: "People-NOUN",
        text_frequency: 2,
        word_difficulty: 0.00185,
      },
      {
        word_root: "believe",
        pos: "VERB",
        word_pos_key: "believe-VERB",
        text_frequency: 1,
        word_difficulty: 0.02215,
      },
      {
        word_root: "think",
        pos: "VERB",
        word_pos_key: "think-VERB",
        text_frequency: 1,
        word_difficulty: 0.0052,
      },
      {
        word_root: "supremely",
        pos: "ADV",
        word_pos_key: "supremely-ADV",
        text_frequency: 1,
        word_difficulty: 0.2638,
      },
      {
        word_root: "confident",
        pos: "ADJ",
        word_pos_key: "confident-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1587,
      },
      {
        word_root: "half",
        pos: "ADJ",
        word_pos_key: "half-ADJ",
        text_frequency: 1,
        word_difficulty: 0.02365,
      },
      {
        word_root: "unprecedented",
        pos: "ADJ",
        word_pos_key: "unprecedented-ADJ",
        text_frequency: 1,
        word_difficulty: 0.25345,
      },
      {
        word_root: "exception",
        pos: "NOUN",
        word_pos_key: "exception-NOUN",
        text_frequency: 2,
        word_difficulty: 0.09705,
      },
      {
        word_root: "computer",
        pos: "NOUN",
        word_pos_key: "computer-NOUN",
        text_frequency: 3,
        word_difficulty: 0.01365,
      },
      {
        word_root: "industry",
        pos: "NOUN",
        word_pos_key: "industry-NOUN",
        text_frequency: 2,
        word_difficulty: 0.0144,
      },
      {
        word_root: "capita",
        pos: "NOUN",
        word_pos_key: "capita-NOUN",
        text_frequency: 1,
        word_difficulty: -1.0,
      },
      {
        word_root: "income",
        pos: "NOUN",
        word_pos_key: "income-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02835,
      },
      {
        word_root: "rise",
        pos: "VERB",
        word_pos_key: "rise-VERB",
        text_frequency: 1,
        word_difficulty: 0.06935,
      },
      {
        word_root: "rate",
        pos: "NOUN",
        word_pos_key: "rate-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0105,
      },
      {
        word_root: "starkly",
        pos: "ADV",
        word_pos_key: "starkly-ADV",
        text_frequency: 1,
        word_difficulty: 0.3929,
      },
      {
        word_root: "decelerate",
        pos: "VERB",
        word_pos_key: "decelerate-VERB",
        text_frequency: 1,
        word_difficulty: 0.82525,
      },
      {
        word_root: "median",
        pos: "ADJ",
        word_pos_key: "median-ADJ",
        text_frequency: 1,
        word_difficulty: 0.15895,
      },
      {
        word_root: "wage",
        pos: "NOUN",
        word_pos_key: "wage-NOUN",
        text_frequency: 2,
        word_difficulty: 0.1362,
      },
      {
        word_root: "find",
        pos: "VERB",
        word_pos_key: "find-VERB",
        text_frequency: 1,
        word_difficulty: 0.00345,
      },
      {
        word_root: "alarming",
        pos: "ADJ",
        word_pos_key: "alarming-ADJ",
        text_frequency: 1,
        word_difficulty: 0.40155,
      },
      {
        word_root: "alice",
        pos: "NOUN",
        word_pos_key: "alice-NOUN",
        text_frequency: 1,
        word_difficulty: -1.0,
      },
      {
        word_root: "wonderland",
        pos: "NOUN",
        word_pos_key: "wonderland-NOUN",
        text_frequency: 1,
        word_difficulty: 0.7948363274735262,
      },
      {
        word_root: "style",
        pos: "NOUN",
        word_pos_key: "style-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02615,
      },
      {
        word_root: "scenario",
        pos: "NOUN",
        word_pos_key: "scenario-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1506,
      },
      {
        word_root: "run",
        pos: "VERB",
        word_pos_key: "run-VERB",
        text_frequency: 1,
        word_difficulty: 0.0161,
      },
      {
        word_root: "hard",
        pos: "ADV",
        word_pos_key: "hard-ADV",
        text_frequency: 2,
        word_difficulty: 0.01585,
      },
      {
        word_root: "long",
        pos: "ADJ",
        word_pos_key: "long-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0056,
      },
      {
        word_root: "hour",
        pos: "NOUN",
        word_pos_key: "hour-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0282,
      },
      {
        word_root: "stay",
        pos: "VERB",
        word_pos_key: "stay-VERB",
        text_frequency: 1,
        word_difficulty: 0.0246,
      },
      {
        word_root: "place",
        pos: "NOUN",
        word_pos_key: "place-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00655,
      },
      {
        word_root: "deceleration",
        pos: "NOUN",
        word_pos_key: "deceleration-NOUN",
        text_frequency: 2,
        word_difficulty: 0.62605,
      },
      {
        word_root: "complex",
        pos: "ADJ",
        word_pos_key: "complex-ADJ",
        text_frequency: 1,
        word_difficulty: 0.04295,
      },
      {
        word_root: "datum",
        pos: "NOUN",
        word_pos_key: "datum-NOUN",
        text_frequency: 1,
        word_difficulty: 0.4612,
      },
      {
        word_root: "explain",
        pos: "VERB",
        word_pos_key: "explain-VERB",
        text_frequency: 1,
        word_difficulty: 0.0715,
      },
      {
        word_root: "support",
        pos: "VERB",
        word_pos_key: "support-VERB",
        text_frequency: 1,
        word_difficulty: 0.0045,
      },
      {
        word_root: "general",
        pos: "ADJ",
        word_pos_key: "general-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0145,
      },
      {
        word_root: "rapid",
        pos: "ADJ",
        word_pos_key: "rapid-ADJ",
        text_frequency: 1,
        word_difficulty: 0.11375,
      },
      {
        word_root: "slow",
        pos: "VERB",
        word_pos_key: "slow-VERB",
        text_frequency: 1,
        word_difficulty: 0.07215,
      },
      {
        word_root: "quickly",
        pos: "ADV",
        word_pos_key: "quickly-ADV",
        text_frequency: 1,
        word_difficulty: 0.0423,
      },
      {
        word_root: "happy",
        pos: "ADJ",
        word_pos_key: "happy-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0426,
      },
      {
        word_root: "tech",
        pos: "NOUN",
        word_pos_key: "tech-NOUN",
        text_frequency: 2,
        word_difficulty: 0.17614155946038126,
      },
      {
        word_root: "law",
        pos: "NOUN",
        word_pos_key: "law-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0126,
      },
      {
        word_root: "largely",
        pos: "ADV",
        word_pos_key: "largely-ADV",
        text_frequency: 1,
        word_difficulty: 0.11765,
      },
      {
        word_root: "hold",
        pos: "VERB",
        word_pos_key: "hold-VERB",
        text_frequency: 1,
        word_difficulty: 0.03025,
      },
      {
        word_root: "forecast",
        pos: "VERB",
        word_pos_key: "forecast-VERB",
        text_frequency: 1,
        word_difficulty: 0.12295,
      },
      {
        word_root: "continue",
        pos: "VERB",
        word_pos_key: "continue-VERB",
        text_frequency: 1,
        word_difficulty: 0.0276,
      },
      {
        word_root: "growth",
        pos: "NOUN",
        word_pos_key: "growth-NOUN",
        text_frequency: 1,
        word_difficulty: 0.025,
      },
      {
        word_root: "improve",
        pos: "VERB",
        word_pos_key: "improve-VERB",
        text_frequency: 1,
        word_difficulty: 0.03075,
      },
      {
        word_root: "hardware",
        pos: "NOUN",
        word_pos_key: "hardware-NOUN",
        text_frequency: 1,
        word_difficulty: 0.05855,
      },
      {
        word_root: "agile",
        pos: "ADJ",
        word_pos_key: "agile-ADJ",
        text_frequency: 1,
        word_difficulty: 0.4626,
      },
      {
        word_root: "development",
        pos: "NOUN",
        word_pos_key: "development-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00735,
      },
      {
        word_root: "model",
        pos: "NOUN",
        word_pos_key: "model-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "obviously",
        pos: "ADV",
        word_pos_key: "obviously-ADV",
        text_frequency: 1,
        word_difficulty: 0.1182,
      },
      {
        word_root: "central",
        pos: "ADJ",
        word_pos_key: "central-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0511,
      },
      {
        word_root: "ecosystem",
        pos: "NOUN",
        word_pos_key: "ecosystem-NOUN",
        text_frequency: 1,
        word_difficulty: 0.2572,
      },
      {
        word_root: "key",
        pos: "ADJ",
        word_pos_key: "key-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0174,
      },
      {
        word_root: "driver",
        pos: "NOUN",
        word_pos_key: "driver-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0533,
      },
      {
        word_root: "modern",
        pos: "ADJ",
        word_pos_key: "modern-ADJ",
        text_frequency: 1,
        word_difficulty: 0.04905,
      },
      {
        word_root: "tec",
        pos: "NOUN",
        word_pos_key: "tec-NOUN",
        text_frequency: 1,
        word_difficulty: 0.7896821714832235,
      },
    ],
    textLanguage: "en",
  },
  {
    name: "Wikipedia Biographies",
    url: "https://firebasestorage.googleapis.com/v0/b/vocably-66cac.appspot.com/o/vocab-mine%2Fdc41cb5a-ad56-40ac-b179-0cec51adaf9e%2FWikipedia%20Biographies?alt=media&token=c255d197-7c95-4365-8075-d81a9ea2dc96",
    description:
      "Wikipedia Articles on Emily Dickinson, William Gibson, Franz Kafka, and J. R. R. Tolkien. Counting 4553 unique words.",
    symbol: "🇬🇧",
    fileType: "plain",
    wordAssessment: [
      {
        word_root: "american",
        pos: "ADJ",
        word_pos_key: "american-ADJ",
        text_frequency: 1,
        word_difficulty: 0.13145,
      },
      {
        word_root: "poet",
        pos: "NOUN",
        word_pos_key: "poet-NOUN",
        text_frequency: 1,
        word_difficulty: 0.20835,
      },
      {
        word_root: "little",
        pos: "ADV",
        word_pos_key: "little-ADV",
        text_frequency: 1,
        word_difficulty: 0.00855,
      },
      {
        word_root: "know",
        pos: "VERB",
        word_pos_key: "know-VERB",
        text_frequency: 2,
        word_difficulty: 0.0028,
      },
      {
        word_root: "life",
        pos: "NOUN",
        word_pos_key: "life-NOUN",
        text_frequency: 3,
        word_difficulty: 0.00545,
      },
      {
        word_root: "regard",
        pos: "VERB",
        word_pos_key: "regard-VERB",
        text_frequency: 1,
        word_difficulty: 0.0829,
      },
      {
        word_root: "important",
        pos: "ADJ",
        word_pos_key: "important-ADJ",
        text_frequency: 1,
        word_difficulty: 0.01135,
      },
      {
        word_root: "figure",
        pos: "NOUN",
        word_pos_key: "figure-NOUN",
        text_frequency: 1,
        word_difficulty: 0.05505,
      },
      {
        word_root: "bear",
        pos: "VERB",
        word_pos_key: "bear-VERB",
        text_frequency: 1,
        word_difficulty: 0.0942,
      },
      {
        word_root: "prominent",
        pos: "ADJ",
        word_pos_key: "prominent-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1778,
      },
      {
        word_root: "family",
        pos: "NOUN",
        word_pos_key: "family-NOUN",
        text_frequency: 2,
        word_difficulty: 0.00875,
      },
      {
        word_root: "strong",
        pos: "ADJ",
        word_pos_key: "strong-ADJ",
        text_frequency: 1,
        word_difficulty: 0.03165,
      },
      {
        word_root: "tie",
        pos: "NOUN",
        word_pos_key: "tie-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1384,
      },
      {
        word_root: "community",
        pos: "NOUN",
        word_pos_key: "community-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0107,
      },
      {
        word_root: "study",
        pos: "VERB",
        word_pos_key: "study-VERB",
        text_frequency: 1,
        word_difficulty: 0.0135,
      },
      {
        word_root: "seven",
        pos: "NUM",
        word_pos_key: "seven-NUM",
        text_frequency: 1,
        word_difficulty: 0.05295,
      },
      {
        word_root: "year",
        pos: "NOUN",
        word_pos_key: "year-NOUN",
        text_frequency: 2,
        word_difficulty: 0.00225,
      },
      {
        word_root: "youth",
        pos: "NOUN",
        word_pos_key: "youth-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0734,
      },
      {
        word_root: "briefly",
        pos: "ADV",
        word_pos_key: "briefly-ADV",
        text_frequency: 1,
        word_difficulty: 0.184,
      },
      {
        word_root: "attend",
        pos: "VERB",
        word_pos_key: "attend-VERB",
        text_frequency: 1,
        word_difficulty: 0.08255,
      },
      {
        word_root: "return",
        pos: "VERB",
        word_pos_key: "return-VERB",
        text_frequency: 1,
        word_difficulty: 0.01095,
      },
      {
        word_root: "home",
        pos: "NOUN",
        word_pos_key: "home-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00215,
      },
      {
        word_root: "evidence",
        pos: "NOUN",
        word_pos_key: "evidence-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0313,
      },
      {
        word_root: "suggest",
        pos: "VERB",
        word_pos_key: "suggest-VERB",
        text_frequency: 1,
        word_difficulty: 0.06045,
      },
      {
        word_root: "live",
        pos: "VERB",
        word_pos_key: "live-VERB",
        text_frequency: 1,
        word_difficulty: 0.0141,
      },
      {
        word_root: "isolation",
        pos: "NOUN",
        word_pos_key: "isolation-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1881,
      },
      {
        word_root: "eccentric",
        pos: "ADJ",
        word_pos_key: "eccentric-ADJ",
        text_frequency: 1,
        word_difficulty: 0.4041,
      },
      {
        word_root: "local",
        pos: "NOUN",
        word_pos_key: "local-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0065,
      },
      {
        word_root: "develop",
        pos: "VERB",
        word_pos_key: "develop-VERB",
        text_frequency: 1,
        word_difficulty: 0.0339,
      },
      {
        word_root: "penchant",
        pos: "NOUN",
        word_pos_key: "penchant-NOUN",
        text_frequency: 1,
        word_difficulty: 0.5606,
      },
      {
        word_root: "white",
        pos: "ADJ",
        word_pos_key: "white-ADJ",
        text_frequency: 2,
        word_difficulty: 0.0223,
      },
      {
        word_root: "clothing",
        pos: "NOUN",
        word_pos_key: "clothing-NOUN",
        text_frequency: 1,
        word_difficulty: 0.08055,
      },
      {
        word_root: "reluctance",
        pos: "NOUN",
        word_pos_key: "reluctance-NOUN",
        text_frequency: 1,
        word_difficulty: 0.42225,
      },
      {
        word_root: "greet",
        pos: "VERB",
        word_pos_key: "greet-VERB",
        text_frequency: 1,
        word_difficulty: 0.37205,
      },
      {
        word_root: "guest",
        pos: "NOUN",
        word_pos_key: "guest-NOUN",
        text_frequency: 1,
        word_difficulty: 0.08645,
      },
      {
        word_root: "later",
        pos: "ADV",
        word_pos_key: "later-ADV",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "leave",
        pos: "VERB",
        word_pos_key: "leave-VERB",
        text_frequency: 1,
        word_difficulty: 0.0305,
      },
      {
        word_root: "bedroom",
        pos: "NOUN",
        word_pos_key: "bedroom-NOUN",
        text_frequency: 1,
        word_difficulty: 0.09165,
      },
      {
        word_root: "marry",
        pos: "VERB",
        word_pos_key: "marry-VERB",
        text_frequency: 1,
        word_difficulty: 0.2287,
      },
      {
        word_root: "friendship",
        pos: "NOUN",
        word_pos_key: "friendship-NOUN",
        text_frequency: 1,
        word_difficulty: 0.19105,
      },
      {
        word_root: "depend",
        pos: "VERB",
        word_pos_key: "depend-VERB",
        text_frequency: 1,
        word_difficulty: 0.12845,
      },
      {
        word_root: "entirely",
        pos: "ADV",
        word_pos_key: "entirely-ADV",
        text_frequency: 1,
        word_difficulty: 0.0977,
      },
      {
        word_root: "take",
        pos: "VERB",
        word_pos_key: "take-VERB",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "early",
        pos: "ADV",
        word_pos_key: "early-ADV",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "prolific",
        pos: "ADJ",
        word_pos_key: "prolific-ADJ",
        text_frequency: 1,
        word_difficulty: 0.39185,
      },
      {
        word_root: "writer",
        pos: "NOUN",
        word_pos_key: "writer-NOUN",
        text_frequency: 1,
        word_difficulty: 0.08925,
      },
      {
        word_root: "publication",
        pos: "NOUN",
        word_pos_key: "publication-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0561,
      },
      {
        word_root: "authenticate",
        pos: "VERB",
        word_pos_key: "authenticate-VERB",
        text_frequency: 1,
        word_difficulty: 0.384,
      },
      {
        word_root: "lifetime",
        pos: "NOUN",
        word_pos_key: "lifetime-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1374,
      },
      {
        word_root: "nearly",
        pos: "ADV",
        word_pos_key: "nearly-ADV",
        text_frequency: 1,
        word_difficulty: 0.05735,
      },
      {
        word_root: "poem",
        pos: "NOUN",
        word_pos_key: "poem-NOUN",
        text_frequency: 6,
        word_difficulty: 0.16955,
      },
      {
        word_root: "portrait",
        pos: "NOUN",
        word_pos_key: "portrait-NOUN",
        text_frequency: 1,
        word_difficulty: 0.19925,
      },
      {
        word_root: "early",
        pos: "ADJ",
        word_pos_key: "early-ADJ",
        text_frequency: 3,
        word_difficulty: 0.0209,
      },
      {
        word_root: "publish",
        pos: "VERB",
        word_pos_key: "publish-VERB",
        text_frequency: 3,
        word_difficulty: 0.1296,
      },
      {
        word_root: "usually",
        pos: "ADV",
        word_pos_key: "usually-ADV",
        text_frequency: 1,
        word_difficulty: 0.02775,
      },
      {
        word_root: "edit",
        pos: "VERB",
        word_pos_key: "edit-VERB",
        text_frequency: 1,
        word_difficulty: 0.03275,
      },
      {
        word_root: "significantly",
        pos: "ADV",
        word_pos_key: "significantly-ADV",
        text_frequency: 1,
        word_difficulty: 0.07695,
      },
      {
        word_root: "fit",
        pos: "VERB",
        word_pos_key: "fit-VERB",
        text_frequency: 1,
        word_difficulty: 0.0501,
      },
      {
        word_root: "conventional",
        pos: "ADJ",
        word_pos_key: "conventional-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1256,
      },
      {
        word_root: "poetic",
        pos: "ADJ",
        word_pos_key: "poetic-ADJ",
        text_frequency: 1,
        word_difficulty: 0.35355,
      },
      {
        word_root: "rule",
        pos: "NOUN",
        word_pos_key: "rule-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0403,
      },
      {
        word_root: "unique",
        pos: "ADJ",
        word_pos_key: "unique-ADJ",
        text_frequency: 1,
        word_difficulty: 0.03325,
      },
      {
        word_root: "era",
        pos: "NOUN",
        word_pos_key: "era-NOUN",
        text_frequency: 1,
        word_difficulty: 0.12665,
      },
      {
        word_root: "contain",
        pos: "VERB",
        word_pos_key: "contain-VERB",
        text_frequency: 1,
        word_difficulty: 0.0558,
      },
      {
        word_root: "short",
        pos: "ADJ",
        word_pos_key: "short-ADJ",
        text_frequency: 1,
        word_difficulty: 0.021,
      },
      {
        word_root: "line",
        pos: "NOUN",
        word_pos_key: "line-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0049,
      },
      {
        word_root: "typically",
        pos: "ADV",
        word_pos_key: "typically-ADV",
        text_frequency: 1,
        word_difficulty: 0.02655,
      },
      {
        word_root: "lack",
        pos: "VERB",
        word_pos_key: "lack-VERB",
        text_frequency: 1,
        word_difficulty: 0.0559,
      },
      {
        word_root: "title",
        pos: "NOUN",
        word_pos_key: "title-NOUN",
        text_frequency: 1,
        word_difficulty: 0.02,
      },
      {
        word_root: "use",
        pos: "VERB",
        word_pos_key: "use-VERB",
        text_frequency: 1,
        word_difficulty: 0.0014,
      },
      {
        word_root: "slant",
        pos: "NOUN",
        word_pos_key: "slant-NOUN",
        text_frequency: 1,
        word_difficulty: 0.4867,
      },
      {
        word_root: "rhyme",
        pos: "VERB",
        word_pos_key: "rhyme-VERB",
        text_frequency: 1,
        word_difficulty: 0.41875,
      },
      {
        word_root: "unconventional",
        pos: "ADJ",
        word_pos_key: "unconventional-ADJ",
        text_frequency: 1,
        word_difficulty: 0.46905,
      },
      {
        word_root: "capitalization",
        pos: "NOUN",
        word_pos_key: "capitalization-NOUN",
        text_frequency: 1,
        word_difficulty: 0.42335,
      },
      {
        word_root: "deal",
        pos: "VERB",
        word_pos_key: "deal-VERB",
        text_frequency: 1,
        word_difficulty: 0.03155,
      },
      {
        word_root: "theme",
        pos: "NOUN",
        word_pos_key: "theme-NOUN",
        text_frequency: 1,
        word_difficulty: 0.07345,
      },
      {
        word_root: "death",
        pos: "NOUN",
        word_pos_key: "death-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02855,
      },
      {
        word_root: "immortality",
        pos: "NOUN",
        word_pos_key: "immortality-NOUN",
        text_frequency: 1,
        word_difficulty: 0.49385,
      },
      {
        word_root: "recur",
        pos: "VERB",
        word_pos_key: "recur-VERB",
        text_frequency: 1,
        word_difficulty: 0.5915,
      },
      {
        word_root: "topic",
        pos: "NOUN",
        word_pos_key: "topic-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01745,
      },
      {
        word_root: "letter",
        pos: "NOUN",
        word_pos_key: "letter-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03815,
      },
      {
        word_root: "friend",
        pos: "NOUN",
        word_pos_key: "friend-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01445,
      },
      {
        word_root: "explore",
        pos: "VERB",
        word_pos_key: "explore-VERB",
        text_frequency: 1,
        word_difficulty: 0.09745,
      },
      {
        word_root: "aesthetic",
        pos: "NOUN",
        word_pos_key: "aesthetic-NOUN",
        text_frequency: 1,
        word_difficulty: 0.29025,
      },
      {
        word_root: "die",
        pos: "VERB",
        word_pos_key: "die-VERB",
        text_frequency: 1,
        word_difficulty: 0.05315,
      },
      {
        word_root: "society",
        pos: "NOUN",
        word_pos_key: "society-NOUN",
        text_frequency: 1,
        word_difficulty: 0.04475,
      },
      {
        word_root: "nature",
        pos: "NOUN",
        word_pos_key: "nature-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03435,
      },
      {
        word_root: "age",
        pos: "VERB",
        word_pos_key: "age-VERB",
        text_frequency: 1,
        word_difficulty: 0.0196,
      },
      {
        word_root: "amherst",
        pos: "ADV",
        word_pos_key: "amherst-ADV",
        text_frequency: 1,
        word_difficulty: -1.0,
      },
      {
        word_root: "acquaintance",
        pos: "NOUN",
        word_pos_key: "acquaintance-NOUN",
        text_frequency: 2,
        word_difficulty: 0.3829,
      },
      {
        word_root: "likely",
        pos: "ADV",
        word_pos_key: "likely-ADV",
        text_frequency: 1,
        word_difficulty: 0.03065,
      },
      {
        word_root: "aware",
        pos: "ADJ",
        word_pos_key: "aware-ADJ",
        text_frequency: 1,
        word_difficulty: 0.06545,
      },
      {
        word_root: "writing",
        pos: "NOUN",
        word_pos_key: "writing-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0286,
      },
      {
        word_root: "young",
        pos: "ADJ",
        word_pos_key: "young-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0155,
      },
      {
        word_root: "sister",
        pos: "NOUN",
        word_pos_key: "sister-NOUN",
        text_frequency: 3,
        word_difficulty: 0.08145,
      },
      {
        word_root: "discover",
        pos: "VERB",
        word_pos_key: "discover-VERB",
        text_frequency: 1,
        word_difficulty: 0.1163,
      },
      {
        word_root: "cache",
        pos: "NOUN",
        word_pos_key: "cache-NOUN",
        text_frequency: 1,
        word_difficulty: 0.1198,
      },
      {
        word_root: "work",
        pos: "NOUN",
        word_pos_key: "work-NOUN",
        text_frequency: 3,
        word_difficulty: 0.0021,
      },
      {
        word_root: "public",
        pos: "NOUN",
        word_pos_key: "public-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00535,
      },
      {
        word_root: "collection",
        pos: "NOUN",
        word_pos_key: "collection-NOUN",
        text_frequency: 2,
        word_difficulty: 0.02995,
      },
      {
        word_root: "poetry",
        pos: "NOUN",
        word_pos_key: "poetry-NOUN",
        text_frequency: 2,
        word_difficulty: 0.13155,
      },
      {
        word_root: "mater",
        pos: "NOUN",
        word_pos_key: "mater-NOUN",
        text_frequency: 1,
        word_difficulty: 0.6692407058720498,
      },
      {
        word_root: "personal",
        pos: "ADJ",
        word_pos_key: "personal-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0119,
      },
      {
        word_root: "heavily",
        pos: "ADV",
        word_pos_key: "heavily-ADV",
        text_frequency: 1,
        word_difficulty: 0.1494,
      },
      {
        word_root: "edited",
        pos: "ADJ",
        word_pos_key: "edited-ADJ",
        text_frequency: 1,
        word_difficulty: 0.03275,
      },
      {
        word_root: "notable",
        pos: "ADJ",
        word_pos_key: "notable-ADJ",
        text_frequency: 1,
        word_difficulty: 0.23985,
      },
      {
        word_root: "list",
        pos: "NOUN",
        word_pos_key: "list-NOUN",
        text_frequency: 1,
        word_difficulty: 0.004,
      },
      {
        word_root: "poem",
        pos: "VERB",
        word_pos_key: "poem-VERB",
        text_frequency: 1,
        word_difficulty: 0.16955,
      },
      {
        word_root: "content",
        pos: "NOUN",
        word_pos_key: "content-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01055,
      },
      {
        word_root: "article",
        pos: "NOUN",
        word_pos_key: "article-NOUN",
        text_frequency: 1,
        word_difficulty: 0.01455,
      },
      {
        word_root: "reveal",
        pos: "VERB",
        word_pos_key: "reveal-VERB",
        text_frequency: 1,
        word_difficulty: 0.15325,
      },
      {
        word_root: "edit",
        pos: "NOUN",
        word_pos_key: "edit-NOUN",
        text_frequency: 1,
        word_difficulty: 0.03275,
      },
      {
        word_root: "deliberately",
        pos: "ADV",
        word_pos_key: "deliberately-ADV",
        text_frequency: 1,
        word_difficulty: 0.26815,
      },
      {
        word_root: "remove",
        pos: "VERB",
        word_pos_key: "remove-VERB",
        text_frequency: 1,
        word_difficulty: 0.04915,
      },
      {
        word_root: "dedicated",
        pos: "ADJ",
        word_pos_key: "dedicated-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0633,
      },
      {
        word_root: "law",
        pos: "NOUN",
        word_pos_key: "law-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0126,
      },
      {
        word_root: "dedication",
        pos: "NOUN",
        word_pos_key: "dedication-NOUN",
        text_frequency: 1,
        word_difficulty: 0.23645,
      },
      {
        word_root: "obliterate",
        pos: "VERB",
        word_pos_key: "obliterate-VERB",
        text_frequency: 1,
        word_difficulty: 0.70755,
      },
      {
        word_root: "presumably",
        pos: "ADV",
        word_pos_key: "presumably-ADV",
        text_frequency: 1,
        word_difficulty: 0.26245,
      },
      {
        word_root: "complete",
        pos: "ADJ",
        word_pos_key: "complete-ADJ",
        text_frequency: 1,
        word_difficulty: 0.0136,
      },
      {
        word_root: "unaltered",
        pos: "ADJ",
        word_pos_key: "unaltered-ADJ",
        text_frequency: 1,
        word_difficulty: 0.56885,
      },
      {
        word_root: "available",
        pos: "ADJ",
        word_pos_key: "available-ADJ",
        text_frequency: 1,
        word_difficulty: 0.00255,
      },
      {
        word_root: "time",
        pos: "NOUN",
        word_pos_key: "time-NOUN",
        text_frequency: 1,
        word_difficulty: 0.00085,
      },
      {
        word_root: "scholar",
        pos: "NOUN",
        word_pos_key: "scholar-NOUN",
        text_frequency: 1,
        word_difficulty: 0.28545,
      },
      {
        word_root: "brother",
        pos: "NOUN",
        word_pos_key: "brother-NOUN",
        text_frequency: 1,
        word_difficulty: 0.07335,
      },
      {
        word_root: "childhood",
        pos: "NOUN",
        word_pos_key: "childhood-NOUN",
        text_frequency: 1,
        word_difficulty: 0.15185,
      },
      {
        word_root: "teenage",
        pos: "NOUN",
        word_pos_key: "teenage-NOUN",
        text_frequency: 1,
        word_difficulty: 0.13205,
      },
      {
        word_root: "influence",
        pos: "NOUN",
        word_pos_key: "influence-NOUN",
        text_frequency: 1,
        word_difficulty: 0.06715,
      },
      {
        word_root: "write",
        pos: "VERB",
        word_pos_key: "write-VERB",
        text_frequency: 1,
        word_difficulty: 0.02845,
      },
      {
        word_root: "seclusion",
        pos: "NOUN",
        word_pos_key: "seclusion-NOUN",
        text_frequency: 1,
        word_difficulty: 0.5811,
      },
      {
        word_root: "verse",
        pos: "NOUN",
        word_pos_key: "verse-NOUN",
        text_frequency: 1,
        word_difficulty: 0.20825,
      },
      {
        word_root: "alive",
        pos: "ADJ",
        word_pos_key: "alive-ADJ",
        text_frequency: 1,
        word_difficulty: 0.1184,
      },
      {
        word_root: "woman",
        pos: "NOUN",
        word_pos_key: "woman-NOUN",
        text_frequency: 1,
        word_difficulty: 0.0318,
      },
    ],
    textLanguage: "en",
  }, */
];
