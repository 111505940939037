import { getHotkeyHandler } from "@mantine/hooks";
import { useState } from "react";

export default function ClozeInput({
  preClozeWord,
  clozeWord,
  postClozeWord,
  isSubmitted,
  isCorrect,
  onSubmit,
  isMC = false,
  choices = [],
  children,
  isLight = false,
}) {
  const [clozeInput, setClozeInput] = useState("");

  const handleSubmit = (input = null) => {
    if (input) {
      onSubmit(input);
    } else {
      onSubmit(clozeInput);
    }
  };

  return (
    <div>
      <div
        className={`rounded-lg relative ${
          isLight ? "bg-white" : "bg-gray-50 shadow-sm"
        }`}
      >
        <div className="p-6">
          <div className="text-xl text-gray-900 inline mr-2 leading-9">
            {preClozeWord}
            {isMC ? (
              <div className="px-12 bg-gray-300 rounded-md border-2 border-gray-400 inline text-gray-600">
                ?
              </div>
            ) : (
              <>
                {isSubmitted ? (
                  <div className="inline">
                    {clozeInput !== clozeWord && (
                      <p
                        className={`mr-2 inline ${
                          isCorrect ? "bg-yellow-100" : "bg-red-100"
                        }`}
                      >
                        {clozeInput}
                      </p>
                    )}
                    <p className="inline bg-green-100 px-0.5">{clozeWord}</p>
                  </div>
                ) : (
                  <>
                    <input
                      className={`border-b-2 outline-none mx-1 ${
                        isLight
                          ? "bg-gray-100 rounded-lg px-2"
                          : "border-gray-700 bg-gray-50 "
                      }`}
                      value={clozeInput}
                      onChange={(event) => {
                        setClozeInput(event.target.value);
                      }}
                      autoFocus
                      onKeyDown={getHotkeyHandler([
                        ["Enter", () => handleSubmit()],
                      ])}
                    ></input>
                  </>
                )}
              </>
            )}
            {postClozeWord}
          </div>
          {!isSubmitted && !isMC && (
            <div className="pt-8">
              <button
                onClick={() => handleSubmit()}
                className={`absolute text-gray-900 text-lg font-medium bottom-0 ${
                  isLight
                    ? "right-0 bg-gray-100 rounded-lg px-3 py-1.5"
                    : "w-full bg-gray-200 left-0 rounded-b-lg px-1 py-1.5"
                } ${!clozeInput && "cursor-not-allowed"}`}
                disabled={!clozeInput}
              >
                Submit
              </button>
            </div>
          )}
        </div>

        {isMC && (
          <div
            className={`${
              choices.length >= 4
                ? "grid grid-cols-2 gap-0.5"
                : "flex flex-col space-y-1"
            }`}
          >
            {choices.map((e, i) => (
              <button
                className={`bg-gray-200 text-gray-900 px-1 py-1.5 text-lg font-medium hover:bg-opacity-90 ${
                  choices.length < 4
                    ? i + 1 === choices.length && "rounded-b-lg"
                    : (i === 2 && "rounded-bl-lg") ||
                      (i === 3 && "rounded-br-lg")
                } ${
                  isSubmitted &&
                  clozeWord.toLowerCase() === e.toLowerCase() &&
                  "bg-green-100"
                } ${
                  isSubmitted &&
                  clozeWord.toLowerCase() !== e.toLowerCase() &&
                  clozeInput.toLowerCase() === e.toLowerCase() &&
                  "bg-red-100"
                }`}
                onClick={() => {
                  setClozeInput(e);
                  handleSubmit(e);
                }}
                disabled={isSubmitted}
              >
                {e}
              </button>
            ))}
          </div>
        )}
      </div>

      {children}
    </div>
  );
}
