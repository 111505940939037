import React from "react";
import ButtonWithShortcut from "../universal-components/buttons/button-with-shortcut.component";
import { connect } from "react-redux";
import Notification from "../universal-components/notification.component";
import Translations from "./dynamic-card-configuration-components/configuration-components/translations.component";
import Definitions from "./dynamic-card-configuration-components/configuration-components/definitions.component";
import SelectTranslationLanguage from "./dynamic-card-configuration-components/select-translation-language.component";
import MultiOptionField from "./dynamic-card-configuration-components/configuration-components/multi-option-field.component";
import Synonyms from "./dynamic-card-configuration-components/configuration-components/synonyms.component";
import Examples from "./examples.component";
import ExampleSelect from "./examples-select.component";
import { Textarea } from "@mantine/core";
import ExamplesNew from "./examples-new.component";

class TextViewAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // props (only for checking component did update)
      activeWord: "",

      // inputs
      wordInput: "",
      translationInput: "",
      definitionInput: "",
      exampleInput: "",
      synonymInput: "",
      tagInput: "",

      // notifications
      isNotification: false,
      notificationTitle: "",
      notificationDescription: "",
      notificationStatus: "",
    };
  }

  updateWordInfo = () => {
    const activeWordInfo = this.props.activeWordInfo;
    this.setState({
      wordInput: activeWordInfo.word_root,
      exampleInput: "",
      activeWord: this.props.activeWordInfo.word_pos_key,
      translationInput: "",
      definitionInput: "",
      showDefinitionSuggestions: false,
      showTranslationSuggestions: false,
    });
  };

  componentDidMount() {
    this.updateWordInfo();
    document.addEventListener("keydown", this.onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUp);
  }

  onKeyUp = (event) => {
    if (
      event.key === "Enter" &&
      (event.altKey || event.ctrlKey || event.metaKey)
    ) {
      this.assertFullCardSubmit();
    }
    if (
      event.key === "Backspace" &&
      (event.altKey || event.ctrlKey || event.metaKey)
    ) {
      this.handleWordRemove();
    }
  };

  componentDidUpdate() {
    // check if prev passed down word equals word passed down on update
    if (this.state.activeWord !== this.props.activeWordInfo.word_pos_key) {
      this.updateWordInfo();
    }
  }

  assertFullCardSubmit = () => {
    if (!this.state.exampleInput) {
      this.setState({
        isNotification: true,
        notificationTitle: "Add at least one example",
        notificationDescription: "",
        notificationStatus: "warning",
      });
    } else if (
      (!this.state.definitionInput ||
        JSON.parse(this.state.definitionInput).length === 0) &&
      !this.state.synonymInput &&
      !this.state.translationInput
    ) {
      this.setState({
        isNotification: true,
        notificationTitle: "Add at least one descriptor",
        notificationDescription: "...synonym, translation, or definition",
        notificationStatus: "warning",
      });
    } else {
      this.submitWord();
    }

    /*     this.setState({
      isNotification: true,
      notificationTitle: "Missing fields: ",
      notificationDescription: missingFields.join(", "),
      notificationStatus: "warning",
    }); */
  };

  submitWord() {
    /* TODO: Word original is added on the backend based on dictionary/API table --> remove word_original */
    fetch(
      `${process.env.REACT_APP_API_URL}/word/1?word_root=${
        this.state.wordInput
      }&translation=${this.state.translationInput}&definition=${
        this.state.definitionInput
      }&examples=${JSON.stringify(this.state.exampleInput)}&pos=${
        this.props.activeWordInfo.pos
      }&deck_id=${parseInt(this.props.deckId)}&user_id=${
        this.props.userApiId
      }&tags=${this.state.tagInput}&synonyms=${this.state.synonymInput}${
        this.props.userApiId.toString() === process.env.REACT_APP_ADMIN_API_ID
          ? "&admin_id=".concat(process.env.REACT_APP_ADMIN_API_ID)
          : ""
      }`,
      { method: "POST", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "success") {
          this.setState({
            isNotification: true,
            notificationTitle: "Word added!",
            notificationDescription: "",
            notificationStatus: "success",
          });
          this.props.onSuccessfulCardSubmit();
        } else {
          this.setState({
            isNotification: true,
            notificationTitle: "Submit Failed",
            notificationDescription: result.message,
            notificationStatus: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          isNotification: true,
          notificationTitle: "Submit Failed",
          notificationDescription: error,
          notificationStatus: "error",
        });
      });
  }

  handleWordRemove() {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/known-words/${
        this.props.userApiId
      }?word_root=${this.props.activeWordInfo["word_root"]}&word_original=${
        this.props.activeWordInfo["word_root"]
      }&pos=${this.props.activeWordInfo["pos"]}&language=${"en"}`,
      { method: "POST", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .then((response) => {
        this.props.onWordsRemove(response);
      })
      .catch((error) => console.error(error));
  }

  render() {
    // conditional rendering
    const activeStep = this.props.activeStep;

    const deckArray = this.props.currentDecks;
    let studyLanguage;
    let translationLanguage;
    for (let i = 0; i < deckArray.length; i++) {
      if (parseInt(deckArray[i].id) === parseInt(this.props.deckId)) {
        studyLanguage = deckArray[i].study_language;
        translationLanguage = deckArray[i].translation_language;
      }
    }

    let texts = [];
    if (this.props.activeWordInfo.texts) {
      texts = this.props.activeWordInfo.texts.map((e) => e.name);
    } else {
      if (this.props.activeWordInfo.original_words_list[0].source_name) {
        texts.push(
          this.props.activeWordInfo.original_words_list[0].source_name
        );
      }
    }

    return (
      <div className="space-y-4 pb-12">
        {this.state.isNotification && (
          <Notification
            title={this.state.notificationTitle}
            description={this.state.notificationDescription}
            status={this.state.notificationStatus}
            onMount={() => this.setState({ isNotification: false })}
            position="bottom"
          />
        )}

        <div className="flex justify-start text-gray-900 font-semibold text-lg">
          Add
          <p className="bg-yellow-100 px-2 text-md mx-2 font-medium">
            {this.props.activeWordInfo.word_display}
          </p>
          to your vocabulary
        </div>

        {/* Later make this visible if user clicks on "Edit" example sentences */}
        {this.props.userApiId.toString() ===
        process.env.REACT_APP_ADMIN_API_ID ? (
          <>
            <Textarea
              value={this.state.exampleInput[0]}
              onChange={(event) =>
                this.setState({ exampleInput: [event.currentTarget.value] })
              }
              autosize
            />

            <ExampleSelect
              wordInfo={this.props.activeWordInfo}
              onSubmit={(value) =>
                this.setState({
                  exampleInput: [
                    this.props.activeWordInfo.original_words_list[value][
                      "sent"
                    ],
                  ],
                })
              }
            />
          </>
        ) : (
          <div>
            {/* <Examples wordInfo={this.props.activeWordInfo} /> */}
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Examples
            </label>
            <ExamplesNew
              exampleList={this.props.activeWordInfo.original_words_list}
              onChange={(values) => this.setState({ exampleInput: values })}
              examples={this.state.exampleInput}
            />
          </div>
        )}

        <div className="flex flex-col space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Synonyms
            </label>
            <Synonyms
              key={this.props.activeWordInfo.word_root}
              wordInput={this.props.activeWordInfo.word_root}
              wordPos={this.props.activeWordInfo.pos}
              sourceLanguage={studyLanguage}
              onSynonymChange={(synonyms) =>
                this.setState({
                  synonymInput: synonyms.join(", "),
                })
              }
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Translations
            </label>

            {(translationLanguage || this.state.translationLanguage) &&
            this.props.activeWordInfo.word_root ? (
              <Translations
                key={this.props.activeWordInfo.word_root}
                wordInput={this.props.activeWordInfo.word_root}
                wordPos={this.props.activeWordInfo.pos}
                sourceLanguage={studyLanguage}
                targetLanguage={
                  translationLanguage
                    ? translationLanguage
                    : this.state.translationLanguage
                }
                onTranslationChange={(translations) => {
                  this.setState({
                    translationInput: translations
                      .filter((e) => e !== "")
                      .join(", "),
                  });
                }}
              />
            ) : (
              <div>
                <SelectTranslationLanguage
                  textLanguage={studyLanguage}
                  deckId={this.props.deckId}
                  onSelect={(value) => {
                    this.setState({ translationLanguage: value });
                  }}
                />
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Definition
            </label>
            <Definitions
              key={this.props.activeWordInfo.word_root}
              word={this.props.activeWordInfo.word_root}
              pos={this.props.activeWordInfo.pos}
              onDefinitionChange={(value) => {
                this.setState({ definitionInput: JSON.stringify(value) });
              }}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Tags
            </label>
            <MultiOptionField
              key={this.props.activeWordInfo.word_pos_key}
              isSuggestions={texts.length > 0}
              suggestions={texts}
              onChange={(values) =>
                this.setState({
                  tagInput: values.join(", "),
                })
              }
              placeholderText="Add a tag"
            />
          </div>

          <div className="flex justify-between sm:pt-6 pt-4">
            <ButtonWithShortcut
              textMain="Submit Word"
              textShortcut=""
              onButtonClick={() => this.assertFullCardSubmit()}
            />
            <div className="self-end">
              <button
                type="button"
                onClick={() => this.handleWordRemove()}
                className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
  currentDecks: decks.currentDecks,
});

export default connect(mapStateToProps)(TextViewAdd);
