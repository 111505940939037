import { Chip, Chips } from "@mantine/core";
import { useSelector } from "react-redux";
import Emoji from "../../universal-components/emoji.component";
import { languages_dict } from "../../app-configuration/dropdown-inputs";

const textLanguages = [
  {
    code: "en",
    name: "English",
    symbol: "🇬🇧",
  },
  {
    code: "fr",
    name: "French",
    symbol: "🇫🇷",
  },
  {
    code: "es",
    name: "Spanish",
    symbol: "🇪🇸",
  },
  {
    code: "de",
    name: "German",
    symbol: "🇩🇪",
  },
  {
    code: "it",
    name: "Italian",
    symbol: "🇮🇹",
  },
  {
    code: "pt",
    name: "Portuguese",
    symbol: "🇵🇹",
  },
];

export default function SelectTranslationLanguage({
  textLanguage,
  onSelect,
  deckId,
}) {
  const userApiId = useSelector((state) => state.decks.user_api_id);

  const token = useSelector((state) => state.user.token);

  const setTranslationLanguage = (value) => {
    const headers = new Headers({ Authorization: token });

    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/deck/${deckId}?name=${""}&study_language=${textLanguage}&translation_language=${value}&exercise_settings=${""}&user_id=${userApiId}`,
      { method: "PUT", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => onSelect(value))
      .catch((error) => window.alert(error));
  };

  return (
    <>
      {/*       <Chips
        value={""}
        onChange={setTranslationLanguage}
        color="orange"
        variant="filled"
        size="xs"
        radius="sm"
      >
        {textLanguages
          .filter((e) => e.code !== textLanguage)
          .map((lang) => (
            <Chip value={lang.code}>
              ...to {lang.name} <Emoji symbol={lang.symbol} />
            </Chip>
          ))}
      </Chips> */}
      <div className="space-y-2">
        <select
          onChange={(event) => setTranslationLanguage(event.target.value)}
          value={textLanguage}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
        >
          <option hidden="true">Select Translation Language</option>
          {languages_dict["en"].translations.map((lang) => (
            <option value={lang.code}>{lang.name}</option>
          ))}
        </select>
      </div>
    </>
  );
}
