import { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import Chip from "./chip.component";
import { getHotkeyHandler } from "@mantine/hooks";

export default function MultiOptionField({
  isSuggestions = false,
  suggestions = [],
  onChange,
  placeholderText,
  isEdit,
}) {
  const [options, setOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [input, setInput] = useState("");
  const [maxResults, setMaxResults] = useState(4);

  useEffect(() => {
    setOptions(suggestions);
    if (isEdit) {
      setActiveOptions(suggestions);
    }
  }, []);

  const handleEntry = () => {
    if (input) {
      if (options.includes(input) && options.indexOf(input) >= maxResults) {
        setMaxResults(options.indexOf(input) + 1);
      }
      if (!options.includes(input)) {
        setOptions((prev) => [input, ...prev]);
      }
      if (!activeOptions.includes(input)) {
        setActiveOptions((prev) => [input, ...prev]);
      }
      setInput("");
      onChange([...activeOptions, input]);
    }
  };

  return (
    <div className="-mb-2">
      <div className="flex flex-row flex-wrap items-center">
        {options.slice(0, maxResults).map((option, i) => (
          <div className="pb-2 pr-2">
            <Chip
              key={i}
              value={option}
              isChecked={activeOptions.includes(option)}
              onCheck={(isChecked) => {
                if (!isChecked) {
                  setActiveOptions((prev) => [...prev, option]);
                  onChange([...activeOptions, option]);
                } else {
                  setActiveOptions((prev) => prev.filter((e) => e !== option));
                  onChange(activeOptions.filter((e) => e !== option));
                }
              }}
            />
          </div>
        ))}
        {options.length > maxResults && (
          <button
            className="inline-flex items-center px-2 py-1 rounded-xl text-sm font-medium border-2 bg-gray-50 mb-2 mr-2"
            onClick={() => setMaxResults(maxResults + 10)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
        )}
        {isSuggestions && options.length === 0 && (
          <p className="text-sm font-medium text-gray-700 pb-2 mr-2">
            No suggestions
          </p>
        )}
        <div className="w-40 pb-2 pr-2">
          <Input
            value={input}
            size="sm"
            rounded="xl"
            focusBorderColor="gray.500"
            onChange={(event) => setInput(event.target.value)}
            placeholder={placeholderText}
            variant="filled"
            onKeyDown={getHotkeyHandler([["Enter", handleEntry]])}
          />
        </div>
      </div>
    </div>
  );
}
