import React from "react";

class SingleSentence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translations: [[]],
            translationSuggestion: [],

            // conditional
            isTranslation: false,
        }
    }

    onSentenceSelect = () => {
        if (this.state.isTranslation) {
            this.setState({ isTranslation: false })
        } else {
            const inputText = this.props.sourceText
            const sourceLang = "EN"
            const targetLang = "DE"
            fetch(`https://api.deepl.com/v2/translate?auth_key=${process.env.REACT_APP_DEEPL_KEY}&text=${inputText}&source_lang=${sourceLang}&target_lang=${targetLang}`, { method: "GET", redirect: "follow" })
                .then(response => response.json())
                .then(data => this.setState({ translations: data.translations, isTranslation: !this.state.isTranslation, isSelected: false }))
                .catch(error => window.alert(error))
        }
    }

    render() {
        return (
            <p onDoubleClick={this.onSentenceSelect} className={`${(this.props.isKnown !== "true") && "cursor-pointer"} inline text-lg font-light leading-loose text-gray-900 select-none`}>
                { this.state.isTranslation &&
                    <div className="p-2 bg-yellow-100 -mx-4 my-2 text-base">
                        <p>
                            {this.state.translations[0]["text"]}
                        </p>
                    </div>
                }

                <p  onClick={this.props.onSingleClick} 
                    id={this.props.id} 
                    className={`inline ${(this.props.isKnown !== "true") && "hover:text-yellow-900"} ${(this.state.isTranslation) && "text-gray-500 hover:text-gray-700"}`}>
                    {`${this.props.sourceText} `}
                </p>
            </p>
        )
    }
}

export default SingleSentence