import React from "react";

const ProgressBar = ({ itemsDone, itemsOpen, children }) => (
  <div className="relative pt-1">
    <div className={`flex flex-row items-center pb-1 justify-between`}>
      <div>{children}</div>
      <p className="text-xs text-right text-gray-700 mr-1">
        {itemsDone} / {itemsOpen} completed
      </p>
    </div>
    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
      <div
        style={{ width: `${(itemsDone / itemsOpen) * 100}%` }}
        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-300"
      ></div>
    </div>
  </div>
);

export default ProgressBar;
