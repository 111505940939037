import { useHotkeys } from "@mantine/hooks";
import { useState } from "react";
import Examples from "../../../app-dynamic-upload/examples.component";
import ButtonWithShortcut from "../../../universal-components/buttons/button-with-shortcut.component";
import RateExercise from "./rate-exercise.component";
import SingleFeedback from "./single-feedback.component";

export default function QuizFeedback({ feedback, onAction, exercise }) {
  useHotkeys([["Enter", onAction]]);

  const [showExamples, setShowExamples] = useState(false);

  let color = "yellow-50";
  if (feedback[0] === "correct") {
    color = "green-50";
  }
  if (feedback[0] === "incorrect") {
    color = "red-100";
  }

  return (
    <div>
      <div className={`flex flex-col p-4 rounded-md bg-${color} shadow-sm`}>
        <div className="flex flex-row justify-between items-center">
          <SingleFeedback feedback={feedback} />

          <div className="flex justify-end">
            <ButtonWithShortcut
              textMain="Next"
              textShortcut="↵"
              onButtonClick={onAction}
              color="black"
            />
          </div>
        </div>
      </div>
      {showExamples && exercise["type"] === "assess" && (
        <div className="mt-4 mb-2 mx-2">
          <p className="font-bold">Examples:</p>
          <Examples wordInfo={exercise["word_info"]} showSource/>
        </div>
      )}
      {!showExamples && exercise["type"] === "assess" && (
        <div className="flex justify-center items-center">
          <button
            onClick={() => setShowExamples(true)}
            className="font-normal italic text-sm mt-2"
          >
            Show examples
          </button>
        </div>
      )}
      {exercise["exercise_id"] &&
        exercise["type"] === "assess" &&
        exercise["language"] && (
          <div className="mt-6">
            <RateExercise
              wordId={exercise["word_id"]}
              language={exercise["language"]}
              type="assess"
              exerciseId={exercise["exercise_id"]}
            />
          </div>
        )}
    </div>
  );
}
