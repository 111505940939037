import React from "react";
import { connect } from "react-redux";
import AddDeckPopup from "../../app-dynamic-upload/add-deck-popup.component";
import DeckLanguageInformation from "../../app-dynamic-upload/language-display-components/deck-language-information.component";

class DeckDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deckId: null,
      deckSelection: [],
    };
    this.handleDeckChange = this.handleDeckChange.bind(this);
  }

  componentDidMount() {
    this.setDefaultDeck();
  }

  componentDidUpdate(prevProps) {
    // if prevProps target language and new target language don't match, reset deckId
    if (prevProps.targetLanguage !== this.props.targetLanguage) {
      console.log(prevProps.targetLanguage, this.props.targetLanguage);
      this.setDefaultDeck();
    }
  }

  setDefaultDeck = () => {
    const deckSelection = this.props.currentDecks.filter(
      (deck) => deck["study_language"] === this.props.targetLanguage
    );
    if (deckSelection.length === 0) {
      this.setState({ deckId: null });
    } else {
      this.setState({ deckId: deckSelection[0].id });
      this.props.onDeckChange(deckSelection[0].id);
    }
    this.setState({ deckSelection: deckSelection });
  };

  addDeck = (currentDecks) => {
    const deckSelection = currentDecks.filter(
      (deck) => deck["study_language"] === this.props.targetLanguage
    );
    const deckId = deckSelection[deckSelection.length - 1].id;
    this.setState({
      deckId: deckId,
      deckSelection: deckSelection,
    });
    this.props.onDeckChange(deckId);
  };

  handleDeckChange = (event) => {
    const deckId = event.target.value;
    this.setState({ deckId: deckId });
    this.props.onDeckChange(deckId);
  };

  render() {
    return (
      <div className="max-w-lg">
        <label className="block text-sm font-medium text-gray-700">
          Select Deck
        </label>
        <div className="flex flex-row items-center space-x-1 mt-1">
          <select
            onChange={this.handleDeckChange}
            value={this.state.deckId}
            id="deck-name"
            name="deck-name"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
          >
            {this.state.deckSelection.map((d) => (
              <option value={d.id}>{d.name}</option>
            ))}
          </select>
          <AddDeckPopup
            onSubmit={(currentDecks) => this.addDeck(currentDecks)}
          />
        </div>

        {this.state.deckSelection.length === 0 && (
          <p className="mt-2 ml-1 text-sm font-medium text-gray-900">
            You need to add a deck for this language first.
          </p>
        )}

        {this.state.deckId && (
          <div className="mt-2">
            <DeckLanguageInformation deckId={this.state.deckId} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  userApiId: decks.user_api_id,
  targetLanguage: decks.target_language,
});

export default connect(mapStateToProps)(DeckDropdown);
