import React from "react"

// <Calendly url="" />

class Calendly extends React.Component {
    componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
    componentWillUnmount() {
      // whatever you need to cleanup the widgets code
    }
    render(){
      return (
        <div>
          <div id="schedule_form">
            <div 
              className="calendly-inline-widget"
              data-url={this.props.url}
              style={{ minWidth: '400px', height: '630px' }} />
          </div>
        </div>
      );
    }
  }

export default Calendly;