import { Spinner, useToast } from "@chakra-ui/react";
import { useHotkeys } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Translations from "../../../../../app-dynamic-upload/dynamic-card-configuration-components/configuration-components/translations.component";
import SelectTranslationLanguage from "../../../../../app-dynamic-upload/dynamic-card-configuration-components/select-translation-language.component";
import ButtonWithShortcut from "../../../../../universal-components/buttons/button-with-shortcut.component";

export default function EditTranslations({
  id,
  word,
  pos,
  deckId,
  label = "You haven't set any translations. Do that now:",
  onSubmit,
}) {
  const [translation, setTranslation] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(true);
  const [targetLanguage, setTargetLanguage] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("");

  const token = useSelector((state) => state.user.token);
  const toast = useToast();

  useHotkeys([
    [
      "mod+Enter",
      () => {
        handleSubmit();
      },
    ],
  ]);

  useEffect(() => {
    const headers = new Headers({ Authorization: token });
    fetch(`${process.env.REACT_APP_API_URL}/deck/${deckId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setTargetLanguage(data.translation_language);
        setSourceLanguage(data.study_language);
        setLoading(false);
      })
      .catch((error) => window.alert("Error: ", error));
  }, [deckId]);

  const handleSubmit = () => {
    if (!translation) {
      toast({
        title: "No translation selected",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/word/${id}?translation=${translation}`,
      { method: "PUT", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
        toast({
          title: "Saved successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onSubmit();
      })
      .catch((error) => window.alert("Error: ", error));
  };

  if (submitted) {
    return <span class="bg-green-50 px-2.5 font-medium">{translation}</span>;
  } else {
    return (
      <div className="flex flex-col items-start space-y-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        {loading ? (
          <div className="pb-2 mr-2">
            <Spinner size="sm" />
          </div>
        ) : (
          <>
            {targetLanguage ? (
              <Translations
                key={word}
                wordInput={word}
                wordPos={pos}
                sourceLanguage={"en"}
                targetLanguage={targetLanguage}
                onTranslationChange={(translations) =>
                  setTranslation(
                    translations.filter((e) => e !== "").join(", ")
                  )
                }
              />
            ) : (
              <SelectTranslationLanguage
                textLanguage={"en"}
                deckId={deckId}
                onSelect={(value) => {
                  setTargetLanguage(value);
                }}
              />
            )}
          </>
        )}
        {translation && (
          <div className="pt-4">
            <ButtonWithShortcut
              textMain="Submit"
              textShortcut="cmd + ↵"
              onButtonClick={handleSubmit}
            />
          </div>
        )}
      </div>
    );
  }
}
