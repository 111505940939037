import React, { useState } from "react";
import Emoji from "../../../components/universal-components/emoji.component";

export default function LevelButton(props) {
  const [isActive, setIsActive] = useState(false);
  return (
    <button
      onClick={() => {
        props.onButtonClick();
        setIsActive(!isActive);
      }}
      type="button"
      className={`${
        isActive ? "bg-yellow-300" : "bg-gray-200 hover:bg-gray-300"
      } focus:outline-none border border-transparent shadow inline-flex items-center px-3 py-2 leading-4 font-medium rounded-md text-gray-600 mr-2.5 mt-2.5 md:mt-0`}
    >
      <Emoji symbol={props.emojiSymbol} />
      <p className="ml-1">{props.name}</p>
    </button>
  );
}