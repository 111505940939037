import { UserActionTypes } from "./user.types";

// for first time app is initializing, so state isn't empty; similar to this.state
const INITIAL_STATE = {
  currentUser: null,
  token: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        // pass in everything that we don't want to modify
        ...state,
        // update what we do want to modify
        currentUser: action.payload,
      };
    case UserActionTypes.SET_TOKEN:
      return {
        // pass in everything that we don't want to modify
        ...state,
        // update what we do want to modify
        token: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
