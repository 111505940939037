import { Component } from "react";
import WordInput from "../../../universal-components/form-inputs/word-input.component";
import { Redirect } from "react-router-dom";
import DeckDeleteButton from "../../../universal-functional-components/deck-delete-button.component";
import { connect } from "react-redux";
import { setCurrentDecks } from "../../../../redux/decks/decks.actions";
import DeckLanguageInformation from "../../../app-dynamic-upload/language-display-components/deck-language-information.component";

class DeckSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deckName: "",
      studyLanguage: "",
      translationLanguage: "",
      exerciseSettings: "",
      userId: "",
      renderRedirectLearn: false,
      responseMessage: "",
      selectedFront: [],
      selectedBack: [],
    };
  }

  componentDidMount() {
    if (this.props.currentDecks) {
      this.setDeck();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentDecks !== this.props.currentDecks) {
      this.setDeck();
    }
  }

  setDeck = () => {
    const deck = this.props.currentDecks.find(
      (element) => element["id"] === parseInt(this.props.match.params.deckId)
    );
    this.setState({
      deckName: deck.name,
      studyLanguage: deck.study_language,
      translationLanguage: deck.translation_language,
      userId: deck.user_id,
    });
  };

  assertSubmit = () => {
    if (this.state.deckName === "") {
      window.alert("Enter a name for your deck.");
    } else if (
      this.state.translationLanguage === "" ||
      this.state.studyLanguage === ""
    ) {
      window.alert("Select translation language");
    } else {
      this.setState({
        status: null,
        notificationTextMain: "",
        notificationTextSub: "",
      });
      this.submitChanges();
    }
  };

  submitChanges = () => {
    const deckName = this.state.deckName;
    const studyLanguage = this.state.studyLanguage;
    const translationLanguage = this.state.translationLanguage;
    const exerciseSettings = {
      front: this.state.selectedFront,
      back: this.state.selectedBack,
    };
    const userId = this.state.userId;
    const headers = new Headers({ Authorization: this.props.token });

    // TODO ADD STORY (add assert submit function, also do for add deck)
    if (["list", "of", "the", "users", "decks"].includes(deckName)) {
      window.alert("This deck name already exists.");
    } else if (deckName) {
      fetch(
        `${process.env.REACT_APP_API_URL}/deck/${this.props.match.params.deckId}?name=${deckName}&study_language=${studyLanguage}&translation_language=${translationLanguage}&exercise_settings=${exerciseSettings}&user_id=${userId}`,
        { method: "PUT", redirect: "follow", headers: headers }
      )
        .then((response) => response.json())
        .then((data) => this.refresh(data.message))
        .catch((error) => window.alert(error));
    } else {
      window.alert("Missing fields!");
    }
  };

  refresh = (message) => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/decks/${this.props.userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentDecks(data.decks);
      })
      .then(
        this.setState({ renderRedirectLearn: true, responseMessage: message })
      )
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <div>
        <div className="space-y-4">
          <h1>Deck Settings</h1>
          <WordInput
            inputValue={this.state.deckName}
            onInputChange={(event) =>
              this.setState({ deckName: event.target.value })
            }
            textMainHeadline=""
            textSubHeadline=""
            textMain="Deck Name"
            textOptional=""
          />
          <DeckLanguageInformation deckId={this.props.match.params.deckId} />
        </div>

        {/* <div className="mt-2">
                    {this.state.exerciseSettings &&
                        <ExerciseSettings
                            exerciseSettings={this.state.exerciseSettings}
                            onCardTypeChange={(front, back) => {
                                this.setState({ selectedFront: front, selectedBack: back });
                            }}
                            
                            user_id={this.state.userId}
                        />
                }
                </div> */}

        <div className="flex justify-end mt-4">
          <button
            onClick={this.props.history.goBack}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Cancel
          </button>

          <div className="ml-3">
            <DeckDeleteButton
              deckId={this.props.match.params.deckId}
              token={this.props.token}
            />
          </div>
          <button
            onClick={this.assertSubmit}
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
        {this.state.renderRedirectLearn && (
          <Redirect
            to={`/app/learn/${this.props.match.params.deckId}?message=${this.state.responseMessage}`}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
});

const mapStateToProps = ({ decks, user }) => ({
  currentDecks: decks.currentDecks,
  userApiId: decks.user_api_id,
  token: user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeckSetting);
