import React from "react";
import SingleSentence from "./vocab-professional-components/single-sentence.component";
import SingleWordInfo from "./vocab-professional-components/single-word-info.component";

import "./reading-page-scroll.css"

class ReadingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSentence: null,
        }

        this.currentInfo = null;

        this.setCurrentInfoRef = element => {
            this.currentInfo = element
            this.focusCurrentInfo()
        }

        this.focusCurrentInfo = () => {
            if (this.currentInfo) {
                this.currentInfo.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                });
            }
        }
    }

    render() {
        return (

            <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
                {/* <!-- Left sidebar & main wrapper --> */}
                <div className="flex-1 min-w-0 bg-white md:flex min-h-screen">

                    <div className="bg-white lg:min-w-0 md:flex-1">
                        <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                            {/* <!-- Start main area--> */}
                            <div className="relative h-full" style={{ minHeight: "36rem" }}>
                                {/* border-2 border-gray-200 border-dashed */}
                                <div className="px-10 absolute inset-0 rounded-lg overflow-y-auto Scroll">

                                    <div>
                                        {this.props.info.map(entry =>
                                            <SingleSentence
                                                id={entry.id}
                                                onSingleClick={(event) => this.setState({ activeSentence: event.target.id })} // alternative onMouseOver
                                                isKnown={entry.known}
                                                sourceText={entry.sentence_text}
                                                newWords={entry.new_words} />)}
                                    </div>

                                </div>
                            </div>
                            {/* <!-- End main area --> */}
                        </div>
                    </div>

                    <div className="border-gray-200 md:flex-shrink-0 md:w-72 lg:80 xl:w-96 bg-white">
                        <div className="h-full pl-4 pr-6 py-6">

                            <div className="h-full relative" style={{ minHeight: "12rem" }}>
                                {/* border-2 border-gray-200 border-dashed */}
                                <div className="absolute inset-0 rounded-lg overflow-y-auto Scroll">

                                    {/* Create extra component for selected at the top, hide selected in all words component */}

                                    <div className="md:64 lg:w-72 xl:w-80 pl-2">
                                        <div className="space-y-2 ">
                                            {this.props.newWords.map(word => {
                                                if (parseInt(this.state.activeSentence) !== parseInt(word.id)) {
                                                    return (
                                                        <div>
                                                            <SingleWordInfo id={word.id} word={word} isActive={false} />
                                                        </div>
                                                    )
                                                } else if (parseInt(this.state.activeSentence) === parseInt(word.id)) {
                                                    return(
                                                        <div ref={this.setCurrentInfoRef}>
                                                            <SingleWordInfo id={word.id} word={word} isActive={true} />
                                                        </div>
                                                    ) 
                                                }
                                            }
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-4">

                                        <button type="button" className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">

                                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                            </svg>
                                        </button>




                                    </div>
                                    <div className="flex justify-center mt-1">
                                        <p className="text-xs text-gray-600">Add All</p>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>


                </div>


            </div>


        )
    }

}

export default ReadingPage;