import { Tooltip } from "@chakra-ui/react";
import React from "react";

const buttonOptions = [
  {
    name: "ToDo",
    value: "all",
    tooltip: "All words that haven't been added or removed.",
  },
  {
    name: "Added",
    value: "converted",
    tooltip: "Words you have added in this or previous texts.",
  },
  {
    name: "Known",
    value: "removed",
    tooltip: "Words you have removed in this or previous texts.",
  },
];

export default function WordSectionSelector(props) {
  return (
    <div className="flex flex-row justify-center items-center space-x-1 pr-4">
      {buttonOptions.map((e) => (
        <Tooltip hasArrow label={e.tooltip} bg="gray.600" openDelay={500}>
          <button
            onClick={props.onSectionSelect}
            value={e.value}
            type="button"
            className={`${
              props.activeList === e.value && "bg-yellow-300"
            } font-medium text-sm text-gray-700 px-1`}
          >
            {e.name}
          </button>
        </Tooltip>
      ))}
    </div>
  );
}
