import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => (
<div className="flex flex-col min-h-screen overflow-hidden">

{/*  Page content */}
<main className="flex-grow">

  <section className="relative">

    {/* Illustration behind content */}
    <div className="absolute left-1/2 transform -translate-x-1/2 -mb-64 bottom-0 pointer-events-none" aria-hidden="true">
      <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
            <stop stopColor="#FFF" offset="0%" />
            <stop stopColor="#EAEAEA" offset="77.402%" />
            <stop stopColor="#DFDFDF" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="url(#illustration-01)" fillRule="evenodd">
          <circle cx="1232" cy="128" r="128" />
          <circle cx="155" cy="443" r="64" />
        </g>
      </svg>
    </div>

    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="pt-32 pb-12 md:pt-40 md:pb-20">
        <div className="max-w-3xl mx-auto text-center">
          
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block text-yellow-600 xl:inline">whoopsie-daisy, </span>
            <span className="block xl:inline">this page doesn't seem to exist</span>
            
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Head back to our 
                <Link to="/" className="text-yellow-600"> homepage </Link>
                that does exist. Or seriously question your choice of url.
        </p>


        </div>
      </div>
    </div>
  </section>

</main>

</div>
)

export default PageNotFound