import { Tooltip } from "@chakra-ui/react";
import React from "react";

export default function CardLayoutButton({
  selected,
  onButtonClick,
  value,
  text,
  disabled,
  example,
}) {
  let color;
  if (selected) {
    color = "bg-yellow-100 text-gray-900 border-gray-500 ";
  } else if (disabled) {
    color = "bg-gray-50 text-gray-700";
  } else {
    color = "bg-gray-200 text-gray-700 hover:bg-gray-300";
  }
  return (
    <Tooltip hasArrow label={`E.g. "${example}"`} bg="gray.600" openDelay={500}>
      <button
        onClick={onButtonClick}
        type="button"
        value={value}
        className={`${color} inline-flex items-center px-3 py-2 border-gray-300 shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300`}
      >
        {text}
      </button>
    </Tooltip>
  );
}
