import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Definitions from "../../app-dynamic-upload/dynamic-card-configuration-components/configuration-components/definitions.component";
import MultiOptionField from "../../app-dynamic-upload/dynamic-card-configuration-components/configuration-components/multi-option-field.component";

export default function EditWordPopup({
  resumeEventListener,
  removeEventListener,
  wordId,
  onClose,
  isOpen,
  onDelete,
}) {
  const [wordInput, setWordInput] = useState("");
  const [wordPos, setWordPos] = useState("");
  const [existingTranslations, setExistingTranslations] = useState("");
  const [existingDefinitions, setExistingDefinitions] = useState("");
  const [translationInput, setTranslationInput] = useState("");
  const [definitionInput, setDefinitionInput] = useState("");
  const [exampleInput, setExampleInput] = useState("");

  const toast = useToast();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (isOpen) {
      removeEventListener();

      fetch(`${process.env.REACT_APP_API_URL}/word/${wordId}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((data) => {
          setWordInput(data.word_root);
          setWordPos(data.pos);
          setExistingTranslations(data.translation.split(", "));
          setTranslationInput(data.translation);
          setExistingDefinitions(JSON.parse(data.definition));
          setDefinitionInput(data.definition);
          setExampleInput(data.example);
        })
        .catch((error) => console.log("Error: ", error));
    }
  }, [isOpen]);

  const handleSave = () => {
    resumeEventListener();

    // TODO: let people remove translations, definitions (problem: backend will not update empty values)
    if (!translationInput) {
      window.alert("Add translation")
      return
    } else if (!definitionInput) {
      window.alert("Add definition");
      return
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/word/${wordId}?translation=${translationInput}&definition=${definitionInput}`,
      { method: "PUT", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => window.alert("Error: ", error));

    onClose();

    toast({
      title: "Saved successfully",
      description: "",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDelete = () => {
    resumeEventListener();

    const headers = new Headers({ Authorization: token });

    fetch(`${process.env.REACT_APP_API_URL}/word/${wordId}`, {
      method: "DELETE",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => window.alert("Error: ", error));

    onDelete();
    onClose();

    toast({
      title: "Word deleted",
      description: "",
      status: "info",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        resumeEventListener();
        onClose();
      }}
      size="xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Word</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="space-y-4">
            {/* TODO: think about making "editable" word info "word_display" and showing "word_display" everywhere instead of root  */}
            <div className="space-y-1 ml-1">
              <label className="block text-sm font-medium text-gray-700">
                Word
              </label>
              <Input
                variant="filled"
                value={wordInput}
                onChange={(event) => setWordInput(event.target.value)}
                isDisabled
              />
            </div>
            <div className="space-y-1 ml-1">
              <label className="block text-sm font-medium text-gray-700">
                Translation
              </label>
              {existingTranslations && (
                <MultiOptionField
                  isSuggestions={
                    existingTranslations.filter((e) => e).length > 0
                  }
                  isEdit={existingTranslations.filter((e) => e).length > 0}
                  suggestions={
                    existingTranslations.filter((e) => e).length > 0
                      ? existingTranslations
                      : []
                  }
                  onChange={(translations) => {
                    if (translations.length > 0) {
                      setTranslationInput(
                        translations.filter((e) => e !== "").join(", ")
                      );
                    } else {
                      setTranslationInput("");
                    }
                  }}
                  placeholderText="Add a translation"
                />
              )}
            </div>
            <div className="space-y-1 ml-1">
              <label className="block text-sm font-medium text-gray-700">
                Definition
              </label>
              {existingDefinitions && (
                <Definitions
                  word={wordInput}
                  pos={wordPos}
                  existing={
                    existingDefinitions.length > 0 ? existingDefinitions : []
                  }
                  onDefinitionChange={(value) => {
                    if (value.length > 0) {
                      setDefinitionInput(JSON.stringify(value));
                    } else {
                      setDefinitionInput("");
                    }
                  }}
                />
              )}
            </div>
            <div className="space-y-1 ml-1">
              <label className="block text-sm font-medium text-gray-700">
                Example
              </label>
              <Textarea
                variant="filled"
                value={exampleInput}
                onChange={(event) => setExampleInput(event.target.value)}
                isDisabled
              />
            </div>
          </div>
        </ModalBody>

        <div className="flex flex-row justify-between px-8 py-4">
          <Button
            colorScheme="gray"
            className="justify-center"
            mr={3}
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
          <div>
            <Button colorScheme="green" mr={3} onClick={() => handleSave()}>
              Save
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                resumeEventListener();
                onClose();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
