import { useEffect, useState } from "react";
import { Tooltip, RingProgress } from "@mantine/core";
import { Spinner } from "@chakra-ui/react";

export default function Stats({ deckId }) {
  const [reviewsPast, setReviewsPast] = useState(null);
  const [reviewsDue, setReviewsDue] = useState(null);
  const [reviewsToday, setReviewsToday] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/deck-stats/${deckId}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        setReviewsPast(data.reviews_past);
        setReviewsDue(data.reviews_due);
        setReviewsToday([data.reviews_past_today, data.reviews_due_today]);
      })
      .catch((error) => console.error(error));
  }, []);

  const getIntensity = (value) => {
    const intValue = parseInt(value);
    if (intValue < 10) {
      return ["#fffbeb", "#dbeafe"];
    } else if (intValue < 20) {
      return ["#fef3c7", "#bfdbfe"];
    } else if (intValue < 30) {
      return ["#fde68a", "#93c5fd"];
    } else if (intValue < 40) {
      return ["#fcd34d", "#60a5fa"];
    } else if (intValue < 50) {
      return ["#fbbf24", "#3b82f6"];
    } else if (intValue < 60) {
      return ["#f59e0b", "#2563eb"];
    } else if (intValue < 70) {
      return ["#d97706", "#1d4ed8"];
    } else if (intValue < 80) {
      return ["#b45309", "#1e40af"];
    } else {
      return ["#92400e", "#1e3a8a"];
    }
  };

  if (!reviewsPast || !reviewsDue || !reviewsToday) {
    return (
      <div>
        <Spinner size="sm" />
      </div>
    );
  } else
    return (
      <div className="flex flex-row justify-start items-start">
        <div className="flex flex-col items-start">
          <label class="text-xs font-medium text-gray-400 mb-0.5">
            Past | Today | Future
          </label>
          <div className="flex flex-row space-x-2">
            <div className="grid grid-rows-7 grid-flow-col gap-1 border-2 border-gray-300 p-1">
              {reviewsPast?.map((entry) => (
                <Tooltip
                  key={entry.date}
                  label={`${entry.date}: ${entry.value} repetitions`}
                  position="bottom"
                  withArrow
                >
                  <div
                    style={{
                      backgroundColor: `${
                        entry.value === 0
                          ? "#f1f5f9"
                          : getIntensity(entry.value)[0]
                      }`,
                    }}
                    className="py-1.5 px-3"
                  ></div>
                </Tooltip>
              ))}
            </div>
            <div className="grid grid-rows-2 grid-flow-col border-2 border-gray-300 p-1 space-y-1">
              <Tooltip
                style={{
                  backgroundColor: `${getIntensity(reviewsToday[1])[1]}`,
                }}
                className="py-1.5 px-2"
                label={`Due today ${reviewsToday[1]}`}
                position="bottom"
                withArrow
              ></Tooltip>
              <Tooltip
                style={{
                  backgroundColor: `${getIntensity(reviewsToday[0])[0]}`,
                }}
                className="py-1.5 px-2"
                label={`Done today ${reviewsToday[0]}`}
                position="bottom"
                withArrow
              ></Tooltip>
            </div>

            <div className="grid grid-rows-7 grid-flow-col gap-1 border-2 border-gray-300 p-1">
              {reviewsDue?.map((entry) => (
                <Tooltip
                  key={entry.date}
                  label={`${entry.date}: ${entry.value} cards due`}
                  position="bottom"
                  withArrow
                >
                  <div
                    style={{
                      backgroundColor: `${
                        entry.value === 0
                          ? "#f1f5f9"
                          : getIntensity(entry.value)[1]
                      }`,
                    }}
                    className="py-1.5 px-3"
                  ></div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col ml-12">
        <label class="text-sm font-medium text-gray-900 mb-2">Progress</label>
        <RingProgress
          className="-mt-6 -ml-4"
          size={160}
          thickness={30}
          sections={[
            { value: 40, color: "cyan" },
            { value: 15, color: "orange" },
            { value: 15, color: "grape" },
          ]}
        />
      </div> */}
      </div>
    );
}
