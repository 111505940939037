import { Title } from "@mantine/core";
import Emoji from "../../components/universal-components/emoji.component";
import ProgrammaticQuizList from "./programatic-quiz-list.component";

export default function ProgrammaticQuiz() {
  return (
    <div className="bg-yellow-50 min-h-screen flex flex-col space-y-4">
      <div className="px-6 sm:px-8 md:p-6 mx-auto max-w-3xl text-center pt-6">
        <Title order={1}>
          Movie Vocab Quizzes <Emoji symbol="🍿" />
        </Title>
        <div className="flex flex-col space-y-2 justify-center items-center mt-6">
          <ProgrammaticQuizList />
        </div>
      </div>
    </div>
  );
}
