import { useToast } from "@chakra-ui/react";

export default function Notification(props) {
  const toast = useToast();
  props.onMount();
  return toast({
    title: props.title,
    description: props.description,
    status: props.status,
    variant: props.variant,
    duration: props.duration,
    isClosable: props.isClosable,
    position: props.position,
  });
}

Notification.defaultProps = {
  title: "Notification",
  description: "Description",
  status: "success", // 'success', 'error', 'warning', 'info'
  variant: "subtle", // 'solid', 'subtle', 'left-accent', 'top-accent'
  duration: 5000,
  isClosable: true,
  position: "top-right", // 'top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left'
};
