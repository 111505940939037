import React from "react";
import { connect } from "react-redux";
import SectionHeading from "../../pages/app/app-page-components/section-heading.component";
import SentenceAdder from "./sentence-adder.component";

class AddSentence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentenceAdders: 1,
    };
  }

  render() {
    return (
      <div>
        <SectionHeading
          headingText="Add from Single Sentences"
          isButton={false}
          buttonText=""
          buttonLink=""
        />

        <div className="space-y-8">
          {[...Array(this.state.sentenceAdders)].map((e) => (
            <SentenceAdder
              key={e}
              onAddAnother={() =>
                this.setState((prevState) => ({
                  sentenceAdders: prevState.sentenceAdders + 1,
                }))
              }
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
});

export default connect(mapStateToProps)(AddSentence);
