import { Spinner } from "@chakra-ui/react";

export default function LoadingPage() {
  return (
    <div className="flex h-screen bg-yellow-50">
      <div className="m-auto">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="white"
          color="yellow.500"
          size="xl"
        />
      </div>
    </div>
  );
}
