import { useEffect, useMemo, useState } from "react";
import { useRouter } from "../../hooks/useRouter";
import { Modal, Title, Slider, Tooltip } from "@mantine/core";
import WordInfoContent from "./movie-components/word-info-content.component";
import MoreInfo from "./movie-components/more_info.component";
import { Checkbox } from "@chakra-ui/react";
import Emoji from "../../components/universal-components/emoji.component";
import { Link } from "react-router-dom";
import LoadingPage from "../../components/universal-functional-components/loading-page.component";

const posOptions = [
  "Adjectives",
  "Adverbs",
  "Verbs",
  "Pronouns",
  "Nouns",
  "Other",
];

const posCodeToPosName = {
  ADJ: "Adjectives",
  ADV: "Adverbs",
  AUX: "Verbs",
  VERB: "Verbs",
  PRON: "Pronouns",
  PROPN: "Nouns",
  NOUN: "Nouns",
  ADP: "Other",
  DET: "Other",
  INTJ: "Other",
  NUM: "Other",
  PART: "Other",
  PUNCT: "Other",
  SYM: "Other",
  X: "Other",
  SPACE: "Other",
  CONJ: "Other",
  CCONJ: "Other",
  SCONJ: "Other",
};

export default function Movie() {
  const router = useRouter();
  const [words, setWords] = useState([]);
  const [movieInfo, setMovieInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/library/${router.match.params.movieTitle}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setWords(data.words.filter((e) => e.word_difficulty > 0));
        setMovieInfo(data.info);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [router]);

  const [isOpen, setIsOpen] = useState(false);
  const [word, setWord] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const [wordDifficultyValue, setWordDifficultyValue] = useState(0);
  const [activePOS, setActivePOS] = useState(posOptions);
  const [textFrequencyValue, setTextFrequencyValue] = useState(0);

  const singleFilter = "space-y-4";

  const filteredWords = useMemo(() => {
    return words.filter(
      (word) =>
        word.word_difficulty > wordDifficultyValue &&
        activePOS.includes(posCodeToPosName[word.pos]) &&
        word.text_frequency > textFrequencyValue
    );
  }, [words, wordDifficultyValue, activePOS, textFrequencyValue]);

  const [isFilterHidden, setIsFilterHidden] = useState(true);

  if (isLoading) {
    return <LoadingPage />;
  } else {
    return (
      <div className="bg-yellow-50 min-h-screen">
        <div className="px-6 sm:px-8 md:p-6 mx-auto max-w-3xl">
          <div className="flex items-center flex-wrap justify-center text-center pt-8">
            <Link
              to="/movies"
              className="sr-only sm:not-sr-only sm:fixed top-4 left-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
            </Link>
            <h1 className="text-3xl font-bold mr-4 mb-2">{movieInfo?.name}</h1>
            <Tooltip label="Total number of words" position="top" withArrow>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-300 text-gray-700 mr-2 mb-2">
                <Emoji symbol="🍿" />
                {words?.length}
              </span>
            </Tooltip>
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-200 text-yellow-800 mb-2">
              <Emoji symbol="📔" />
              {movieInfo?.language_level}
            </span>
          </div>
          <div className="mt-6">
            <h1 className="text-2xl font-bold text-gray-900">
              <Emoji symbol="🏆" />
              Top Obscure Words
            </h1>
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap">
                {["🥇", "🥈", "🥉"].map((e, i) => (
                  <button
                    onClick={() => {
                      setWord(words[i]);
                      setIsOpen(true);
                    }}
                    className="mt-3 mr-1.5 inline-flex items-center px-3 py-2.5 rounded-md text-xl font-medium bg-gray-300 text-gray-900 hover:bg-yellow-100"
                  >
                    <Emoji symbol={e} />
                    {words.length > i && words[i]["word_display"]}
                  </button>
                ))}
                {words?.slice(3, 10).map((w) => (
                  <button
                    onClick={() => {
                      setWord(w);
                      setIsOpen(true);
                    }}
                    className="mt-3 mr-1.5 inline-flex items-center px-3 py-0.5 rounded-md text-lg font-medium bg-gray-200 text-gray-800 hover:bg-yellow-100"
                  >
                    {w.word_display}
                  </button>
                ))}
                {words?.slice(10, 50).map((w) => (
                  <button
                    onClick={() => {
                      setWord(w);
                      setIsOpen(true);
                    }}
                    className="mt-3 mr-1.5 inline-flex items-center px-3 py-0.5 rounded-md text-base font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100"
                  >
                    {w.word_display}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex flex-row space-x-2 items-center justify-between">
              <div className="flex flex-row space-x-2 items-center">
                <h1 className="text-2xl font-bold text-gray-900">
                  <Emoji symbol="🍿" />
                  All Words
                </h1>
                <div className="flex flex-row items-center space-x-2">
                  <Tooltip
                    label={`Filtered words out of ${words.length} total words`}
                    position="right"
                    withArrow
                  >
                    <span
                      className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                        filteredWords.length / words.length < 0.05
                          ? "bg-gray-200 text-gray-900"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {filteredWords.length}{" "}
                      {isFilterHidden && !isMobile && "Words"}
                    </span>
                  </Tooltip>
                </div>
              </div>

              {isFilterHidden && (
                <button
                  className="p-2 m-1 rounded-md hover:bg-gray-100"
                  onClick={() => setIsFilterHidden((o) => !o)}
                >
                  <div className="flex flex-row items-center space-x-2">
                    <Title order={5}>Filter</Title>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                      />
                    </svg>
                  </div>
                </button>
              )}
            </div>

            <div>
              {!isFilterHidden && (
                <div className="-mt-10 max-w-3xl">
                  <div className="flex justify-end mb-4">
                    <button
                      className="p-2 m-1 rounded-md hover:bg-gray-100"
                      onClick={() => setIsFilterHidden((o) => !o)}
                    >
                      <div className="flex flex-row items-center ">
                        <Title order={5}>Hide Filter</Title>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-md shadow-md flex flex-col space-y-8 sm:justify-between sm:flex-row sm:space-y-0 sm:space-x-12">
                    <div className="flex flex-col space-y-8 flex-grow">
                      <div className={singleFilter}>
                        <div className="flex items-center space-x-1">
                          <Title order={4}>Word difficulty score</Title>
                          <MoreInfo label="Filter by estimated difficulty of the word. 0 being easiest, 1 being most difficult." />
                        </div>
                        <Slider
                          color="yellow"
                          min={0}
                          max={1}
                          step={0.01}
                          size="lg"
                          radius="lg"
                          value={wordDifficultyValue}
                          onChange={setWordDifficultyValue}
                        />
                      </div>
                      <div className={singleFilter}>
                        <div className="flex items-center space-x-1">
                          <Title order={4}>Number of occurrences</Title>
                          <MoreInfo label="Filter by how often the word appears in the movie/series." />
                        </div>
                        <Slider
                          color="yellow"
                          min={0}
                          max={Math.max(words.length / 1000, 5)}
                          step={1.0}
                          size="lg"
                          radius="lg"
                          value={textFrequencyValue}
                          onChange={setTextFrequencyValue}
                        />
                      </div>
                    </div>

                    <div className={singleFilter}>
                      <Title order={4}>Part-of-speech</Title>
                      <div className="grid grid-cols-2 gap-2 max-w-lg">
                        {posOptions.map((option, i) => (
                          <Checkbox
                            size="lg"
                            colorScheme="orange"
                            key={i}
                            isChecked={activePOS.includes(option)}
                            onChange={() => {
                              if (!activePOS.includes(option)) {
                                setActivePOS((o) => [...o, option]);
                              } else {
                                setActivePOS((o) =>
                                  o.filter((e) => e !== option)
                                );
                              }
                            }}
                          >
                            <p className="text-lg font-medium">{option}</p>
                          </Checkbox>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={!isFilterHidden && "mt-4"}>
                {filteredWords?.map((w) => (
                  <button
                    onClick={() => {
                      setWord(w);
                      setIsOpen(true);
                    }}
                    className="mt-3 mr-1.5 inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100"
                  >
                    {w.word_display}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Modal
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          size={isMobile ? "full" : "70%"}
          title={
            <h1 className="bg-yellow-100 px-1 text-xl font-medium">
              {word?.word_display}
            </h1>
          }
          overflow="outside"
        >
          <WordInfoContent wordInfo={word} movieTitle={movieInfo?.name} />
        </Modal>
      </div>
    );
  }
}