import { Component } from "react";
import WordInput from "../universal-components/form-inputs/word-input.component";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TextDeleteButton from "../universal-functional-components/text-delete-button.component";
import DeckLanguageInformation from "./language-display-components/deck-language-information.component";
import { setCurrentUploads } from "../../redux/decks/decks.actions";

class TextSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textName: "",
      textDeckId: "",
      cardTypeListParam: "",
      renderRedirectDashboard: false,
      renderRedirectText: false,
      responseMessage: "",
    };
  }

  componentDidMount() {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/text/${this.props.match.params.textId}`,
      { method: "GET", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          textName: data.name,
          textDeckId: data.deck_param,
          cardTypeListParam: data.card_type_param,
        })
      )
      .catch((error) => console.error(error));
  }

  assertSubmit = () => {
    const textName = this.state.textName;
    const textDeckId = this.state.textDeckId;
    if (textName && textDeckId) {
      this.updateText(textName, textDeckId);
    } else {
      window.alert("Missing fields!");
    }
  };

  updateText = (name, deckId) => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/text/${this.props.match.params.textId}?name=${name}&deck_id=${deckId}`,
      { method: "PUT", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => this.refresh(data.message))
      .catch((error) => console.error(error));
  };

  refresh = (message) => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/all-uploads/${this.props.userApiId}`,
      { method: "GET", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentUploads(data.uploads);
      })
      .then(
        this.setState({ renderRedirectText: true, responseMessage: message })
      )
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <div>
        <h1>Text Settings</h1>
        <div className="mt-4">
          <WordInput
            inputValue={this.state.textName}
            onInputChange={(event) =>
              this.setState({ textName: event.target.value })
            }
            textMainHeadline=""
            textSubHeadline=""
            textMain="Text Name"
            textOptional=""
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Deck
          </label>
          <select
            onChange={(event) =>
              this.setState({ textDeckId: event.target.value })
            }
            value={this.state.textDeckId}
            id="deck-name"
            name="deck-name"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
          >
            {this.props.currentDecks.map((deck) => (
              <option value={deck.id}>{deck.name}</option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <DeckLanguageInformation deckId={this.state.textDeckId} />
        </div>
        <div className="flex justify-end mt-4 space-x-3">
          <button
            onClick={this.props.history.goBack}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Cancel
          </button>
          <TextDeleteButton
            textId={this.props.match.params.textId}
            token={this.props.token}
          />
          <button
            onClick={this.assertSubmit}
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
        {this.state.renderRedirectText && (
          <Redirect
            to={`/app/text-view/text/${this.state.textDeckId}/${this.props.match.params.textId}/?message=${this.state.responseMessage}`}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUploads: (uploads) => dispatch(setCurrentUploads(uploads)),
});

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  userApiId: decks.user_api_id,
});

export default connect(mapStateToProps, mapDispatchToProps)(TextSettings);
