import { useToast } from "@chakra-ui/react";
import { getHotkeyHandler, useClickOutside } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TextName({ textId, textName, deckName, deckId }) {
  const [isHover, setIsHover] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(textName);
  }, [textName]);

  const ref = useClickOutside(() => submitName());
  const toast = useToast();

  const token = useSelector((state) => state.user.token);

  const submitName = () => {
    if (name === textName) {
      setIsEdit(false);
      return;
    }
    const headers = new Headers({ Authorization: token });
    fetch(
      `${process.env.REACT_APP_API_URL}/text/${textId}?name=${name}&deck_id=${deckId}`,
      { method: "PUT", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => {
        toast({
          title: "Saved successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="flex flex-row items-center">
      <div
        className="flex flex-col"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className="flex flex-row items-center"
          onClick={() => setIsEdit(true)}
        >
          {isEdit ? (
            <input
              onChange={(event) => setName(event.target.value)}
              value={name}
              className="bg-yellow-50"
              autoFocus
              onSubmit={() => alert("hi")}
              ref={ref}
              onKeyDown={getHotkeyHandler([["Enter", () => submitName()]])}
            ></input>
          ) : (
            <p className="cursor-pointer">{name}</p>
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-4 w-4 ml-1 cursor-pointer ${
              isHover || isEdit ? "text-gray-900" : "text-yellow-50"
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </div>
        <span className="font-normal text-xs text-gray-700 -mt-0.5">
          {deckName}
        </span>
      </div>
      <Link to={`/app/text/settings/${textId}`}>
        <div className="inline-flex items-center text-sm font-medium rounded-md text-gray-700 bg-transparent p-2 hover:bg-yellow-200 ml-1 -mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </Link>
    </div>
  );
}
