import React from "react";

const Imprint = () => (
  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-8">
    <div className="bg-yellow-50 overflow-hidden shadow rounded-lg">
      <div className="p-6 space-y-2">
        <p className="text-3xl font-bold pb-2">Imprint</p>
        <p>
          <b>3z digital GmbH</b> (3z digital Sagl) (3z digital Ltd liab. Co) (3z
          digital Sàrl)
        </p>
        <p>Kirchlistrasse 1, 9010 St. Gallen, Switzerland</p>
        <p>
          <b>UID:</b> CHE-190.464.087
        </p>
        <p>
          <b>Email:</b> contact@thisiszing.com
        </p>
        <p>
          <b>Contact:</b> Julius Janda
        </p>
        <p>
          <b>Email:</b> julius@vocab.so
        </p>
      </div>
    </div>
  </div>
);

export default Imprint;
