import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SectionHeading from "../pages/app/app-page-components/section-heading.component";

export default function WordOverview({ isLoaded }) {
  const [known, setKnown] = useState([]);
  const [added, setAdded] = useState([]);

  const userApiId = useSelector((state) => state.decks.user_api_id);

  useEffect(() => {
    if (isLoaded) {
      fetch(`${process.env.REACT_APP_API_URL}/known-words/${userApiId}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((data) => {
          setKnown(data.map((e) => e.split("-")[0]));
        })
        .catch((error) => console.log(error));

      fetch(`${process.env.REACT_APP_API_URL}/all-words/${userApiId}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((data) => {
          setAdded(data);
        })
        .catch((error) => console.log(error));
    }
  }, [isLoaded]);

  return (
    <div>
      <SectionHeading
        headingText="Word Overview"
        isButton={false}
        buttonText=""
        buttonLink=""
      />
      <div className="space-y-2">
        <h1 className="font-bold">Added Words</h1>
        <p>{added.map((e) => e + ", ")}</p>
      </div>
      <div className="space-y-2 pt-5">
        <h1 className="font-bold">Known Words</h1>
        <p>{known.map((e) => e + ", ")}</p>
      </div>
    </div>
  );
}
