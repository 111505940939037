import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBJFPjClOzfLRHGnpqnclk942_VKZos5h0",
  authDomain: "vocably-66cac.firebaseapp.com",
  projectId: "vocably-66cac",
  storageBucket: "vocably-66cac.appspot.com",
  messagingSenderId: "550955182620",
  appId: "1:550955182620:web:687140af54d744cb984dd9",
  measurementId: "G-CM22V5C6S3",
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);