import { useEffect, useState } from "react";
import { PosLabel } from "../../../universal-components/pos-label.component";
import MultipleChoiceButton from "../session-components/exercise-components/multiple-choice-components/multiple-choice-button.component";
import { analytics } from "../../../../firebase.utils";
import { logEvent } from "firebase/analytics";
import { useSelector } from "react-redux";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default function QuizAssess({
  wordInfo,
  incorrectAnswers,
  correctAnswer,
  onAction,
  userApiId,
  deckId,
  field,
  exercise,
}) {
  const [answers, setAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [response, setResponse] = useState("");

  useEffect(() => {
    incorrectAnswers.push(correctAnswer);
    setAnswers(shuffleArray(incorrectAnswers));
  }, [correctAnswer, incorrectAnswers]);

  console.log(wordInfo, exercise);

  const token = useSelector((state) => state.user.token);

  const onUnknown = () => {
    handleIncorrect("skipped");
  };

  const handleAction = (event) => {
    setResponse(event.target.value);
    if (correctAnswer === event.target.value) {
      const headers = new Headers({ Authorization: token });
      fetch(
        `${process.env.REACT_APP_API_URL}/known-words/${userApiId}?word_root=${
          wordInfo["word_root"]
        }&word_original=${wordInfo["word_root"]}&pos=${
          wordInfo["pos"]
        }&language=${"en"}`,
        { method: "POST", redirect: "follow", headers: headers }
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .then((response) => {
          console.log(response);
          logEvent(analytics, "word_added", {
            language: "en",
            session_type: "quiz",
            exercise_type: "assess",
          });
          const feedbackTexts = [
            "Nicely done!",
            "Well done!",
            "That's it!",
            "Correct",
            "Yes, yes, indeed.",
            "Oh yes, you are quite right!",
          ];
          onAction(
            "correct",
            feedbackTexts[Math.floor(Math.random() * feedbackTexts.length)]
          );
          setIsSubmitted(true);
        })
        .catch((error) => console.error(error));
    } else {
      handleIncorrect("incorrect");
    }
  };

  const handleIncorrect = (feedback) => {
    let examples = [];
    wordInfo["original_words_list"].forEach((word) => {
      examples.push(word["sent"]);
    });
    const headers = new Headers({ Authorization: token });
    fetch(
      `${process.env.REACT_APP_API_URL}/converted-words?word=${wordInfo["word_pos_key"]}&text_id=${wordInfo["text_id"]}`,
      { method: "POST", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
    fetch(
      `${process.env.REACT_APP_API_URL}/word/1?word_root=${
        wordInfo["word_root"]
      }&translation=${""}&definition=${""}&examples=${JSON.stringify(
        examples.slice(0, 15)
      )}&pos=${wordInfo["pos"]}&deck_id=${parseInt(
        deckId
      )}&user_id=${userApiId}&tags=${""}&synonyms=${""}`,
      { method: "POST", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        onAction(feedback, correctAnswer);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const promptFields = [
    "definition",
    "synonym_reversed",
    "antonym_reversed",
    "related_reversed",
    "translation_reversed",
  ];

  return (
    <div>
      <div className="font-medium flex flex-row items-center">
        {field === "synonym" && (
          <p className="inline">
            What word is most{" "}
            <span className="bg-yellow-100 py-0.5 px-0.5">similar</span> to{" "}
          </p>
        )}
        {field === "synonym_reversed" && (
          <p className="inline">
            What word is most{" "}
            <span className="bg-yellow-100 py-0.5 px-0.5">similar</span> to{" "}
            {exercise["prompt_info"]} -- rev
          </p>
        )}
        {field === "related" && (
          <p className="inline">
            What word do you most{" "}
            <span className="bg-green-100 py-0.5 px-0.5">associate</span> with
            the word{" "}
          </p>
        )}
        {field === "related_reversed" && (
          <p className="inline">
            What word do you most{" "}
            <span className="bg-green-100 py-0.5 px-0.5">associate</span> with
            the word {exercise["prompt_info"]} -- rev
          </p>
        )}
        {field === "antonym" && (
          <p className="inline">
            What word describes the{" "}
            <span className="bg-red-100 py-0.5 px-0.5">opposite</span> of{" "}
          </p>
        )}
        {field === "antonym_reversed" && (
          <p className="inline">
            What word describes the{" "}
            <span className="bg-red-100 py-0.5 px-0.5">opposite</span> of{" "}
            {exercise["prompt_info"]} -- rev
          </p>
        )}

        {field === "translation" && (
          <p className="inline">What is the meaning of </p>
        )}
        {field === "translation_reversed" && (
          <p className="inline">
            What is the meaning of {exercise["prompt_info"]} -- rev
          </p>
        )}
        {field === "definition" && (
          <p className="inline">{exercise["prompt_info"]}</p>
        )}
        {!promptFields.includes(field) && (
          <>
            <p className="ml-0.5 py-0.5 px-0.5 inline underline">
              {wordInfo["word_display"]}
            </p>
            <p className="inline">?</p>
          </>
        )}
        {field !== "definition" && (
          <div className="ml-3">
            <PosLabel pos={wordInfo["pos"]} isGray />
          </div>
        )}
      </div>

      <p className="font-normal italic text-sm mb-4">
        {wordInfo["word_count"]
          ? wordInfo["word_count"]
          : wordInfo["original_words_list"].length}{" "}
        occurrences.
      </p>

      <div
        className={
          answers.length >= 4
            ? "grid grid-cols-2 gap-4"
            : "flex flex-col space-y-1"
        }
      >
        {answers &&
          answers.map((e) => (
            <MultipleChoiceButton
              value={e}
              isVerb={wordInfo["pos"] === "VERB" && field !== "translation"}
              onSelect={handleAction}
              isDisabled={isSubmitted}
              isCorrect={isSubmitted && correctAnswer === e}
              isFalse={isSubmitted && correctAnswer !== e && response === e}
            />
          ))}
      </div>
      <div className="flex justify-center mt-4">
        <button className="text-sm font-medium" onClick={() => onUnknown()}>
          I'm not sure
        </button>
      </div>
    </div>
  );
}
