import { useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import SwitchMode from "./switch-mode.component";
import TextName from "./text-name.component";

export default function LearnNavigation({ location, onBack = null }) {
  const router = useRouter();
  const deckId = router.match.params.deckId;
  const textId = router.match.params.textId;
  const itemType = router.match.params.itemType;

  const deck = useSelector((state) =>
    state.decks.currentDecks.filter((deck) => deck.id === parseInt(deckId))
  )[0];

  const text = useSelector((state) =>
    state.decks.currentUploads.filter((text) => text.id === parseInt(textId))
  )[0];

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      router.push(`/app/learn/${deckId}`);
    }
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <button
        onClick={handleBack}
        className="rounded-md text-sm font-medium text-gray-600 p-1 hover:bg-yellow-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <div className="font-bold">
        {itemType === "all" && "All Decks"}
        {itemType === "deck" && deck?.name}
        {itemType === "text" && (
          <TextName
            textId={textId}
            textName={text?.name}
            deckName={deck?.name}
            deckId={deckId}
          />
        )}
      </div>
      <SwitchMode
        selected={location}
        deckId={deckId}
        textId={textId}
        itemType={itemType}
      />
    </div>
  );
}
