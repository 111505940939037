import React from "react";
import { Input } from "@chakra-ui/react";
import { connect } from "react-redux";
import { Spinner, Button } from "@chakra-ui/react";
import AddWord from "./add-word.component";

const colors = [
  "yellow",
  "green",
  "blue",
  "red",
  "indigo",
  "purple",
  "pink",
  "yellow",
  "green",
  "blue",
  "red",
  "indigo",
  "purple",
  "pink",
];

class SentenceAdder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentenceInput: "",

      isConverted: false,
      activeWord: null,

      words: [],
      frequency: [],

      frequencyThreshold: 5,

      isButton: true,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress);
  }

  onKeyPress = (event) => {
    if (event.key === "Enter" && this.state.sentenceInput) {
      this.assertSubmitSentence();
    }
  };

  assertSubmitSentence = () => {
    if (this.state.sentenceInput.length > 1000) {
      window.alert("Sentence too long.");
    } else if (this.state.sentenceInput.split(" ").length < 3) {
      window.alert("Sentence too short.");
    } else {
      this.setState({ isLoading: true });
      this.submitSentence();
    }
  };

  submitSentence = () => {
    const sentenceInput = this.state.sentenceInput;
    fetch(
      `${process.env.REACT_APP_API_URL}/sentence?text=${sentenceInput}&user_id=${this.props.userApiId}&text_language=en`,
      { method: "GET", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        const words = data.words;
        const frequency = data.frequencies.sort(function (a, b) {
          return a - b;
        });
        let highlightColors = [];
        let count = 0;
        for (let i = 0; i < words.length; i++) {
          const word_info = words[i];
          if (
            word_info.is_included &&
            !word_info.existing &&
            word_info.frequency < frequency[this.state.frequencyThreshold]
          ) {
            const word_original = word_info.word_original;
            highlightColors[word_original] = colors[count];
            count += 1;
          }
        }
        this.setState({
          isConverted: true,
          words: words,
          frequency: frequency,
          highlightColors: highlightColors,
        });
      })
      .catch((error) => window.alert(error));
  };

  render() {
    return (
      <div className="max-w-2xl">
        {this.state.addWord && (
          <AddWord
            wordInfo={this.state.words[this.state.activeWord]}
            onClose={() => this.setState({ addWord: false })}
          />
        )}

        {this.state.isConverted ? (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row flex-wrap">
              {this.state.words.map((word_info, i) => (
                <div>
                  {word_info.is_included &&
                  !word_info.existing &&
                  word_info.frequency <
                    this.state.frequency[this.state.frequencyThreshold] ? (
                    <button
                      onMouseEnter={() => this.setState({ activeWord: i })}
                      onMouseLeave={() => this.setState({ activeWord: null })}
                      onClick={() => this.setState({ addWord: true })}
                      className={`inline bg-${
                        this.state.highlightColors[word_info.word_original]
                      }-${
                        this.state.activeWord === i ? "200" : "100"
                      } mr-1 font-medium`}
                    >
                      {word_info.word_original}
                    </button>
                  ) : (
                    <p
                      className={`inline font-medium ${
                        word_info.pos === "PUNCT" && "-ml-1"
                      } ${word_info.word_original !== "-" && "mr-1"}`}
                    >
                      {word_info.word_original}
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div className="flex flex-row">
              {this.state.words.map(
                (word_info, i) =>
                  word_info.is_included &&
                  !word_info.existing &&
                  word_info.frequency <
                    this.state.frequency[this.state.frequencyThreshold] && (
                    <div
                      className={`${
                        this.state.activeWord === i &&
                        `bg-${
                          this.state.highlightColors[word_info.word_original]
                        }-50`
                      } p-4 rounded-md  cursor-pointer`}
                      onClick={() => this.setState({ addWord: true })}
                      onMouseEnter={() => this.setState({ activeWord: i })}
                      onMouseLeave={() => this.setState({ activeWord: null })}
                    >
                      <label
                        className={`bg-${
                          this.state.highlightColors[word_info.word_original]
                        }-100 px-1 text-xs font-medium text-gray-700 cursor-pointer`}
                      >
                        {word_info.word_display}
                      </label>
                      <div className="flex flex-wrap ml-1">
                        {word_info.translations.map((translation) => (
                          <p className="inline text-sm mr-2">{translation}</p>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
            {this.state.isButton && (
              <div className="pt-6">
                <Button
                  colorScheme="gray"
                  onClick={() => {
                    this.props.onAddAnother();
                    this.setState({ isButton: false });
                  }}
                  variant="outline"
                >
                  <p className="text-xs">Add more</p>
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            {this.state.isLoading ? (
              <div className="flex flex-col items-center justify-center h-full">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="white"
                  color="gray.500"
                  size="md"
                />
              </div>
            ) : (
              <Input
                placeholder="Paste Sentence"
                variant="flushed"
                onChange={(event) =>
                  this.setState({ sentenceInput: event.target.value })
                }
                focusBorderColor="gray.500"
                className="mt-2"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
});

export default connect(mapStateToProps)(SentenceAdder);
