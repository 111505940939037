import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./pages/landing/landing.page.component";
import AppPage from "./pages/app/app.page.component";
import { setToken } from "./redux/user/user.actions";
import PageNotFound from "./pages/no-match/page-not-found.page.component";
import WaitlistRegistration from "./pages/waitlist/waitlist-registration.component";
import DemoRegistration from "./pages/waitlist/demo-registration.component";
import Imprint from "./pages/service-pages/imprint.page";
import Privacy from "./pages/service-pages/privacy.page";
import PublicConverter from "./pages/public-converter/public-converter.page.component";
import Konvey from "./pages/vocab-professional/vocab-professional.page";
import WhyPage from "./pages/landing/why.page.component";
import { auth } from "./firebase.utils";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import SignIn from "./firebase-auth/sign-in.component";
import SignUp from "./firebase-auth/sign-up.component";
import LoadingPage from "./components/universal-functional-components/loading-page.component";
import { useDispatch } from "react-redux";
import MovieLibrary from "./pages/movie/movie-library.page";
import Movie from "./pages/movie/movie.page";
import MovieAdmin from "./pages/movie/movie-admin/movie-admin.page";
import Onboarding from "./pages/onboarding/onboarding.page";
import ProgrammaticQuizzes from "./pages/programmatic-quiz/programmatic-quizzes.page";
import ProgrammaticQuiz from "./pages/programmatic-quiz/programmatic-quiz.page";

export default function App() {
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  onAuthStateChanged(auth, (currentUser) => {
    setIsLoaded(true);
    setUser(currentUser);
    if (currentUser) {
      currentUser
        .getIdToken()
        .then(function (idToken) {
          dispatch(setToken(idToken));
        })
        .catch(function (error) {
          console.log("Error retrieving token", error);
        });
    }
  });

  return (
    <Switch>
      <Route
        path="/app"
        render={() =>
          isLoaded ? (
            user?.uid ? (
              <AppPage user={user} />
            ) : (
              <SignIn />
            )
          ) : (
            <LoadingPage />
          )
        }
      />
      <Route path="/onboarding">
        <Onboarding user={user} />
      </Route>
      <Route path="/movies">
        <MovieLibrary />
      </Route>
      <Route path="/quizzes">
        <ProgrammaticQuizzes />
      </Route>
      <Route path="/quiz/:quizId/:quizName">
        <ProgrammaticQuiz />
      </Route>
      <Route path="/movie/:movieTitle">
        <Movie />
      </Route>
      <Route exact path="/beta-user-sign-up" component={SignUp} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/why" component={WhyPage} />
      <Route
        exact
        path="/waitlist-registration"
        component={WaitlistRegistration}
      />
      <Route exact path="/demo-registration" component={DemoRegistration} />
      <Route exact path="/imprint" component={Imprint} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/text-to-vocab" component={PublicConverter} />
      <Route exact path="/professional" component={Konvey} />
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
