import React from "react";
import Calendly from "./calendly-form.component"

class WaitlistRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formReady: false,
            formSubmit: false,
        }
    }

    render() {
        return(
            <div className="pt-8 pb-4 bg-yellow-50 overflow-hidden min-h-screen">

                <div className="max-w-7xl mx-auto py-4 sm:py-8 px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                    <h2 className="text-lg font-semibold text-red-400 tracking-wide uppercase">Schedule A Product Demo</h2>
                    <p className="max-w-xl mt-5 mx-auto text-md text-gray-500">By scheduling a demo, you'll get a tour of the app and an early invite afterwards. If you agree, we want to take 2-3 minutes to learn what problems we can help you solve with vo·​cab and hear what you think.</p>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-center items-center sm:space-x-12">
                    <Calendly url="https://calendly.com/juliusjanda/vo-cab-demo-conversation?hide_event_type_details=1&background_color=fffbeb&primary_color=fca5a5"/>
                </div>
            </div>
            
        )
    }
}

export default WaitlistRegistration;