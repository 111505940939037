//  <ButtonWithShortcut textMain="Continue" textShortcut="press Enter ↵" onButtonClick={this.assertSubmit}/>

export default function ButtonWithShortcut({
  onButtonClick,
  textMain,
  textShortcut,
  color = "yellow",
}) {
  let background,
    text = "";
  if (color === "black") {
    background = "text-white bg-gray-600 hover:bg-gray-700 focus:ring-gray-900";
    text = "text-gray-900";
  } else if (color === "gray") {
    background =
      "text-gray-800 bg-gray-200 hover:bg-gray-300 focus:ring-gray-600";
    text = "text-gray-500";
  } else {
    background =
      "text-gray-800 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500";
    text = "text-gray-500";
  }

  return (
    <div className="flex flex-row">
      <button
        type="button"
        onClick={onButtonClick}
        className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 ${background} font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
      >
        {textMain}
      </button>
      <p className={`ml-3 mt-2 text-sm ${text}`}>{textShortcut}</p>
    </div>
  );
}
