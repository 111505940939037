import { useState } from "react";

const options = [
  {
    value: 0,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-red-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    color: "bg-red-100",
  },
  {
    value: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-yellow-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    color: "bg-yellow-100",
  },
  {
    value: 2,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-green-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    color: "bg-green-100",
  },
];

export default function RateExercise({ wordId, language, type, exerciseId }) {
  const [rating, setRating] = useState(null);

  const submitRating = (rating) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/rate?word_id=${wordId}&language=${language}&type=${type}&exercise_id=${exerciseId}&rating=${rating}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {rating || rating === 0 ? (
        /* TODO: add question mark icon with explanation of how the ratings are used on hover */
        <div className="flex justify-center font-bold">
          Thank you for your help!
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-2">
          <p className="font-medium text-sm">Rate exercise quality</p>
          <div className="space-x-2 flex flex-row justify-center items-center">
            {options.map((e) => (
              <button
                onClick={() => {
                  setRating(e.value);
                  submitRating(e.value);
                }}
                className={
                  "transition duration-300 ease-in-out transform hover:scale-110 rounded-md shadow-sm p-2.5 " +
                  e.color
                }
              >
                {e.icon}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
