import { useState, useEffect } from "react";
import SingleDefinition from "./single-definition.component";
import { Spinner } from "@chakra-ui/react";
import { getHotkeyHandler } from "@mantine/hooks";

export default function Definitions({
  onDefinitionChange,
  word,
  pos,
  existing = [],
}) {
  const [suggestionCount, setSuggestionCount] = useState(3);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [edit, setEdit] = useState("");

  const [selected, setSelected] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    if (input) {
      onDefinitionChange([...selected, [input, "user"]]);
    } else {
      onDefinitionChange(selected);
    }
  }, [selected, input]);

  useEffect(() => {
    if (existing.length === 0) {
      fetch(
        `${process.env.REACT_APP_API_URL}/definition?word_original=${word}&word_root=${word}&pos=${pos}`,
        { method: "GET", redirect: "follow" }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setSuggestions(
              result.map((e) => {
                return [e.text, e.source];
              })
            );
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    } else {
      setIsLoaded(true);
      setSuggestions(existing);
      setSelected(existing);
    }
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div className="pb-2 mr-2">
        <Spinner size="sm" />
      </div>
    );
  } else {
    return (
      <div className="space-y-2">
        <div className="flex flex-col items-stretch space-y-2">
          {suggestions.slice(0, suggestionCount).map((entry, i) => (
            <SingleDefinition
              key={i}
              text={entry[0]}
              source={entry[1]}
              isEdit={edit === entry[0]}
              isSelected={selected.map((e) => e[0]).includes(entry[0])}
              onEditClick={() => {
                if (edit === entry[0]) {
                  setEdit(null);
                } else {
                  setEdit(entry[0]);
                }
              }}
              onSuggestionClick={() => {
                if (
                  selected.includes(entry) ||
                  selected.map((e) => e[0]).includes(entry[0])
                ) {
                  setSelected((prev) => prev.filter((e) => e[0] !== entry[0]));
                } else {
                  setSelected((prev) => [...prev, entry]);
                }
              }}
            />
          ))}
        </div>
        <div className="flex justify-center">
          {suggestions.length > suggestionCount && (
            <button
              className="text-sm font-bold text-gray-500 p-1 rounded-md hover:bg-gray-50"
              onClick={() => setSuggestionCount(suggestionCount + 3)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
              </svg>
            </button>
          )}
        </div>
        <div className="flex flex-col items-stretch space-y-2">
          <textarea
            placeholder="Add your own..."
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
            className="text-sm font-medium border-2 text-gray-700 bg-gray-50 p-2 rounded-md hover:bg-gray-100 flex flex-row justify-start items-end focus:border-transparent shadow-sm focus:ring-gray-400 focus:border-gray-400 w-full sm:text-sm border-gray-300"
            onKeyDown={getHotkeyHandler([
              [
                "Enter",
                () => {
                  if (
                    input.trim() &&
                    !suggestions.map((e) => e[0]).includes(input.trim())
                  ) {
                    setSuggestionCount(suggestions.length + 1);
                    setSuggestions((prev) => [...prev, [input.trim(), "user"]]);
                  }
                  if (
                    input.trim() &&
                    !selected.map((e) => e[0]).includes(input.trim())
                  ) {
                    setSelected((prev) => [...prev, [input.trim(), "user"]]);
                  }
                  setInput("");
                },
              ],
            ])}
          ></textarea>
          {input && (
            <p className="text-right text-sm text-gray-500 font-medium">
              Press Enter ↵ to add
            </p>
          )}
        </div>
      </div>
    );
  }
}
