import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRouter } from "../../../hooks/useRouter";

export default function DeckTextList({ deckId, isLink = true }) {
  const texts = useSelector((state) =>
    state.decks.currentUploads.filter(
      (text) => text.deck_param === parseInt(deckId)
    )
  );

  const router = useRouter();

  return (
    <div className="flex flex-row justify-start flex-wrap">
      {texts?.length === 0 && (
        <Link to="/app/upload/settings">
          <button
            type="button"
            class="inline-flex items-center px-2 py-1 mt-2 text-sm leading-4 font-medium rounded-md text-gray-700 bg-yellow-100 hover:bg-yellow-200"
          >
            Add Text
          </button>
        </Link>
      )}
      {/* TODO: Add ... to show more texts */}
      {isLink &&
        texts?.map((t) => (
          <Link to={`/app/session/text/${t.deck_param}/${t.id}/quiz/5`}>
            <button className="mt-3 mr-2 inline-flex items-center justify-center px-4 py-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-600 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                  clipRule="evenodd"
                />
              </svg>
              {t.name}
            </button>
          </Link>
        ))}
      {/* TODO: below is just a interim fix, it shouldn't need to reload the page */}
      {!isLink &&
        texts?.map((t) => (
          <button
            onClick={() => {
              router.push(`/app/session/text/${t.deck_param}/${t.id}/quiz/5`);
              window.location.reload();
            }}
            className="mt-3 mr-2 inline-flex items-center justify-center px-4 py-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-600 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
              />
            </svg>
            {t.name}
          </button>
        ))}
      {/*       {texts.length > 1 && (
        <Link to={`/app/upload/all/${deckId}`}>
          <button className="mt-3 mr-2 inline-flex items-center justify-center px-4 py-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-600 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
            All
          </button>
        </Link>
      )} */}
    </div>
  );
}
