import React from "react";
import Footer from "./footer.component";
import heroImage from "../../assets/hero_new.png";
import LandingHeader from "./landing-header.component";
import AdditionalInfo from "./email-form-components/additional-info-form.component";
import { Link } from "react-router-dom";
import feature1 from "../../assets/feature1_new.png";
import feature2 from "../../assets/feature2_new.png";
import feature3 from "../../assets/feature3_new.png";
import feature4 from "../../assets/feature4_new.png";
import Emoji from "../../components/universal-components/emoji.component";
import EmailSignUp from "./email-form-components/email-sign-up.component";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      emailActive: true,
      additionalInfoActive: false,
    };
  }

  executeScroll = () => this.myRef.current.scrollIntoView();

  render() {
    const headlineStyle =
      "text-3xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 px-4";
    const subHeadlineStyle =
      "font-bold text-lg sm:text-xl md:text-2xl mt-3 mx-auto text-gray-900 md:mt-5 md:max-w-3xl lg:max-w-4xl md:px-4";

    const featureHeadingStyle =
      "text-2xl sm:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900";
    const featureSubTitleStyle =
      "mt-4 font-bold text-xl sm:text-2xl text-gray-700";

    const featureSection =
      "px-4 pb-24 lg:pt-12 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-16 xl:gap-24 lg:px-20 lg:place-content-center";
    const featureSectionText =
      "px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 place-self-center";
    const featureSectionImage = "px-8 place-self-center pt-10 lg:px-0 lg:pt-0";

    return (
      <div className="relative bg-yellow-50 overflow-x-hidden">
        <LandingHeader />
        <div className="relative pt-16">
          <main className="mt-12 mx-auto max-w-7xl px-4 sm:mt-16 z-40">
            <div className="text-center flex flex-col justify-center items-center">
              <h1 className={headlineStyle + " max-w-4xl"}>
                Learn the words you need from the content you want
                {/* Learn new words faster than living abroad */}
              </h1>
              <p className={subHeadlineStyle}>
                <span className="sm:sr-only">
                  Vocab helps you absorb relevant words from English books,
                  movies, and more via personalized quizzes tuned to your
                  language level.
                </span>
                <span className="sr-only sm:not-sr-only">
                  Vocab helps you absorb relevant words from English books,
                  movies, and more via personalized quizzes tuned to your
                  language level.
                </span>
              </p>
              <div
                className="relative flex justify-center sm:sr-only"
                aria-hidden="true"
              >
                <div className="mx-auto pt-8 pb-4 px-4">
                  <img src={heroImage} alt="Hero" />
                </div>
              </div>
            </div>

            {this.state.emailActive ? (
              <div className="max-w-md mx-auto sm:flex sm:justify-center sm:flex-col">
                <div className="z-40 mt-8">
                  <EmailSignUp
                    onWaitlist={() =>
                      this.setState({
                        emailActive: false,
                        additionalInfoActive: true,
                      })
                    }
                  />
                </div>
                {/* <div className="flex justify-center mt-4">
                                    <p className="text-sm font-medium text-gray-900 ">Beta users learn free.</p>
                                </div> */}
              </div>
            ) : (
              <div className="mt-8 sm:max-w-lg sm:w-full sm:flex"></div>
            )}

            <div
              className="relative flex justify-center sr-only sm:not-sr-only sm:mt-8"
              aria-hidden="true"
            >
              <div className="max-w-5xl mx-auto px-4 sm:px-8 lg:px-12">
                <img src={heroImage} alt="Hero" />
              </div>
            </div>
          </main>
        </div>

        <div className="max-w-3xl mx-auto pb-16 px-4 pt-32 sm:px-6 lg:px-8 lg:pt-48 text-center">
          <h2 className="text-base text-yellow-600 font-semibold tracking-wide uppercase">
            Features
          </h2>
          <h1
            className={
              "mt-4 text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl"
            }
          >
            Customized learning instead of forgettable lessons
          </h1>
        </div>

        {/* SECTION ONE */}
        <div className={featureSection}>
          <div className={`${featureSectionText}`}>
            <h2 className={featureHeadingStyle}>
              Tailored to your life's context
            </h2>
            <p className={featureSubTitleStyle}>
              Vocab focuses on your language level and the context of your
              hobbies or professions. By importing custom content or choosing
              from the library, you learn only what is relevant and interesting
              to you.
            </p>
          </div>
          <div className={featureSectionImage}>
            <img
              className="relative mx-auto"
              width={490}
              src={feature1}
              alt=""
            />
          </div>
        </div>

        {/* SECTION TWO */}
        <div className={featureSection}>
          <div className={`${featureSectionText} lg:col-start-2`}>
            <h2 className={featureHeadingStyle}>Personalized quizzes</h2>
            <p className={featureSubTitleStyle}>
              Vocab turns imported content into personalized quizzes tuned to
              your language level. While you learn, Vocab keeps track of your
              active vocabulary and constantly improves your lessons.
            </p>
          </div>
          <div className={featureSectionImage}>
            <img
              className="relative mx-auto"
              width={320}
              src={feature2}
              alt=""
            />
          </div>
        </div>

        {/* SECTION THREE */}
        <div className={featureSection}>
          <div className={`${featureSectionText}`}>
            <h2 className={featureHeadingStyle}>Manage your vocabulary</h2>
            <p className={featureSubTitleStyle}>
              After uploading content, you can manually filter out the words
              that are most important to you. Tag important terms, edit word
              information, and select words you want to learn. Once added, your
              words appear in your lessons and remain editable.
            </p>
          </div>
          <div className={featureSectionImage}>
            <img
              className="relative mx-auto"
              width={490}
              src={feature3}
              alt=""
            />
          </div>
        </div>
        {/* SECTION FOUR */}
        <div className={featureSection}>
          <div className={`${featureSectionText} lg:col-start-2`}>
            <h2 className={featureHeadingStyle}>Magic flashcards</h2>
            <p className={featureSubTitleStyle}>
              Whenever you want to take charge of your lessons yourself, easily
              switch to learning with flashcards. Not any flashcards though.
              Instead of tediously creating one-off flashcards, Vocab lets you
              change flashcard fields every lesson.
            </p>
          </div>
          <div className={featureSectionImage}>
            <img
              className="relative mx-auto"
              width={490}
              src={feature4}
              alt=""
            />
          </div>
        </div>

        {/*         <div className="max-w-3xl mx-auto text-center px-6">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mine.vocab.so/"
          >
            <button className="transition duration-500 ease-in-out p-12 bg-gradient-to-tl from-pink-200 to-purple-200 rounded-xl shadow-md transform hover:scale-105">
              <h1
                className={
                  "mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl"
                }
              >
                BTW. You can now test out vocab's text processing functionality...
                <Emoji label="orb" symbol=" 🔮" />
              </h1>
            </button>
          </a>
        </div> */}
        <div ref={this.myRef} className="pt-12 pb-48 max-w-md mx-auto md:mt-8">
          <div>
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl px-4 sm:px-0">
              Ready?
            </h2>
            <p className="mt-2 text-xl font-medium text-gray-900 px-4 sm:px-0">
              Start learning English for free
            </p>
            <div className="mt-4">
              {this.state.emailActive ? (
                <EmailSignUp
                  onWaitlist={() =>
                    this.setState({
                      emailActive: false,
                      additionalInfoActive: true,
                    })
                  }
                />
              ) : (
                <p className="mt-6 max-w-3xl text-lg leading-6 text-gray-900 font-bold px-4 sm:px-0">
                  Submitted successfully <Emoji symbol="😊" label="cap" />
                </p>
              )}

              <p className="mt-3 text-sm text-gray-700 px-4 sm:px-0">
                Your data is protected. Read our{" "}
                <Link
                  to="/privacy"
                  className="text-gray-600 font-medium underline inline"
                >
                  Privacy Policy.
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.additionalInfoActive && <AdditionalInfo />}
      </div>
    );
  }
}

export default LandingPage;
