import React from "react"
import LanguageButton from "./public-converter-components/language-button.component";
import LevelButton from "./public-converter-components/level-button.component";
import VocabularyList from "./public-converter-components/vocabulary-list.component";

class PublicConverter extends React.Component {
    constructor() {
        super();
        this.state = {
            // conditional rendering
            inputPage: true,
            isLoading: false,
            isLoadingError: false, 
            fieldAlert: false,
            fieldAlertText: "",
            vocabPage: false,

            // inputs
            textInput: "",
            languageInput: "",
            levelInput: "",

            // output
            vocabList: [],
        }
    }

    assertSubmit = () => {
        if (this.state.textInput === "") {
            this.setState({ fieldAlert: true, fieldAlertText: "Enter some text first." })
        } else if (this.state.languageInput === "") {
            this.setState({ fieldAlert: true, fieldAlertText: "Select a language"  })
        } else {
            this.setState({ fieldAlert: false, isLoadingError: false })
            this.handleSubmit()
        }
    }

    handleSubmit = () => {
        const textParam = this.state.textInput
        const advancedParam = this.state.levelInput
        const languageParam = this.state.languageInput

        console.log(textParam, advancedParam, languageParam)

        this.setState({ isLoading: true })

        fetch(`${process.env.REACT_APP_API_URL}/text-to-vocab?text=${textParam}&advanced=${advancedParam}&language=${languageParam}`, {method: "GET", redirect: "follow"})
            .then(response => response.json())
            .then(data => {
                console.log(data)
                let vocabArray = []
                for (const [key, value] of Object.entries(data)) {
                    console.log(key)
                    vocabArray.push(value)
                  }
                this.setState({ vocabList: vocabArray, isLoading: false, inputPage: false, vocabPage: true })
                console.log(vocabArray)
                })
            .catch(error => this.setState({ isLoadingError: true, isLoading: false }))
    }

    render() {
        return(
            <div className="bg-yellow-50">
            <div className=" mx-auto px-4 sm:px-6 lg:px-8 py-16 min-h-screen">
                <div className="flex flex-col items-center">

                    <div className="max-w-4xl mx-auto px-4 sm:pt-12 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-base font-semibold text-red-400 tracking-wide uppercase">Free Text To Vocab Converter</h2>
                            <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl capitalize">
                                {this.state.inputPage ? "Create Your Vocab Lists From Context" : "View and export your vocab list"}
                            </p>
                            { this.state.inputPage ? 
                                (<p className="mt-5 mx-auto text-md sm:text-lg text-gray-500">
                                    Add a text of words that you would like to learn. We'll convert it to a vocab list including words, sentences and part of speech tags for you. Select your language level to exclude words that you are already likely to know.
                                </p>) : (
                                    <button type="button" className="mt-5 -mb-5 inline-flex items-center px-3 py-2 border-2 border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-yellow-50 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        Download CSV
                                    </button>
                                )
                                }
                        </div>
                    </div>

                    { this.state.inputPage && 
                    <div className="w-full">
                        { this.state.isLoading ? (
                            <p className="mt-4 md:mt-8 text-2xl font-bold text-gray-700 text-center">
                                Loading...
                            </p>
                        ) : (
                            <div className="max-w-4xl mx-auto px-4 pt-6 w-full flex flex-col items-center">
                                <textarea onChange={(event) => this.setState({ textInput: event.target.value })} id="text" name="text" rows="10" placeholder="Type or copy and paste your text here..." className="max-w-lg shadow-sm block w-full focus:ring-red-300 focus:border-red-300 sm:text-sm border-2 border-gray-500 rounded-md"></textarea>
                                <div className="mt-4 grid grid-cols-2 gap-2 sm:gap-0 sm:flex sm:flex-row sm:flex-wrap sm:space-x-2">
                                    <LanguageButton     name="English"
                                                        active={(this.state.languageInput === "en")}
                                                        emojiSymbol="🇬🇧"
                                                        onButtonClick={() => this.setState({ languageInput: "en" })} />
                                    <LanguageButton     name="German"
                                                        active={(this.state.languageInput === "de")}
                                                        emojiSymbol="🇩🇪"
                                                        onButtonClick={() => this.setState({ languageInput: "de" })} />
                                    <LanguageButton     name="Spanish"
                                                        active={(this.state.languageInput === "es")}
                                                        emojiSymbol="🇪🇸"
                                                        onButtonClick={() => this.setState({ languageInput: "es" })} />
                                    <LanguageButton     name="French"
                                                        active={(this.state.languageInput === "fr")}
                                                        emojiSymbol="🇫🇷"
                                                        onButtonClick={() => this.setState({ languageInput: "fr" })} />    
                                </div>
                                { (this.state.languageInput === "en") &&
                                <div className="mt-3 space-x-2">
                                    <LevelButton    name="B2"
                                                    active={(this.state.levelInput === "top_1000")}
                                                    emojiSymbol="📗"
                                                    onButtonClick={() => this.setState({ levelInput: "top_1000" })} />
                                    <LevelButton    name="C1"
                                                    active={(this.state.levelInput === "top_3000")}
                                                    emojiSymbol="📘"
                                                    onButtonClick={() => this.setState({ levelInput: "top_3000" })} />
                                </div>
                                }
                                
                                <button onClick={this.assertSubmit} type="button" className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                    Get that vocab
                                </button>
                            </div>
                        )}

                        {/* Removed MissingFieldsAlert -- use new notification component */}
                        {/* <div className="flex flex-col items-center">
                        { (this.state.fieldAlert && 
                            <div className="mt-6">
                                <MissingFieldsAlert mainAlert={this.state.fieldAlertText} secondaryAlerts={false}/>
                            </div>
                        )}
                        { (this.state.isLoadingError && 
                            <div className="mt-6">
                                <MissingFieldsAlert mainAlert="Server Error" secondaryAlerts={false}/>
                            </div>
                        )}
                        </div> */}

                    </div>
                    }

                    { this.state.vocabPage && <VocabularyList vocab={this.state.vocabList} language={this.state.languageInput}/>}

                </div>
            </div>
            </div>
        )
    }
}

export default PublicConverter