import React from "react";
import FileUploadInput from "../universal-components/uploads/file-upload-input.component";
import DeckDropdown from "../universal-components/dropdowns/deck-dropdown.component";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import WordInput from "../universal-components/form-inputs/word-input.component";
import { setCurrentUploads } from "../../redux/decks/decks.actions";
import SectionHeading from "../../pages/app/app-page-components/section-heading.component";
import { analytics } from "../../firebase.utils";
import { logEvent } from "firebase/analytics";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { Box, Overlay } from "@mantine/core";

const axios = require("axios").default;

const resetState = {
  fileUrl: "",
  fileName: "",
  isSubmissionFile: null,
  textInput: "",
  uploadName: "",
  deckId: null,
  uploadId: "",
  redirectStarted: false,
  renderRedirect: false,
  isText: false,
};

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // upload
      fileUrl: "",
      fileName: "",
      isSubmissionFile: null,

      // text input
      textInput: "",

      // settings
      uploadName: "",
      deckId: null,

      uploadId: "",

      // conditional rendering
      redirectStarted: false,
      renderRedirect: false,
      isText: false,
    };
  }

  componentDidMount() {
    if (this.props.currentDecks.length > 0) {
      this.setState({ deckId: this.props.currentDecks[0].id });
    }
  }

  assertHandleProcessText = () => {
    if (!this.state.deckId) {
      window.alert("Please select a deck first.");
    } else if (!this.state.fileUrl && this.state.isSubmissionFile) {
      window.alert("You need to upload a text first.");
    } else if (!this.state.textInput && !this.state.isSubmissionFile) {
      window.alert("You need to enter a text first.");
    } else if (!this.state.uploadName) {
      window.alert("Please add a name for your text first.");
    } else if (
      this.props.currentUploads
        .map((t) => t.name)
        .includes(this.state.uploadName)
    ) {
      window.alert("A text with this name exists already.");
    } else {
      this.handleProcessText();
    }
  };

  handleProcessText = () => {
    this.setState({ redirectStarted: true });

    const text = this.state.textInput;
    const url = this.state.fileUrl;
    const encodedUrl = encodeURIComponent(url);
    const fileType = this.state.fileType;

    const deckParam = this.state.deckId;
    const userId = this.props.userApiId;

    let textLanguage;
    const deckArray = this.props.currentDecks;
    for (let i = 0; i < deckArray.length; i++) {
      if (parseInt(deckArray[i].id) === parseInt(this.state.deckId)) {
        textLanguage = deckArray[i].study_language;
      }
    }

    let submissionMode;
    if (this.state.isSubmissionFile) {
      submissionMode = "file";
    } else {
      submissionMode = "text";
    }

    axios.defaults.headers.common["Authorization"] = this.props.token;

    axios
      .post(`${process.env.REACT_APP_API_URL}/convert-file/1`, {
        name: this.state.uploadName,
        submission_mode: submissionMode,
        url: url,
        text: text,
        deck_param: deckParam,
        user_id: userId,
        text_language: textLanguage,
        file_type: fileType,
      })
      .then((response) => {
        this.setState({ uploadId: response.data.text_id });
        logEvent(analytics, "text_added", {
          submission_mode: submissionMode,
          file_type: fileType,
          text_language: textLanguage,
        });
        this.refreshRedux();
        this.handleRedirect();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        window.alert("Something went wrong processing your file.");
        this.setState(resetState);
      });
  };

  handleRedirect = () => {
    this.setState({ renderRedirect: true });
  };

  refreshRedux = () => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/all-uploads/${this.props.userApiId}`,
      { method: "GET", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentUploads(data.uploads);
      })
      .catch((error) => console.error(error));
  };

  submitTextInput = () => {
    if (this.state.textInput.split(" ").length >= 10) {
      /* TODO: Catch more problematic upload names and add cleaning function */
      this.setState({
        isText: true,
        isSubmissionFile: false,
        uploadName:
          this.state.textInput.slice(0, 10).replace(/\W/g, "") + "...",
      });
    } else {
      window.alert("Enter a text that has at least 10 words.");
    }
  };

  render() {
    return (
      <div>
        <SectionHeading headingText="Upload A New Text" isButton={false} />

        <div className="space-y-6">
          <div>
            {this.state.isText ? (
              <div className="flex flex-row items-center space-x-2">
                <button
                  onClick={() =>
                    this.setState({
                      fileUrl: "",
                      fileName: "",
                      uploadName: "",
                      textInput: "",
                      isText: false,
                      isSubmissionFile: null,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {this.state.isSubmissionFile ? (
                  <p className="text-sm font-medium text-gray-700">
                    {this.state.fileName}
                  </p>
                ) : (
                  <div className="max-w-lg text-xs font-medium text-gray-700">
                    {this.state.textInput.slice(0, 150)}...
                  </div>
                )}
              </div>
            ) : (
              <div className="max-w-lg space-y-4">
                {this.props.currentUploads.length >= 5 &&
                  this.props.userApiId.toString() !==
                    process.env.REACT_APP_ADMIN_API_ID && (
                    <div>
                      <Alert status="warning" rounded="md">
                        <AlertIcon />
                        <p>
                          Reached 5 text account limit. Delete texts or{" "}
                          <a
                            className="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://forms.gle/ii3nUhEjGJ2MUyRXA"
                          >
                            upgrade here.
                          </a>
                        </p>
                      </Alert>
                    </div>
                  )}
                <Box sx={{ position: "relative" }}>
                  <div
                    className={`space-y-2 ${
                      this.props.currentUploads.length >= 5 && "px-1"
                    }`}
                  >
                    <textarea
                      onChange={(event) =>
                        this.setState({ textInput: event.target.value })
                      }
                      id="text"
                      name="text"
                      rows="10"
                      placeholder="Type or copy and paste your text here..."
                      className="w-full p-4 shadow-sm block focus:ring-gray-400 focus:border-gray-400 sm:text-sm border-2 border-gray-400 rounded-md"
                    />
                    <div className="flex flex-row items-start justify-center space-x-6">
                      <button
                        onClick={this.submitTextInput}
                        className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-gray-200 hover:bg-gray-300"
                      >
                        Submit
                      </button>
                      <div className="flex flex-col items-center space-y-2">
                        <FileUploadInput
                          mainFolder="text-files"
                          onFileUpload={(
                            url,
                            fileNameRegex,
                            fileNameOriginal,
                            fileType
                          ) =>
                            this.setState({
                              fileUrl: url,
                              fileName: fileNameOriginal,
                              uploadName: fileNameRegex,
                              fileType: fileType,
                              isSubmissionFile: true,
                              isText: true,
                            })
                          }
                        />
                        <div className="flex flex-row space-x-1 items-center text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>
                          <label className="text-xs">PDF/TXT</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.props.currentUploads.length >= 5 &&
                    this.props.userApiId.toString() !==
                      process.env.REACT_APP_ADMIN_API_ID && (
                      <Overlay opacity={0.6} color="#FFFFFF" zIndex={5} />
                    )}
                </Box>
              </div>
            )}
          </div>

          {this.state.isText && (
            <div className="max-w-lg space-y-4">
              <WordInput
                inputValue={this.state.uploadName}
                onInputChange={(event) =>
                  this.setState({ uploadName: event.target.value })
                }
                textMainHeadline=""
                textSubHeadline=""
                textMain="Add Upload Name"
                textOptional=""
              />

              <DeckDropdown
                onDeckChange={(value) => this.setState({ deckId: value })}
              />

              <div className="flex flex-row space-x-3 pt-2">
                <button
                  type="button"
                  onClick={this.assertHandleProcessText}
                  className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-gray-200 hover:bg-gray-300"
                >
                  Process Text
                </button>
                <button
                  onClick={this.props.history.goBack}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {this.state.redirectStarted && (
            <div className="bg-gray-50 sm:rounded-lg pm-8">
              <p className="text-md leading-6 font-medium text-gray-900 px-4 py-5 sm:p-6 space-x-5">
                Processing Text and Loading Words... You will be redirected in a
                bit.
              </p>
            </div>
          )}

          {this.state.renderRedirect && (
            <Redirect
              to={`/app/text-view/text/${this.state.deckId}/${this.state.uploadId}`}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUploads: (uploads) => dispatch(setCurrentUploads(uploads)),
});

const mapStateToProps = ({ decks, user }) => ({
  currentDecks: decks.currentDecks,
  currentCardTypes: decks.currentCardTypes,
  userApiId: decks.user_api_id,
  currentUploads: decks.currentUploads,
  token: user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
