import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { analytics } from "../../firebase.utils";
import { useRouter } from "../../hooks/useRouter";
import LoadingPage from "../universal-functional-components/loading-page.component";

export default function Extension() {
  const router = useRouter();
  const userApiId = useSelector((state) => state.decks.user_api_id);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (userApiId && router.query && !processing) {
      processText();
      setProcessing(true);
    }
  }, [router, userApiId]);

  const token = useSelector((state) => state.user.token);

  const processText = () => {
    const headers = new Headers({ Authorization: token });

    fetch(
      `${process.env.REACT_APP_API_URL}/convert-file/0?name=${router.query.title}&submission_mode=url&url=${router.query.url}&user_id=${userApiId}&text_language=en`,
      { method: "POST", headers: headers }
    )
      .then((res) => res.json())
      .then((data) => {
        logEvent(analytics, "text_added", {
          submission_mode: "url",
          file_type: "url",
          text_language: "undefined",
        });
        router.push(`/app/text-view/text/${data.deck_id}${data.text_id}`);
      })
      .catch((error) => console.log(error));
  };
  return <LoadingPage />;
}
