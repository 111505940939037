import React from "react";
import LanguageSelector from "./language-selector.component";
import SidebarMenuItem from "./sidebar-menu-item.component";
import SidebarStats from "./sidebar-stats.component";

const MobileNavigation = (props) => (
  <div
    class="fixed inset-0 flex z-40 md:hidden"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-600 bg-opacity-75"
      aria-hidden="true"
    ></div>
    <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
      <div class="absolute top-0 right-0 -mr-12 pt-2">
        <button
          onClick={props.onMobileMenuClose}
          type="button"
          class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span class="sr-only">Close sidebar</span>
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div class="flex-1 h-0 overflow-y-auto">
        <nav class="px-2 space-y-2">
          <div className="flex flex-row space-x-4">
            {/* <LanguageSelector isMobile={true} /> */}
            <SidebarStats />
          </div>

          <div onClick={props.onMobileMenuClose}>
            <SidebarMenuItem
              menuItemText="Dashboard"
              menuItemLink="/app"
              menuItemIcon={null}
            />
          </div>
          <div onClick={props.onMobileMenuClose}>
            <SidebarMenuItem
              menuItemText="Upload Text"
              menuItemLink="/app/upload/settings"
              menuItemIcon={
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              }
            />
          </div>
          <div onClick={props.onMobileMenuClose}>
            <SidebarMenuItem
              menuItemText="My Texts"
              menuItemLink="/app/texts"
              menuItemIcon={
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                />
              }
            />
          </div>
          <div onClick={props.onMobileMenuClose}>
            <SidebarMenuItem
              menuItemText="My Decks"
              menuItemLink="/app/decks"
              menuItemIcon={
                <>
                  <path d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  />
                </>
              }
            />
          </div>
        </nav>
      </div>
    </div>

    <div class="flex-shrink-0 w-14" aria-hidden="true"></div>
  </div>
);

export default MobileNavigation;
