import React from "react";
import Emoji from "../../../components/universal-components/emoji.component";

const LanguageButton = (props) => (
    <button onClick={props.onButtonClick} type="button" 
    className={`${props.active && "ring-2 ring-offset-2 ring-yellow-500"} focus:outline-none border border-transparent shadow inline-flex items-center px-3 py-2 text-sm leading-4 font-normal rounded-md text-gray-600 bg-yellow-50 hover:bg-yellow-100`}>
      <Emoji symbol={props.emojiSymbol}/>
      <p className="ml-1">{props.name}</p>
    </button>
)

export default LanguageButton;