import React from "react";
import FlashcardContent from "./flashcard-content.component";
import { Tooltip } from "@chakra-ui/react";
import WordLevel from "./word-level.component";

class Flashcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFront: true,
      activeWord: [],
    };
    this.handleShowBack = this.handleShowBack.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress);
    console.log(this.props);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress);
  }

  onKeyPress = (event) => {
    const keyCode = event.keyCode;
    const key = event.key;

    if (!this.props.disableEventListener) {
      if (key === "1" && !this.state.isFront) {
        this.props.onUserAction(0);
      }

      if (key === "2" && !this.state.isFront) {
        this.props.onUserAction(3);
      }

      if (key === "3" && !this.state.isFront) {
        this.props.onUserAction(4);
      }

      if (key === "4" && !this.state.isFront) {
        this.props.onUserAction(5);
      }

      // Space or Enter
      if ((keyCode === 32 || keyCode === 13) && this.state.isFront) {
        this.handleShowBack();
      }
    }
  };

  handleShowBack = () => {
    this.setState({ isFront: false });
  };

  render() {
    return (
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <WordLevel interval={this.props.exerciseInfo.word_info.interval} />
        <div
          className={`p-12 sm:min-h-${
            48 + 12 * Math.max(this.props.front.length, this.props.back.length)
          }`}
        >
          <FlashcardContent
            activeFields={this.props.front}
            contentInfo={this.props.exerciseInfo.word_info}
          />
          {!this.state.isFront && (
            <div className="pt-4 sm:pt-8">
              <div className="w-full border-t border-gray-300 pb-4 sm:pb-8" />
              <FlashcardContent
                activeFields={this.props.back}
                contentInfo={this.props.exerciseInfo.word_info}
              />
            </div>
          )}
        </div>

        <div className="flex justify-center bg-gray-50 px-4 py-4 sm:px-6">
          <span class="relative z-0 inline-flex shadow-sm rounded-md">
            {this.state.isFront ? (
              <button
                onClick={this.handleShowBack}
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                Show Back
              </button>
            ) : (
              <div>
                <Tooltip
                  hasArrow
                  label="You didn't remember the answer"
                  bg="gray.600"
                  openDelay={500}
                >
                  <button
                    onClick={() => this.props.onUserAction(0)}
                    type="button"
                    class="relative inline-flex items-center px-4 py-2 space-x-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                  >
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-gray-800">
                      1
                    </span>
                    <span>Couldn't</span>
                  </button>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="You had difficulty remembering the answer"
                  bg="gray.600"
                  openDelay={500}
                >
                  <button
                    onClick={() => this.props.onUserAction(3)}
                    type="button"
                    class="-ml-px relative inline-flex items-center px-4 py-2 space-x-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                  >
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-gray-800">
                      2
                    </span>
                    <p>Hard</p>
                  </button>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="You remembered the answer after some thought"
                  bg="gray.600"
                  openDelay={500}
                >
                  <button
                    onClick={() => this.props.onUserAction(4)}
                    type="button"
                    class="-ml-px relative inline-flex items-center px-4 py-2 space-x-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                  >
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-gray-800">
                      3
                    </span>
                    <p>Okay</p>
                  </button>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="You remembered the answer immediately"
                  bg="gray.600"
                  openDelay={500}
                >
                  <button
                    onClick={() => this.props.onUserAction(5)}
                    type="button"
                    class="-ml-px relative inline-flex items-center px-4 py-2 space-x-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                  >
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-gray-800">
                      4
                    </span>
                    <p>Easy</p>
                  </button>
                </Tooltip>
              </div>
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default Flashcard;
