import React from "react";
import { connect } from "react-redux";
import SectionHeading from "../../../pages/app/app-page-components/section-heading.component";
import Onboarding from "./dashboard-components/onboarding-component";
import LoadingSkeleton from "../../universal-components/loading-skeleton.component";
import DeckList from "../my-decks/decks-section-components/deck-list.component";
import AllTextsListItem from "../all-texts/all-texts-components/all-texts-list-item.component";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedLanguage: false,
    };
  }

  findDeckName = (id) => {
    for (let x of this.props.currentDecks) {
      if (x.id === id) {
        return x.name;
      }
    }

    return "Missing deck!";
  };

  render() {
    return (
      <div>
        {/* TODO Adjust this solution for how the user navigates to "Start Learning" */}
        <SectionHeading
          headingText="Dashboard"
          isButton={this.props.currentDecks.length > 0}
          buttonText="Learn All"
          buttonLink="/app/session/all/-/-/flashcards/20"
        />

        {!this.props.isLoaded ? (
          <LoadingSkeleton />
        ) : (
          <div>
            {/* TODO Add conditional rendering for onboarding (add to user state) */}
            {this.props.userApiId && (
              <Onboarding
                addedLanguage={this.state.addedLanguage}
                {...this.props}
              />
            )}

            {/* TODO: Make this conditional on user finishing onboarding; Only display decks with words that are due; if none are due display explainer*/}
            {this.props.currentDecks.filter(
              (deck) =>
                deck.study_language === this.props.targetLanguage &&
                deck.words_due_count > 0
            ).length > 0 && (
              <div className="mt-8 space-y-4">
                <h3 class="text-lg font-medium text-gray-900">Due Decks</h3>
                <DeckList isLoaded={this.props.isLoaded} filterDue={true} />
              </div>
            )}

            {/* TODO: filter these for uploads that are relevant to the user (from which the user last added words) */}
            {/* TODO: use text list component instead of mapping it here (see DeckList) */}
            {this.props.currentUploads.filter(
              (text) =>
                text.text_language === this.props.targetLanguage &&
                text.text_language
            ).length > 0 && (
              <div className="mt-8 space-y-2">
                <h3 class="text-lg font-medium text-gray-900 pb-2">
                  Current Texts
                </h3>
                <ul className="grid gap-y-4 gap-x-6 grid-cols-1 xl:grid-cols-2">
                  {this.props.currentUploads
                    .filter(
                      (text) =>
                        text.text_language === this.props.targetLanguage &&
                        text.text_language
                    )
                    .slice(0, 6)
                    .map((e) => {
                      return (
                        e["text_language"] === this.props.targetLanguage && (
                          <AllTextsListItem
                            textInfo={e}
                            deckName={this.findDeckName(e.deck_param)}
                          />
                        )
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  userApiId: decks.user_api_id,
  currentUploads: decks.currentUploads,
  currentCardTypes: decks.currentCardTypes,
  targetLanguage: decks.target_language,
});

export default connect(mapStateToProps)(Dashboard);
