import React from "react";

class SingleWordInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: this.props.isActive,
            definitionEmpty: false,
            suggestionArray: [],
            translationSuggestion: [],
            firstTranslation: "",
        }
    }

    componentDidMount() {
        const infoType = "definition"
        const word = this.props.word.root
        const sourceLanguage = "en"
        const targetLanguage = "de"
        const posTag = this.props.word.pos

        fetch(`${process.env.REACT_APP_API_URL}/wordnik?word=${word}&pos=${posTag}&info_type=${infoType}`, { method: "GET", redirect: "follow" })
            .then(response => response.json())
            .then(data => {
                let emptyArray
                if (data.length === 0) {
                    emptyArray = true
                } else {
                    emptyArray = false
                }
                this.setState({ suggestionArray: data, definitionEmpty: emptyArray });

            })
            .catch(error => window.alert(error))

        fetch(`${process.env.REACT_APP_API_URL}/translation?word=${word}&source=${sourceLanguage}&target=${targetLanguage}`, { method: "GET", redirect: "follow" })
            .then(response => response.json())
            .then(data => {
                let translationSuggestion
                let firstTranslation // vorübergehend
                let translationArray = data.translations.filter(function (translation) { return translation.posTag === posTag })
                if (data.translations.length === 0) {
                    translationSuggestion = []
                    firstTranslation = ""
                } else if (translationArray.length === 0) {
                    translationSuggestion = data.translations
                    firstTranslation = data.translations[0]["displayTarget"]
                } else {
                    translationSuggestion = translationArray
                    firstTranslation = translationArray[0]["displayTarget"]
                }
                this.setState({ translationSuggestion: translationSuggestion, firstTranslation: firstTranslation });
            })
            .catch(error => window.alert(error))
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive) {
            this.setState({ isOpen: this.props.isActive })
        }
    }

    render() {
        return (
            <div onClick={() => this.setState({ isOpen: !this.state.isOpen })} className="cursor-pointer">
                { this.state.isOpen ? (
                    <div className={`${(this.props.isActive) ? "bg-yellow-50" : "bg-white"} overflow-hidden shadow rounded-lg select-none`}>
                        <div className="p-4">
                            <p className="text-xs">
                                {this.props.word.pos}
                            </p>
                        </div>
                        <div className="px-4">
                            <p className="inline text-sm">{this.props.word.root} </p>
                            { (this.state.firstTranslation !== "") && <p className="inline text-sm font-medium">→ {this.state.firstTranslation}</p>}
                            
                            {(this.state.definitionEmpty) ?
                                (<p className="text-sm italic pt-2">
                                    No definition found.
                                </p>
                                ) : (
                                    <p className="text-sm italic pt-2">
                                        {this.state.suggestionArray[0]}
                                    </p>

                                )

                            }

                        </div>
                        <div className="p-4 flex flex-row justify-between">
                            <button>
                                <svg className="text-gray-400 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>

                            <div className="flex flex-row space-x-1">
                                <button>
                                    <svg className="text-gray-400 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <button>
                                    <svg className="text-gray-400 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`${(this.props.isActive) ? "bg-yellow-50" : "bg-white"} overflow-hidden shadow rounded-lg`}>
                        <div className="px-4 py-2 select-none">
                            <div className="flex flex-row justify-between">

                                <div>
                                    <p className="inline font-medium text-sm">{this.props.word.root} </p><p className="inline text-xs">{this.state.firstTranslation}</p>
                                </div>

                                <button>
                                    <svg className="text-gray-400 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                                    </svg>
                                </button>

                            </div>


                        </div>
                    </div>
                )}
            </div>


        )
    }
}

export default SingleWordInfo