import { useHotkeys } from "@mantine/hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import WordSelectionList from "../../../../pages/onboarding/onboarding-subpages/onboarding-subpages-components/word-selection-list.component";
import ButtonWithShortcut from "../../../universal-components/buttons/button-with-shortcut.component";

export default function QuizAssessByList({
  words,
  onAction,
  userApiId,
  deckId,
}) {
  const [selectedWords, setSelectedWords] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  useHotkeys([["mod+Enter", () => handleSubmit()]]);

  const token = useSelector((state) => state.user.token);

  const handleSubmit = () => {
    for (let i = 0; i < words.length; i++) {
      if (selectedWords.includes(words[i].word_pos_key)) {
        handleIncorrect(words[i]);
      } else {
        handleCorrect(words[i]);
      }
    }
    setSubmitted(true);
    onAction("assessed", "Known words submitted!");
  };

  const handleCorrect = (wordInfo) => {
    const headers = new Headers({ Authorization: token });

    fetch(
      `${process.env.REACT_APP_API_URL}/known-words/${userApiId}?word_root=${
        wordInfo["word_root"]
      }&word_original=${wordInfo["word_root"]}&pos=${
        wordInfo["pos"]
      }&language=${"en"}`,
      { method: "POST", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.error(error));
  };

  const handleIncorrect = (wordInfo) => {
    const headers = new Headers({ Authorization: token });

    let examples = [];
    wordInfo["original_words_list"].forEach((word) => {
      examples.push(word["sent"]);
    });
    fetch(
      `${process.env.REACT_APP_API_URL}/unknown-words/${userApiId}?word_root=${
        wordInfo["word_root"]
      }&word_original=${wordInfo["word_root"]}&pos=${
        wordInfo["pos"]
      }&language=${"en"}`,
      { method: "POST", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.error(error));
/*     fetch(
      `${process.env.REACT_APP_API_URL}/converted-words?word=${wordInfo["word_pos_key"]}&text_id=${wordInfo["text_id"]}`,
      { method: "POST", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
    fetch(
      `${process.env.REACT_APP_API_URL}/word/1?word_root=${
        wordInfo["word_root"]
      }&translation=${""}&definition=${""}&examples=${JSON.stringify(
        examples.slice(0, 15)
      )}&pos=${wordInfo["pos"]}&deck_id=${parseInt(
        deckId
      )}&user_id=${userApiId}&tags=${""}&synonyms=${""}`,
      { method: "POST", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      }); */
  };

  return (
    <div>
      <div className="text-lg font-medium mb-4">
        {/* Based on your language level, we believe you know these words. Please
        select any that you don't. */}
        <p>Select the words you don't know.</p>
        {/*         <p className="font-normal text-base italic">
          Hover over a word to see an example sentence from your text.
        </p> */}
      </div>
      <div className="">
        <WordSelectionList
          words={words}
          selectedWords={selectedWords}
          setSelectedWords={(value) => setSelectedWords(value)}
          componentStyle="quiz"
        />
      </div>
      {!submitted && (
        <div className="flex justify-end mt-6">
          <ButtonWithShortcut
            textMain="Submit"
            textShortcut="cmd + ↵"
            onButtonClick={() => handleSubmit()}
          />
        </div>
      )}
    </div>
  );
}
