import { DecksActionTypes } from "./decks.types"


const INITIAL_STATE = {
    user_api_id: null,
    currentDecks: [],
    currentCardTypes: [],
    currentUploads: [],
}

const decksReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DecksActionTypes.SET_USER_API_ID:
            return {
                ...state,
                user_api_id: action.payload
            }
        case DecksActionTypes.SET_CURRENT_DECKS:
            return {
                // pass in everything that we don't want to modify
                ...state,
                // update what we do want to modify
                currentDecks: action.payload
            }
        case DecksActionTypes.SET_CURRENT_CARD_TYPES:
            return {
                // pass in everything that we don't want to modify
                ...state,
                // update what we do want to modify
                currentCardTypes: action.payload
            }
        case DecksActionTypes.SET_CURRENT_UPLOADS:
            return {
                // pass in everything that we don't want to modify
                ...state,
                // update what we do want to modify
                currentUploads: action.payload
            }
        case DecksActionTypes.SET_CURRENT_TARGET_LANGUAGE:
            return {
                ...state,
                target_language: action.payload
            }
        case DecksActionTypes.SET_CURRENT_TRANSLATION_LANGUAGE:
            return {
                ...state,
                translation_language: action.payload
            }
        default:
            return state
    }
}

export default decksReducer;