import React from "react";

const WordItem = ({
  activeWordKey,
  id,
  onWordSelect,
  wordPos,
  wordDisplay,
}) => (
  <li
    className={`${
      activeWordKey === id
        ? "bg-yellow-100 border-gray-400 border-2"
        : "border-gray-200 border"
    } shadow-sm rounded-md cursor-pointer flex-1 px-4 py-2 truncate`}
    onClick={onWordSelect}
  >
    <p className="text-gray-900 text-xs lowercase">{wordPos}</p>
    <p className="text-gray-900 font-medium text-sm">{wordDisplay}</p>
  </li>
);

export default WordItem;
