import { Link } from "react-router-dom";

export default function OnboardingProgress({ steps }) {
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <p className="text-sm font-medium">
        Step {steps.findIndex((step) => step.status === "current") + 1} of{" "}
        {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === "complete" ? (
              <Link
                to={step.link ? step.link : "#"}
                className={`block w-2.5 h-2.5 bg-yellow-600 rounded-full hover:bg-yellow-900 ${
                  !step.link && "pointer-events-none"
                }`}
              >
                <span className="sr-only">{step.name}</span>
              </Link>
            ) : step.status === "current" ? (
              <Link
                to={step.link ? step.link : "#"}
                className={`relative flex items-center justify-center ${
                  !step.link && "pointer-events-none"
                }`}
                aria-current="step"
              >
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-yellow-200" />
                </span>
                <span
                  className="relative block w-2.5 h-2.5 bg-yellow-600 rounded-full"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </Link>
            ) : (
              <Link
                to={step.link ? step.link : "#"}
                className={`block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400 ${
                  !step.link && "pointer-events-none"
                }`}
              >
                <span className="sr-only">{step.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
