export const PosLabel = ({ pos, isGray = null }) => (
  <div>
    <span
      className={`text-xs font-medium text-gray-900 px-2.5 ${
        isGray ? "bg-gray-100" : "bg-yellow-200"
      }`}
    >
      {pos}
    </span>
  </div>
);
