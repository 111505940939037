import { useState } from "react";
import logoSmall from "../../../assets/logo_small.png";
import { analytics } from "../../../firebase.utils";
import { setUserProperties } from "firebase/analytics";

const problemOptions = [
  {
    name: "School/Uni",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path d="M12 14l9-5-9-5-9 5 9 5z" />
        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
        />
      </svg>
    ),
  },
  {
    name: "Work/Career",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
  },
  {
    name: "Hobby/Culture",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  },
  {
    name: "Family/Friends",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
  { name: "Other" },
];

const levelOptions = [
  { name: "Beginner" },
  { name: "Intermediate" },
  { name: "Advanced" },
  { name: "Fluent" },
  { name: "Native" },
];

const OptionItem = ({ name, active, disabled, onSelect, icon }) => (
  <button
    className={`mb-2 border-2 px-6 py-3 text-xl font-bold rounded-md ${
      disabled
        ? "border-gray-300 text-gray-300"
        : "border-gray-700 shadow-sm hover:shadow-md"
    } ${active && "bg-yellow-200"} ${
      icon && "flex flex-row justify-center items-center space-x-2"
    }`}
    onClick={onSelect}
    disabled={disabled}
  >
    <span>{icon}</span>
    <span>{name}</span>
  </button>
);

export default function Onboarding0({
  user: { displayName },
  onSubmit,
  userApiId,
}) {
  const [problem, setProblem] = useState("");
  const [level, setLevel] = useState("");

  const handleSubmit = () => {
    let langLevel = 0.2;
    if (level === "Intermediate") {
      langLevel = 0.4;
    } else if (level === "Advanced") {
      langLevel = 0.6;
    } else if (level === "Fluent") {
      langLevel = 0.75;
    } else if (level === "Native") {
      langLevel = 0.85;
    }
    setUserProperties(analytics, { languageLevel: level, segment: problem });

    fetch(
      `${process.env.REACT_APP_API_URL}/set-language-level/${userApiId}?value=${langLevel}`,
      { method: "PUT", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        onSubmit();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-12 max-w-5xl px-4">
      <div className="flex flex-row justify-center items-center space-x-6">
        <img
          className="block h-6 sm:h-8 w-auto"
          src={logoSmall}
          alt="vocab logo small"
        />
        <h2 className="text-lg sm:text-xl font-medium mt-1">
          Welcome to Vocab, {displayName}!
        </h2>
      </div>
      <div className="space-y-6 text-2xl sm:text-3xl font-extrabold text-gray-900 text-center">
        <h1 className="">Why do you want to improve your English? </h1>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:space-x-2 sm:justify-center sm:items-center">
          {problemOptions.map((e) => (
            <OptionItem
              name={e.name}
              onSelect={() => setProblem(e.name)}
              active={e.name === problem}
              icon={e.icon}
            />
          ))}
        </div>
      </div>
      <div className="space-y-6 text-3xl font-extrabold text-gray-900 text-center">
        <h1 className={`${!problem && "text-gray-300"}`}>Where do we start?</h1>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:space-x-2 sm:justify-center sm:items-center">
          {levelOptions.map((e) => (
            <OptionItem
              name={e.name}
              disabled={!problem}
              onSelect={() => setLevel(e.name)}
              active={e.name === level}
            />
          ))}
        </div>
      </div>
      <div className="pb-12">
        <button
          onClick={handleSubmit}
          className={`items-center px-6 py-3 justify-center border-2 rounded-md shadow-sm text-xl font-medium text-white ${
            level
              ? "border-yellow-600 bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              : "border-gray-400 bg-gray-300 bg-transparent opacity-70"
          }`}
          disabled={!level}
        >
          Start Learning
        </button>
      </div>
    </div>
  );
}
