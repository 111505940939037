import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import logoSmall from "../../assets/logo_small.png";

export default function LandingHeader() {
  return (
    <div className="z-10 w-full fixed top-0 bg-yellow-50 py-3 shadow-sm">
      <nav className="max-w-7xl mx-auto flex items-center justify-between sm:h-10 px-4 sm:px-6">
        <Link to="/">
          <span className="sr-only">Vocab</span>
          <img
            className="h-6 w-auto sr-only sm:not-sr-only sm:h-8"
            src={logo}
            alt="logo"
          />
          <img className="h-8 w-auto sm:hidden" src={logoSmall} alt="" />
        </Link>
        <div className="flex items-center justify-end inset-y-0 right-0">
          <a
            href="https://app.vocab.so/app"
            className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-600 hover:text-gray-700"
          >
            Log In
          </a>
          <a
            href="https://app.vocab.so/sign-up?language=none"
            className="inline-flex rounded-md shadow"
          >
            <div className="cursor-pointer inline-flex items-center px-4 py-2 text-base font-medium rounded-md bg-red-400 bg-transparent hover:bg-red-400 focus:ring-offset-red-400 bg-opacity-95 border w-full justify-center border-transparent shadow-xs text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
              Start Learning
            </div>
          </a>
        </div>
      </nav>
    </div>
  );
}
