import { useToast } from "@chakra-ui/react";
import { useHotkeys } from "@mantine/hooks";
import { useState } from "react";
import Definitions from "../../../../../app-dynamic-upload/dynamic-card-configuration-components/configuration-components/definitions.component";
import ButtonWithShortcut from "../../../../../universal-components/buttons/button-with-shortcut.component";

export default function EditDefinitions({
  id,
  word,
  pos,
  label = "You haven't set a definition. Do that now:",
  onSubmit,
}) {
  const [definition, setDefinition] = useState("[]");
  const [submitted, setSubmitted] = useState(false);
  const toast = useToast();

  useHotkeys([
    [
      "mod+Enter",
      () => {
        handleSubmit();
      },
    ],
  ]);

  console.log(definition);

  const handleSubmit = () => {
    if (!definition || JSON.parse(definition).length === 0) {
      toast({
        title: "No definition selected",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/word/${id}?definition=${definition}`,
      { method: "PUT", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
        onSubmit();
        toast({
          title: "Saved successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => window.alert("Error: ", error));
  };

  if (submitted) {
    return (
      <div className="flex flex-col px-2.5">
        <p className="text-md font-bold">Definition:</p>
        <p className="italic">
          {JSON.parse(definition).map((e, i) => (
            <div className="">
              <b>{i + 1}.</b> {e[0]}
            </div>
          ))}
        </p>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-start space-y-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        <Definitions
          word={word}
          pos={pos}
          onDefinitionChange={(value) => {
            setDefinition(JSON.stringify(value));
          }}
        />
        {JSON.parse(definition).length > 0 && (
          <div className="pt-4">
            <ButtonWithShortcut
              textMain="Submit"
              textShortcut="cmd + ↵"
              onButtonClick={handleSubmit}
            />
          </div>
        )}
      </div>
    );
  }
}
