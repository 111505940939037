import React from "react";
import { connect } from "react-redux";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Checkbox,
  Progress,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spinner,
} from "@chakra-ui/react";
import { setCurrentDecks } from "../../redux/decks/decks.actions";
import WordItem from "./dynamic-card-configuration-components/word-item.component";
import TextViewAdd from "./text-view-add.component";
import WordSectionSelector from "./dynamic-card-configuration-components/word-section-selector.component";
import Notification from "../universal-components/notification.component";
import { analytics } from "../../firebase.utils";
import { logEvent } from "firebase/analytics";
import WordList from "./word-list.component";
import LearnNavigation from "../app-page/learn/learn-navigation.component";

const axios = require("axios").default;

const partOfSpeech = {
  Adjectives: ["ADJ"],
  Adverbs: ["ADV"],
  Verbs: ["AUX", "VERB"],
  Pronouns: ["PRON"],
  Conjunctions: ["CONJ", "CCONJ", "SCONJ"],
  Nouns: ["PROPN", "NOUN"],
  Other: [
    "ADP",
    "DET",
    "INTJ",
    "NUM",
    "PART",
    "PUNCT",
    "SYM",
    "X",
    "SPACE",
    "CONJ",
    "CCONJ",
    "SCONJ",
  ], // remove "CONJ", "CCONJ", "SCONJ" if re-adding Conjunctions
};

const posOptions = [
  "Adjectives",
  "Adverbs",
  "Verbs",
  "Pronouns",
  "Nouns",
  "Other",
]; // "Conjunctions",

class TextView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processingProgress: 0,
      isConverted: true,

      // conditional rendering words display
      activeList: "all",
      dictionaryLoading: false,

      // word information
      activeWordKey: "",
      activeWordInfo: "",

      // prev word
      prevActiveWord: null,

      // user word information
      wordInput: "",

      allWordsLength: 0,
      totalWords: 0,
      isEmpty: false,

      // notifications
      isNotification: false,
      notificationTitle: "",
      notificationDescription: "",
      notificationStatus: "",

      updateRequested: false,

      // settings
      isDrawerOpen: false,
      isRemoveExistingWords: true,
      isRemoveOutsideDictionary: false,
      difficultyValue: null,
      globalFrequencyRangeStart: -50000,
      globalFrequencyRangeEnd: 0,
      frequencyRangeStart: 0,
      frequencyRangeEnd: 1000,

      wordsAddedGlobal: [],
      wordsKnownManually: [],
      wordsKnownEstimated: [],

      isLoading: true,

      Adjectives: true,
      Adverbs: true,
      Verbs: true,
      Pronouns: true,
      Conjunctions: true,
      Nouns: true,
      Other: true,
      activePOS: [
        "ADJ",
        "ADV",
        "AUX",
        "VERB",
        "PRON",
        "CONJ",
        "CCONJ",
        "SCONJ",
        "PROPN",
        "NOUN",
        "ADP",
        "DET",
        "INTJ",
        "NUM",
        "PART",
        "PUNCT",
        "SYM",
        "X",
        "SPACE",
      ],
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.match.params.itemType === "deck") {
      this.fetchAll();
    } else {
      this.initialFetch();
    }

    document.addEventListener("keydown", this.onKeyPress);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener("keydown", this.onKeyPress);
  }

  fetchAll = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-words/${this.props.match.params.deckId}`,
        { headers: { Authorization: this.props.token } }
      )
      .then((response) => {
        console.log(response);
        this.setWordDisplay(response.data);
        this.setState({ isConverted: true });
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  initialFetch = (isRepeat = 0) => {
    // TODO: case where text has previously stopped
    // const jobId = this.props.location.search.slice(1);
    axios.defaults.headers.common["Authorization"] = this.props.token;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/convert-file/${this.props.match.params.textId}`
      )
      .then((response) => {
        console.log(response);
        if (
          response.status === 204 &&
          this.mounted &&
          !this.state.updateRequested
        ) {
          this.setState({ isConverted: false });
          if (isRepeat > 4) {
            axios
              .put(
                `${process.env.REACT_APP_API_URL}/convert-file/${this.props.match.params.textId}`
              )
              .then(() => {
                setTimeout(() => this.initialFetch(), 20000);
                window.alert(
                  "It seems your text has failed processing. Restarting processing."
                );
                this.setState({ updateRequested: true });
              });
          } else {
            setTimeout(
              () => this.initialFetch((isRepeat = isRepeat + 1)),
              2000
            );
          }
        } else if (
          response.status === 205 &&
          this.mounted &&
          !this.state.updateRequested
        ) {
          this.setState({ isConverted: false });
          axios.put(
            `${process.env.REACT_APP_API_URL}/convert-file/${this.props.match.params.textId}`
          );
          setTimeout(() => this.initialFetch(), 10000);
          window.alert(
            "We have added new word information since this text was last updated. Reprocessing the text."
          );
          this.setState({ updateRequested: true });
        } else if (response.status === 206 && this.mounted) {
          this.setState({ isConverted: false });
          this.setWordDisplay(response.data.words);

          this.setState({
            processingProgress: response.data.progress,
          });
          setTimeout(() => this.initialFetch(), 1000);
        } else {
          console.log(response, "200");
          this.setWordDisplay(response.data);
          this.setState({ isConverted: true });
        }
      })
      .catch((error) => {
        console.log(error);
        if (this.mounted) {
          setTimeout(() => this.initialFetch(), 1000);
        }
      });
  };

  fetchWordDisplay = () => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(
      `${process.env.REACT_APP_API_URL}/convert-file/${this.props.match.params.textId}`,
      { method: "GET", redirect: "follow", headers: headers }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setWordDisplay(data);
      })
      .catch((error) => console.log(error));
  };

  setWordDisplay = (data) => {
    let difficultyValue;
    if (!this.state.difficultyValue && this.state.difficultyValue !== 0) {
      difficultyValue = data.difficulty;
      this.setState({ difficultyValue: difficultyValue });
      console.log("reset");
    } else {
      difficultyValue = this.state.difficultyValue;
    }
    const allWords = data.words_todo.filter((e) =>
      this.filterWord(
        e,
        this.state.activePOS,
        this.state.isRemoveExistingWords,
        this.state.isRemoveOutsideDictionary,
        difficultyValue
      )
    );
    this.setState({
      allWordsUnfiltered: data.words_todo,
      allWords: allWords,
      allWordsLength: allWords.length,
      convertedWords: data.words_added_local,
      wordsAddedGlobal: data.words_added_global,
      wordsKnownManually: data.words_known_manually,
      wordsKnownEstimated: data.words_known_estimated,
      totalWords: data.words_todo.length,
      isEmpty: data.words_todo.length === 0,
      textLanguage: data.text_language,
    });
    this.updateActiveWord(this.state.allWords[this.state.prevActiveWord]);
    this.setState({ isLoading: false });
  };

  updateActiveWord = (word) => {
    if (word) {
      this.setState({
        activeWordKey: word["word_pos_key"],
        activeWordInfo: word,
      });
    }
  };

  handleWordDisplayChange = (event) => {
    const change = event.target.value;
    if (!(change === this.state.activeList)) {
      this.setState({ activeList: change });
    }
  };

  stepForward = (step) => {
    var prevActiveWordIndex = this.state.allWords.findIndex(
      (w) => w["word_pos_key"] === this.state.activeWordKey
    );
    if (prevActiveWordIndex + step <= this.state.allWords.length) {
      this.updateActiveWord(this.state.allWords[prevActiveWordIndex + step]);
    }
  };

  stepBack = (step) => {
    var prevActiveWordIndex = this.state.allWords.findIndex(
      (w) => w["word_pos_key"] === this.state.activeWordKey
    );
    if (prevActiveWordIndex - step >= 0)
      this.updateActiveWord(this.state.allWords[prevActiveWordIndex - step]);
  };

  onKeyPress = (event) => {
    if (event.shiftKey) {
      var step = 1;
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        // grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-9 m-8
        const windowSize = window.innerWidth;

        if (windowSize < 620) {
          step = 3;
        } else if (windowSize >= 640 && windowSize < 768) {
          step = 4;
        } else if (windowSize >= 768 && windowSize < 1024) {
          step = 5;
        } else if (windowSize >= 1024 && windowSize < 1280) {
          step = 4;
        } else if (windowSize >= 1280) {
          step = 4;
        }
      }

      if (event.key === "ArrowRight" || event.key === "ArrowDown") {
        this.stepForward(step);
      }

      if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
        this.stepBack(step);
      }
    }
  };

  handleCardSubmit = () => {
    const headers = new Headers({ Authorization: this.props.token });
    if (this.props.match.params.itemType !== "deck") {
      fetch(
        `${process.env.REACT_APP_API_URL}/converted-words?word=${this.state.activeWordKey}&text_id=${this.props.match.params.textId}`,
        { method: "POST", redirect: "follow", headers: headers }
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
    } else {
      for (const text of this.state.activeWordInfo.texts) {
        fetch(
          `${process.env.REACT_APP_API_URL}/converted-words?word=${this.state.activeWordKey}&text_id=${text.id}`,
          { method: "POST", redirect: "follow", headers: headers }
        )
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error(error));
      }
    }

    fetch(`${process.env.REACT_APP_API_URL}/decks/${this.props.userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => this.props.setCurrentDecks(data.decks))
      .catch((error) => console.log(error));

    logEvent(analytics, "word_added", {
      language: this.state.textLanguage,
    });

    this.resetDisplay();
  };

  handleWordRemove = () => {
    this.resetDisplay();
    this.setState({
      isNotification: true,
      notificationTitle: "Word Removed!",
      notificationDescription: "",
      notificationStatus: "info",
    });

  };

  resetDisplay = () => {
    var prevActiveWordIndex = this.state.allWords.findIndex(
      (w) => w.word_pos_key === this.state.activeWordKey
    );

    this.setState({
      activeWordKey: "",
      activeWordInfo: "",
      prevActiveWord: prevActiveWordIndex,
    });

    if (this.props.match.params.itemType === "deck") {
      this.fetchAll();
    } else {
      this.fetchWordDisplay();
    }
  };

  updateFilter = (
    activePOS = this.state.activePOS,
    isRemoveExistingWords = this.state.isRemoveExistingWords,
    isRemoveOutsideDictionary = this.state.isRemoveOutsideDictionary
  ) => {
    const allWordsUnfiltered = this.state.allWordsUnfiltered;
    const allWords = allWordsUnfiltered.filter((e) =>
      this.filterWord(
        e,
        activePOS,
        isRemoveExistingWords,
        isRemoveOutsideDictionary
      )
    );
    this.setState({ allWords: allWords, allWordsLength: allWords.length });
  };

  filterWord = (
    e,
    activePOS,
    isRemoveExistingWords,
    isRemoveOutsideDictionary,
    difficultyValue = this.state.difficultyValue
  ) => {
    return (
      (!isRemoveExistingWords || !e.existing) &&
      (e.frequency < -this.state.globalFrequencyRangeStart ||
        (this.state.globalFrequencyRangeStart === -50000 &&
          e.frequency > 50000)) &&
      (e.frequency > -this.state.globalFrequencyRangeEnd ||
        (e.frequency < 0 && this.state.globalFrequencyRangeEnd === 0)) && // e.frequency < 0 in case word has -1 frequency
      e.word_count > this.state.frequencyRangeStart &&
      (e.word_count < this.state.frequencyRangeEnd ||
        (this.state.frequencyRangeEnd === 20 && e.word_count > 20)) &&
      activePOS.includes(e.pos) &&
      (!isRemoveOutsideDictionary || e.frequency !== -2) &&
      e.difficulty > difficultyValue
    );
  };

  updatePosFilter = (value) => {
    let activePOS = [];
    for (let i = 0; i < posOptions.length; i++) {
      if (posOptions[i] === value) {
        if (!this.state[value]) {
          activePOS = activePOS.concat(partOfSpeech[posOptions[i]]);
        }
      } else if (this.state[posOptions[i]]) {
        activePOS = activePOS.concat(partOfSpeech[posOptions[i]]);
      }
    }

    this.setState({ activePOS: activePOS });

    if (value === "Adjectives") {
      this.setState({ Adjectives: !this.state.Adjectives });
    } else if (value === "Adverbs") {
      this.setState({ Adverbs: !this.state.Adverbs });
    } else if (value === "Verbs") {
      this.setState({ Verbs: !this.state.Verbs });
    } else if (value === "Pronouns") {
      this.setState({ Pronouns: !this.state.Pronouns });
    } else if (value === "Conjunctions") {
      this.setState({ Conjunctions: !this.state.Conjunctions });
    } else if (value === "Nouns") {
      this.setState({ Nouns: !this.state.Nouns });
    } else if (value === "Other") {
      this.setState({ Other: !this.state.Other });
    }

    this.updateFilter(activePOS);
  };

  render() {
    return (
      <div className="bg-yellow-50 max-w-full min-h-screen pt-2">
        <div className="max-w-7xl flex justify-between items-center mt-8 mx-auto mb-2">
          <LearnNavigation location="text" />
          <div className="flex flex-row space-x-2">
            {this.props.match.params.itemType !== "deck" && (
              <WordSectionSelector
                onSectionSelect={this.handleWordDisplayChange}
                activeList={this.state.activeList}
              />
            )}
            <button
              className="pr-2 flex flex-row items-center space-x-1"
              onClick={() => this.setState({ isDrawerOpen: true })}
            >
              <span className="sr-only sm:not-sr-only font-medium text-base text-gray-700">
                Filter
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="max-w-7xl mx-auto bg-white md:rounded-t-xl shadow-md min-h-screen">
          {!this.state.isConverted && (
            <div>
              {this.state.totalWords > 0 ? (
                <div className="flex flex-col">
                  <Progress
                    value={this.state.processingProgress * 100}
                    colorScheme="yellow"
                  />
                </div>
              ) : (
                <div className="flex flex-row justify-center items-center pt-6 space-x-2">
                  <Spinner size="sm" />
                  <label className="text-sm font-medium text-gray-700">
                    Initializing Text Processing...
                  </label>
                </div>
              )}
            </div>
          )}

          <div className="grid lg:grid-cols-2">
            {this.state.isNotification && (
              <Notification
                title={this.state.notificationTitle}
                description={this.state.notificationDescription}
                status={this.state.notificationStatus}
                onMount={() => this.setState({ isNotification: false })}
                position="bottom"
              />
            )}

            <Drawer
              isOpen={this.state.isDrawerOpen}
              placement="right"
              onClose={() => this.setState({ isDrawerOpen: false })}
              size="md"
            >
              <DrawerOverlay />
              <DrawerContent className="px-4 pt-2" bg="white">
                <DrawerCloseButton />
                <DrawerHeader>
                  <div className="flex flex-row items-center">
                    Filter Words
                    <span className="inline-flex items-center mx-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {this.state.allWordsLength}
                    </span>
                  </div>
                </DrawerHeader>

                <DrawerBody className="space-y-2">
                  <div className="flex flex-col space-y-2">
                    <label className="mt-2 text-md font-medium text-gray-900">
                      Word Difficulty
                    </label>
                    <div className="bg-gray-100 px-8 pt-4 pb-3 rounded-lg">
                      <Slider
                        id="slider"
                        defaultValue={this.state.difficultyValue}
                        min={0}
                        max={1}
                        onChange={(v) => {
                          this.setState({ difficultyValue: v });
                          this.updateFilter();
                        }}
                        step={0.02}
                        colorScheme="yellow"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={5} />
                      </Slider>
                      <div className="flex flex-row justify-between -mx-3">
                        <label className="text-xs font-medium text-gray-700">
                          Low
                        </label>
                        <label className="text-xs font-medium text-gray-700">
                          High
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="mt-2 text-md font-medium text-gray-900">
                      Frequency on the Web
                    </label>
                    <div className="bg-gray-100 px-8 pt-4 pb-3 rounded-lg">
                      {/* TODO: Add highest difficutly (word frequency) to text upload information and use to set max value */}

                      <RangeSlider
                        aria-label={["min", "max"]}
                        colorScheme="yellow"
                        defaultValue={[
                          this.state.globalFrequencyRangeStart,
                          this.state.globalFrequencyRangeEnd,
                        ]}
                        min={-50000}
                        max={0}
                        onChangeEnd={(value) => {
                          this.setState({
                            globalFrequencyRangeStart: value[0],
                            globalFrequencyRangeEnd: value[1],
                          });
                          this.updateFilter();
                        }}
                        isDisabled={this.state.textLanguage !== "en"}
                      >
                        <RangeSliderTrack>
                          <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb boxSize={5} index={0} />
                        <RangeSliderThumb boxSize={5} index={1} />
                      </RangeSlider>
                      <div className="flex flex-row justify-between -mx-3">
                        <label className="text-xs font-medium text-gray-700">
                          High
                        </label>
                        <label className="text-xs font-medium text-gray-700">
                          Low
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="mt-4 text-md font-medium text-gray-900">
                      Frequency in Text
                    </label>
                    <div className="bg-gray-100 px-8 pt-4 pb-3 rounded-lg">
                      {/* TODO: Add highest frequency to text upload information and use to set max value */}
                      <RangeSlider
                        aria-label={["min", "max"]}
                        colorScheme="yellow"
                        defaultValue={[
                          this.state.frequencyRangeStart,
                          this.state.frequencyRangeEnd,
                        ]}
                        min={0}
                        max={20}
                        onChangeEnd={(value) => {
                          this.setState({
                            frequencyRangeStart: value[0],
                            frequencyRangeEnd: value[1],
                          });
                          this.updateFilter();
                        }}
                      >
                        <RangeSliderTrack>
                          <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb boxSize={5} index={0} />
                        <RangeSliderThumb boxSize={5} index={1} />
                      </RangeSlider>
                      <div className="flex flex-row justify-between -mx-3">
                        <label className="text-xs font-medium text-gray-700">
                          Low
                        </label>
                        <label className="text-xs font-medium text-gray-700">
                          High
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="mt-4 text-md font-medium text-gray-900">
                      Vocabulary
                    </label>
                    <div className="bg-gray-100 p-4 rounded-lg space-y-2">
                      {/* <Checkbox
                        isChecked={this.state.isRemoveExistingWords}
                        onChange={() => {
                          this.setState((prevState) => ({
                            isRemoveExistingWords:
                              !prevState.isRemoveExistingWords,
                          }));
                          this.updateFilter(
                            undefined,
                            !this.state.isRemoveExistingWords
                          );
                        }}
                        colorScheme="yellow"
                      >
                        Remove words I know
                      </Checkbox> */}
                      <Checkbox
                        isChecked={this.state.isRemoveOutsideDictionary}
                        onChange={() => {
                          this.setState((prevState) => ({
                            isRemoveOutsideDictionary:
                              !prevState.isRemoveOutsideDictionary,
                          }));
                          this.updateFilter(
                            undefined,
                            undefined,
                            !this.state.isRemoveOutsideDictionary
                          );
                        }}
                        colorScheme="yellow"
                        isDisabled={this.state.textLanguage !== "en"}
                      >
                        Remove words not in the dictionary
                      </Checkbox>
                      {/*                                             <Checkbox
                                                isChecked={false}
                                                onChange={() => console.log("")}
                                                colorScheme="yellow"
                                                isDisabled
                                            >
                                                Remove synonyms of words I know
                                            </Checkbox> */}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="mt-4 text-md font-medium text-gray-900">
                      Part-of-speech
                    </label>
                    <div className="bg-gray-100 p-5 rounded-lg grid grid-rows-3 grid-flow-col gap-4">
                      {posOptions.map((option) => (
                        <Checkbox
                          key={option}
                          isChecked={this.state[option]}
                          onChange={() => this.updatePosFilter(option)}
                          colorScheme="yellow"
                        >
                          {option}
                        </Checkbox>
                      ))}
                    </div>
                  </div>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <div>
              {!this.state.isConverted && this.state.totalWords > 0 && (
                <div className="flex justify-center lg:justify-start lg:ml-8 lg:mt-2 lg:-mb-2">
                  <label className="pt-2 text-sm font-medium text-gray-700">
                    Retrieved {this.state.totalWords} unique words (
                    {(this.state.processingProgress * 100).toFixed()}%)
                  </label>
                </div>
              )}

              {this.state.isConverted && this.state.isLoading && (
                <p className="p-12">Loading words...</p>
              )}

              {this.state.activeList === "all" && (
                <WordList
                  allWordsLength={this.state.allWordsLength}
                  totalWords={this.state.totalWords}
                  isEmpty={this.state.isEmpty && this.state.isConverted}
                  allWords={this.state.allWords}
                  activeWordKey={this.state.activeWordKey}
                  onUpdateActiveWord={(value) => this.updateActiveWord(value)}
                />
              )}

              {this.state.activeList === "converted" && (
                <div className="overflow-auto h-80 lg:h-screen">
                  {this.state.convertedWords.length > 0 ? (
                    <ul className="grid  gap-3 sm:gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 xl:grid-cols-4 m-8">
                      {this.state.convertedWords.map((e) => (
                        <WordItem
                          id={e.word_pos_key}
                          wordDisplay={e.word_display}
                          wordPos={e.pos}
                          activeWordKey={null}
                          onWordSelect={() =>
                            console.log("word already added/removed")
                          }
                        />
                      ))}
                    </ul>
                  ) : (
                    <div className="m-6">
                      <h2 className="text-sm font-medium text-gray-900 ml-1 mb-2.5">
                        No words added from this text
                      </h2>
                    </div>
                  )}
                  {this.state.wordsAddedGlobal.length > 0 && (
                    <div className="mx-4 mt-4 bg-gray-100 p-4 rounded-md">
                      <h2 className="text-sm font-medium text-gray-900 ml-1 mb-2.5">
                        Added from other texts
                      </h2>
                      <ul className="grid  gap-3 sm:gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 xl:grid-cols-4">
                        {this.state.wordsAddedGlobal.map((e) => (
                          <WordItem
                            id={e.word_pos_key}
                            wordDisplay={e.word_display}
                            wordPos={e.pos}
                            activeWordKey={null}
                            onWordSelect={() =>
                              console.log("word already added/removed")
                            }
                          />
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {this.state.activeList === "removed" && (
                <div className="overflow-auto h-80 lg:h-screen">
                  {this.state.wordsKnownManually.length > 0 && (
                    <ul className="grid  gap-3 sm:gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 xl:grid-cols-4 mx-8 mt-8">
                      {this.state.wordsKnownManually.map((e) => (
                        <WordItem
                          id={e.word_pos_key}
                          wordDisplay={e.word_display}
                          wordPos={e.pos}
                          activeWordKey={null}
                          onWordSelect={() =>
                            console.log("word already added/removed")
                          }
                        />
                      ))}
                    </ul>
                  )}

                  {this.state.wordsKnownEstimated.length > 0 && (
                    <ul className="grid  gap-3 sm:gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-9 m-8">
                      {this.state.wordsKnownEstimated.map((e) => (
                        <WordItem
                          id={e.word_pos_key}
                          wordDisplay={e.word_display}
                          wordPos={e.pos}
                          activeWordKey={null}
                          onWordSelect={() =>
                            console.log("word already added/removed")
                          }
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row justify-center border-t-2 lg:border-l-2 lg:border-t-0">
              {this.state.activeWordKey ? (
                <div className="max-w-3xl flex-grow overflow-hidden space-y-4 mt-6 px-8">
                  <TextViewAdd
                    onSuccessfulCardSubmit={this.handleCardSubmit}
                    onWordsRemove={this.handleWordRemove}
                    deckId={this.props.match.params.deckId}
                    activeWordInfo={this.state.activeWordInfo}
                    token={this.props.token}
                  />
                </div>
              ) : (
                <div className="max-w-3xl flex justify-center flex-grow overflow-hidden mt-12 font-medium">
                  {this.state.dictionaryLoading ? (
                    <p className="bg-red-100 px-4 -my-1">
                      Woop. This seems to be a long text. Please give us a
                      minute while the additional word info is loading...
                    </p>
                  ) : (
                    <>
                      {!this.state.isEmpty && (
                        <p className="bg-yellow-100 px-4 -my-1 lg:max-h-5">
                          Select A Word To Get Started
                        </p>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            {/* <div>
                    {this.state.totalWords} words left!
                    <p>{this.props.match.params.textId}, {this.props.match.params.deckId}, {this.props.match.params.cardTypeId}</p>
                </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextView);
