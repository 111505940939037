import React from "react";
import { storage } from "../../../firebase.utils";
import { v4 as uuidv4 } from "uuid";
import Notification from "../notification.component";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

class FileUploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileCurrently: false,
      fileUploaded: false,

      // notifications
      isNotification: false,
      notificationTitle: "",
      notificationDescription: "",
      notificationStatus: "",
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.size > 10000000) {
      return window.alert("Please upload a file smaller than 10MB.");
    }

    const fileType = file.type.split("/")[1];
    const fileNameOriginal = file.name;

    const uuid = uuidv4();
    const fileName = fileNameOriginal
      .slice(0, file.name.length - 4)
      .replace(/\W/g, " ");

    const mainFolder = this.props.mainFolder;

    const storageRef = ref(storage, `${mainFolder}/${uuid}/${fileName}`);

    this.setState({
      fileCurrently: true,
      fileUploaded: false,
      isNotification: true,
      notificationTitle: "Uploading file...",
      notificationDescription: "",
      notificationStatus: "info",
    });

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        window.alert("Unable to process the file. Please try another file.");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.setState({
            fileCurrently: false,
            fileUploaded: true,
            isNotification: true,
            notificationTitle: "File uploaded successfully!",
            notificationDescription: "",
            notificationStatus: "success",
          });
          this.props.onFileUpload(
            downloadURL,
            fileName,
            fileNameOriginal,
            fileType
          );
        });
      }
    );
  };

  render() {
    let buttonText;

    if (this.state.fileCurrently) {
      buttonText = <span>Uploading file...</span>;
    } else if (this.state.fileUploaded) {
      buttonText = <span>Click to change upload.</span>;
    } else {
      buttonText = <span>Or Upload A Text</span>;
    }

    return (
      <div>
        {this.state.isNotification && (
          <Notification
            title={this.state.notificationTitle}
            description={this.state.notificationDescription}
            status={this.state.notificationStatus}
            onMount={() => this.setState({ isNotification: false })}
            position="top"
            duration={3000}
          />
        )}
        <label
          for="file-upload"
          className="relative cursor-pointer inline-flex items-center px-4 py-2 border-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-300"
        >
          {buttonText}
          <input
            id="file-upload"
            onChange={this.handleFileUpload}
            type="file"
            className="sr-only"
            accept=".txt,.pdf"
          />
        </label>
      </div>
    );
  }
}

export default FileUploadInput;
