import { useState } from "react";
import en from "../../../assets/en.png";
import earth from "../../../assets/earth.png";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from "@chakra-ui/react";

const SelectItem = ({ src, name, onAction }) => (
  <button
    type="button"
    className="flex items-center space-x-2 hover:bg-gray-50 py-1.5"
    onClick={onAction}
  >
    <img className="h-6" src={src} alt={name} />
    <span className="text-gray-900 font-medium">{name}</span>
  </button>
);

export default function EmailSignUp({ onWaitlist }) {
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState("en");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selected === "en") {
      window.location.href = `https://app.vocab.so/sign-up?email=${email}&language=en`;
    } else {
      fetch(
        `https://hooks.zapier.com/hooks/catch/9899583/ojxnu23?email=${email}`,
        { method: "POST", redirect: "follow" }
      )
        .then((response) => response.json())
        .then((data) => {
          onWaitlist();
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <form
      onSubmit={(event) => handleSubmit(event)}
      className="px-6 sm:px-0 sm:flex"
      action="#"
      method="POST"
    >
      <div className="flex">
        <Popover autoFocus={false} direction="bottom" placement="bottom-start">
          <PopoverTrigger>
            <button
              type="button"
              className="w-24 py-1.5 px-0.5 bg-yellow-50 mr-2.5 border-2 ring-1 border-gray-600 ring-gray-700 rounded-md flex flex-row items-center justify-center space-x-1"
            >
              {selected === "en" ? (
                <img className="h-7" src={en} alt="English" />
              ) : (
                <img className="h-7" src={earth} alt="English" />
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <div className="flex flex-col">
                <SelectItem
                  src={en}
                  name="English"
                  onAction={() => setSelected("en")}
                />
                <SelectItem
                  src={earth}
                  name="Other"
                  onAction={() => setSelected("other")}
                />
              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <label htmlFor="hero_email" className="sr-only">
          Email address
        </label>
        <input
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          id="hero_email"
          name="hero_email"
          type="email"
          autocomplete="email"
          placeholder="Email address"
          className={`bg-opacity-95 block shadow-md w-full bg-yellow-50 border-2 ring-1 border-gray-600 ring-gray-700 rounded-md pl-5 py-3 text-base text-gray-900 placeholder-gray-500 ${
            selected === "en"
              ? "focus:border-red-300 focus:ring-red-300"
              : "focus:border-purple-300 focus:ring-purple-300"
          }`}
          required
        />
      </div>
      <button
        type="submit"
        className={`bg-opacity-95 border mt-3 w-full flex items-center justify-center px-5 py-3 border-transparent shadow-md text-base font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0 ${
          selected === "en"
            ? "bg-red-400 bg-transparent hover:bg-red-400 focus:ring-offset-red-400"
            : "bg-purple-400 bg-transparent hover:bg-purple-400 focus:ring-offset-purple-400"
        }`}
      >
        {selected === "en" ? "Start Learning" : "Join Waitlist"}
      </button>
    </form>
  );
}
