import { Highlight, Blockquote } from "@mantine/core";
import Report from "./report.component";
import VotableDefinitions from "./votable-definitions.component";

export default function WordInfoContent({ wordInfo, movieTitle }) {
  const headingStyles = "font-medium text-base";
  return (
    <div className="space-y-3">
      <p className="italic -mt-2">{wordInfo.pos}</p>
      <div className="space-y-1">
        <h2 className={headingStyles}>Definitions</h2>
        <VotableDefinitions word={wordInfo.word_root} pos={wordInfo.pos} />
      </div>
      <div className="space-y-1">
        <h2 className={headingStyles}>Appearances</h2>
        <div className="flex flex-row flex-wrap">
          {/* TODO: i guess i should do this stuff on the backend, not here */}
          {wordInfo.original_words_list.slice(0, 5).map((e) => {
            const wordIndex = e.sent.indexOf(e.word_original);
            const exampleSentArray = e.sent
              .slice(
                Math.max(wordIndex - 75, 0),
                Math.min(wordIndex + 75, e.sent.length)
              )
              .split(" ");
            const exampleSent =
              e.sent.length < 150
                ? e.sent
                : (wordIndex - 75 > 0 ? "..." : "") +
                  exampleSentArray
                    .slice(1, exampleSentArray.length - 1)
                    .join(" ") +
                  (e.sent.length > wordIndex + 75 ? "..." : "");
            return (
              <Blockquote
                cite={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/search?q=${movieTitle
                      .split(" ")
                      .join("+")}`}
                  >
                    - {movieTitle}
                  </a>
                }
              >
                <Highlight highlight={e.word_original}>{exampleSent}</Highlight>
              </Blockquote>
            );
          })}
        </div>
        <div className="flex justify-end">
          <Report wordInfo={wordInfo} movieTitle={movieTitle} />
        </div>
      </div>
    </div>
  );
}
