import React from "react";
import Emoji from "../../../universal-components/emoji.component";
import OnboardingListItem from "./onboarding-list-item.component";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
} from "@chakra-ui/react";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedLanguage: false,
      isWordAdded: false,
      accordionToggle: false,
    };
  }

  render() {
    let isWordAdded;
    for (let i = 0; i < this.props.currentDecks.length; i++) {
      if (this.props.currentDecks[i]["word_count"] > 0) {
        isWordAdded = true;
        break;
      }
    }

    return (
      <div className="mt-8 text-gray-900 text-md leading-7 space-y-2">
        {/* TODO Get a real first name/nickname from users */}
        {/* TODO Add start learning todo */}

        <Accordion allowToggle defaultIndex={1}>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>Getting Started </b>
                <Emoji symbol="👋" label="cap" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <p>
                Fantastic seeing you checking out Vocab! Did we tell you{" "}
                <b>what you can do</b> around here yet?
              </p>
              <div>
                <p>
                  Vocab helps you <b>learn new words</b> you encounter while
                  reading.
                </p>
                <ul className="list-disc ml-7">
                  <li>
                    Upload a new text <Emoji symbol="📚" label="cap" />
                  </li>
                  <li>
                    Automatically filter words based on your existing vocabulary{" "}
                    <Emoji symbol="🔑" label="cap" />
                  </li>
                  <li>
                    Get suggestions for translations and definitions{" "}
                    <Emoji symbol="💡" label="cap" />
                  </li>
                  <li>
                    Learn with custom flashcards and automatic quizzes{" "}
                    <Emoji symbol="⭐️" label="cap" />
                  </li>
                </ul>
              </div>
              <div className="pt-4 space-y-2">
                <p>
                  Here are your <b>first steps to get started:</b>
                </p>
                <div className="ml-1 space-y-2">
                  <Link to="/app/deck/add">
                    <OnboardingListItem
                      key={1}
                      isCompleted={this.props.currentDecks.length > 0}
                      text="Add your first deck"
                    />
                  </Link>
                  <Link to="/app/upload/settings">
                    <OnboardingListItem
                      key={2}
                      isCompleted={this.props.currentUploads.length > 0}
                      text="Upload your first text"
                    />
                  </Link>
                  <Link to="/app/texts">
                    <OnboardingListItem
                      key={3}
                      isCompleted={isWordAdded}
                      text="Add your first word"
                    />
                  </Link>
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <b>
                  Community and Roadmap <Emoji label="chat" symbol="💬" />{" "}
                </b>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <div className="flex flex-col space-y-2 text-base font-medium">
                <p>
                  As a beta user, you are steering the ship! To participate in
                  the development process,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.gg/CUfjTVCXsC"
                    className="inline text-indigo-700"
                  >
                    {" "}
                    join our community chat on Discord.
                  </a>
                </p>
                {/* <p>
                  1)
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.gg/CUfjTVCXsC"
                    className="inline text-indigo-700"
                  >
                    {" "}
                    join our community chat on Discord
                  </a>
                </p>
                <p>
                  2) request and vote on new features using the{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://vocab.nolt.io/"
                    className="inline text-yellow-700"
                  >
                    roadmap.
                  </a>
                </p> */}
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}

export default Onboarding;
