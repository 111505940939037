import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MultiOptionField from "./multi-option-field.component";

export default function Synonyms({
  wordInput,
  sourceLanguage,
  wordPos,
  onSynonymChange,
}) {
  const [synonyms, setSynonyms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/synonym?word_original=${wordInput}&source=${sourceLanguage}&pos=${wordPos}&word_root=${wordInput}`,
      { method: "GET", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        setSynonyms(data.synonyms);
        setIsLoading(false);
      })
      .catch((error) => window.alert(error));
  }, []);
  return (
    <div className="flex flex-row flex-wrap items-center">
      {isLoading ? (
        <div className="pb-2 mr-2">
          <Spinner size="sm" />
        </div>
      ) : (
        <MultiOptionField
          isSuggestions
          suggestions={synonyms}
          onChange={(value) => onSynonymChange(value)}
          placeholderText="Add a synonym"
        />
      )}
    </div>
  );
}
