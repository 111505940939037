import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  CheckboxGroup,
  Checkbox,
  Stack,
  useDisclosure,
  IconButton,
  Input
} from "@chakra-ui/react";
import { useState } from "react";

const options = [
  { value: 1, name: "This word is not that difficult!" },
  { value: 2, name: "This word is much more difficult!" },
  { value: 3, name: "The part-of-speech tag (e.g., NOUN) is incorrect!" },
  { value: 4, name: "The definitions suck." },
  { value: 5, name: "There's something wrong with the example sentences." },
  { value: 6, name: "Something else..." },
];

export default function Report({ wordInfo, movieTitle }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [flagged, setFlagged] = useState(null);
  const [isAlert, setIsAlert] = useState(false)
  const [input, setInput] = useState("")

  const onSubmit = () => {
    if (!flagged) {
      setIsAlert(true);
    } else {
        fetch(
          `https://hooks.zapier.com/hooks/catch/9899583/b8edbk7?flag=${flagged}&message=${input}&movieTitle=${movieTitle}&word=${wordInfo.word_root}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            onClose();
          })
          .catch((error) => {
            window.alert(error);
          });

    }
  };
  
  return (
    <>
      <Button
        leftIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
            />
          </svg>
        }
        variant="ghost"
        onClick={onOpen}
      >
        <p className="text-sm">Report</p>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="py-4 space-y-3">
              {options.map((o) => (
                <Checkbox
                  value={o.value}
                  size="lg"
                  isChecked={o.name === flagged}
                  onChange={() => setFlagged(o.name)}
                >
                  {o.name}
                </Checkbox>
              ))}
            </div>
            {flagged === "Something else..." && (
              <Input
                placeholder="Please specify the problem here"
                onChange={(event) => setInput(event.target.value)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={onSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
