import { Spinner } from "@chakra-ui/react";
import { Highlight } from "@mantine/core";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import ClozeInput from "../../components/app-page/learn/quiz-components/cloze-components/cloze-input.component";
import { useRouter } from "../../hooks/useRouter";
import { editDistance } from "../../utils";
import CountdownBar from "./countdown-bar.component";
import QuizSummary from "./quiz-summary.component";

const targetLangs = [
  ["BG", "Bulgarian"],
  ["CS", "Czech"],
  ["DA", "Danish"],
  ["DE", "German"],
  ["EL", "Greek"],
  ["ES", "Spanish"],
  ["ET", "Estonian"],
  ["FI", "Finnish"],
  ["FR", "French"],
  ["HU", "Hungarian"],
  ["ID", "Indonesian"],
  ["IT", "Italian"],
  ["JA", "Japanese"],
  ["LT", "Lithuanian"],
  ["LV", "Latvian"],
  ["NL", "Dutch"],
  ["PL", "Polish"],
  [
    "PT-PT",
    "Portuguese (all Portuguese varieties excluding Brazilian Portuguese)",
  ],
  ["PT-BR", "Portuguese (Brazilian)"],
  ["RO", "Romanian"],
  ["RU", "Russian"],
  ["SK", "Slovak"],
  ["SL", "Slovenian"],
  ["SV", "Swedish"],
  ["TR", "Turkish"],
  ["ZH", "Chinese"],
];

export default function ProgrammaticQuiz() {
  const router = useRouter();

  const [index, setIndex] = useState(0);
  const [quiz, setQuiz] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(true);
  const [quizHistory, setQuizHistory] = useState([]);
  const [language, setLanguage] = useState(null);

  const [name, setName] = useState("");

  useEffect(() => {
    getQuiz();
  }, []);

  const getQuiz = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/quiz?admin_id=${parseInt(
        process.env.REACT_APP_ADMIN_API_ID
      )}&deck_id=${router.match.params.quizId}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setQuiz(data.quiz);
        setName(data.name)
        setLoading(false);
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const handleAction = (clozeInput) => {
    const answer = quiz[index]["word_info"]["word_original"];
    let inputIs = null;
    console.log(clozeInput);
    if (clozeInput.toLowerCase() === answer.toLowerCase()) {
      inputIs = true;
    } else if (
      editDistance(clozeInput.toLowerCase(), answer.toLowerCase()) <= 1
    ) {
      inputIs = true;
    } else if (clozeInput.toLowerCase() === answer.toLowerCase()) {
      inputIs = false;
    } else if (
      editDistance(clozeInput.toLowerCase(), answer.toLowerCase()) <= 1
    ) {
      inputIs = false;
    } else {
      inputIs = false;
    }
    setIsCorrect(inputIs);
    setQuizHistory((prev) => [...prev, [answer, inputIs]]);
    setIsSubmitted(true);
    setTimeout(() => next(), 1500);
  };

  const next = () => {
    setIsSubmitted(false);
    setIndex((prev) => prev + 1);
  };

  if (loading) {
    return (
      <div className="bg-yellow-50 min-h-screen flex justify-center items-center">
        {" "}
        <Spinner
          thickness="4px"
          speed="0.7s"
          emptyColor="white"
          color="gray.500"
          size="lg"
        />
      </div>
    );
  }
  return (
    <div className="bg-yellow-50 min-h-screen">
      <div className="px-6 sm:px-8 md:p-6 mx-auto max-w-3xl">
        <h1 className="text-xl font-bold py-4">
          {name ? name : router.match.params.quizName.split("-").join(" ")}
        </h1>
        {language ? (
          <div>
            {quiz.length > index && (
              <>
                <div className="flex flex-col ">
                  <p className="flex justify-end text-sm font-semibold mb-1">
                    {index + 1} / {quiz.length}
                  </p>

                  <CountdownBar
                    key={index}
                    onZero={() => handleAction("")}
                    isStopped={isSubmitted}
                  />
                </div>

                <ClozeInput
                  preClozeWord={quiz[index]["word_info"]["example_pre_word"]}
                  postClozeWord={quiz[index]["word_info"]["example_post_word"]}
                  clozeWord={quiz[index]["word_info"]["word_original"]}
                  isSubmitted={isSubmitted}
                  isCorrect={isCorrect}
                  onSubmit={(value) => handleAction(value)}
                  isMC
                  choices={quiz[index]["other_words"]}
                >
                  <div className="px-4 pt-4">
                    {quiz[index]["translations"]["translations"][language][
                      "word"
                    ] && (
                      <p className="text-lg font-bold">
                        {
                          quiz[index]["translations"]["translations"][language][
                            "word"
                          ]
                        }
                      </p>
                    )}
                    <p className="text-md inline">
                      <Highlight
                        highlight={
                          quiz[index]["translations"]["translations"][language][
                            "word"
                          ]
                        }
                      >
                        {
                          quiz[index]["translations"]["translations"][language][
                            "sentence"
                          ]
                        }
                      </Highlight>
                    </p>
                  </div>
                </ClozeInput>
              </>
            )}
            {quiz.length === index && (
              <>
                <QuizSummary quizHistory={quizHistory} />
                <div className="flex justify-center">
                  <Link
                    to="/quizzes"
                    className="bg-yellow-400 text-gray-900 rounded-md px-4 py-2 my-8 font-medium"
                  >
                    Take another quiz
                  </Link>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="text-lg">
            <h2>Select your native language to get started</h2>
            <div className="flex flex-col space-y-1 items-start mt-4">
              {targetLangs.map((e) => (
                <button
                  className="flex flex-row items-center space-x-2"
                  onClick={() => setLanguage(e[0].toLowerCase())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>{e[1]}</span>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
