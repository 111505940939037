import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SingleVotableDefinition from "./single-votable-definition.component";

export default function VotableDefinitions({ word, pos }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/definition?word_original=${word}&word_root=${word}&pos=${pos}`,
      { method: "GET", redirect: "follow" }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  return (
    <div>
      {isLoaded ? (
        <>
          {items.length === 0 ? (
            <div>No definitions found.</div>
          ) : (
            <>
              {items.slice(0, 5).map((e, i) => (
                <SingleVotableDefinition
                  text={e.text}
                  index={i}
                  source={e.source}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <div className="pb-2 mr-2">
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
}