import React from "react";
import DeckList from "./decks-section-components/deck-list.component";
import SectionHeading from "../../../pages/app/app-page-components/section-heading.component";

const MyDecks = (props) => (
  <div>
    <SectionHeading
      headingText="Select A Deck"
      isButton={true}
      buttonText="Add New"
      buttonLink="/app/deck/add"
    />
    <DeckList isLoaded={props.isLoaded} />
  </div>
);

export default MyDecks;