import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { useRouter } from "../../../hooks/useRouter";

function SingleLearningButton({
  onAction,
  name,
  benefits,
  icon,
  colorScheme = "gray",
}) {
  const [hover, setHover] = useState(false);
  return (
    <div className="flex flex-col">
      <Button
        className="space-x-2 py-6"
        colorScheme={colorScheme}
        onClick={onAction}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {icon}
        <span>{name}</span>
      </Button>
      {hover && (
        <div className="mt-2 ml-1.5">
          {benefits.map((e) => (
            <div className="flex flex-row items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>{" "}
              <p className="text-sm font-medium">{e}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function StartLearning(props) {
  const router = useRouter();

  return (
    <div className="mx-auto">
      <div className="mt-2 grid grid-cols-3 gap-3 grid-flow-row-dense">
        {props.deckLang === "en" && (
          <SingleLearningButton
            key="quiz"
            onAction={() =>
              router.push(`/app/session/deck/${props.deckId}/-/quiz/5`)
            }
            name={"Quiz"}
            benefits={[
              "Start learning in one click",
              "Exercises are created automatically",
            ]}
            colorScheme="yellow"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                />
              </svg>
            }
          />
        )}
        <SingleLearningButton
          key="flashcards"
          onAction={() =>
            router.push(`/app/session/deck/${props.deckId}/-/flashcards/10`)
          }
          name={"Flashcards"}
          benefits={[
            "Customize how you want to learn",
            "Exercises are created from added fields",
          ]}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              />
            </svg>
          }
        />
        <SingleLearningButton
          key="text-view"
          onAction={() => router.push(`/app/text-view/deck/${props.deckId}/-/`)}
          name={"Word List"}
          benefits={[
            "Go through all words appearing in the texts",
            "Filter out the words you want to learn first",
          ]}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
}
