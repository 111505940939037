import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";
import Notification from "../universal-components/notification.component";

class AddWord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      wordInput: this.props.wordInfo.word_root,
      translationInput: this.props.wordInfo.translations.join(", "),
      definitionInput: "",
      exampleInput: this.props.wordInfo.sent,

      // notifications
      isNotification: false,
      notificationTitle: "",
      notificationDescription: "",
      notificationStatus: "",
    };
  }

  componentDidMount() {
    this.setState({ isOpen: true });
  }

  onOpen = () => {
    this.setState({ isOpen: true });
  };

  onSave = () => {
    this.setState({ isOpen: false });

    this.setState({
      isNotification: true,
      notificationTitle: "Can't save",
      notificationDescription: "Only a prototype!",
      notificationStatus: "error",
    });

    this.props.onClose();
  };

  onClose = () => {
    this.setState({ isOpen: false });

    this.props.onClose();
  };

  render() {
    return (
      <div>
        {this.state.isNotification && (
          <Notification
            title={this.state.notificationTitle}
            description={this.state.notificationDescription}
            status={this.state.notificationStatus}
            onMount={() => this.setState({ isNotification: false })}
            position="bottom"
          />
        )}

        <Modal
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          size="xl"
          returnFocusOnClose={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Word</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="space-y-4">
                {/* TODO: think about making "editable" word info "word_display" and showing "word_display" everywhere instead of root  */}
                <div className="space-y-1 ml-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Word
                  </label>
                  <Input
                    variant="filled"
                    value={this.state.wordInput}
                    onChange={(event) =>
                      this.setState({ wordInput: event.target.value })
                    }
                    isDisabled
                  />
                </div>
                <div className="space-y-1 ml-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Translation
                  </label>
                  <Input
                    variant="filled"
                    value={this.state.translationInput}
                    onChange={(event) =>
                      this.setState({ translationInput: event.target.value })
                    }
                  />
                </div>
                <div className="space-y-1 ml-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Definition
                  </label>
                  <Input
                    variant="filled"
                    value={this.state.definitionInput}
                    onChange={(event) =>
                      this.setState({ definitionInput: event.target.value })
                    }
                  />
                </div>
                <div className="space-y-1 ml-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Example
                  </label>
                  <Textarea
                    variant="filled"
                    value={this.state.exampleInput}
                    onChange={(event) =>
                      this.setState({ exampleInput: event.target.value })
                    }
                  />
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="gray" mr={3} onClick={this.onSave}>
                Save
              </Button>
              <Button variant="ghost" onClick={this.onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

export default AddWord;
