import React from "react"
import WordInput from "../../components/universal-components/form-inputs/word-input.component"
import ReadingPage from "./reading-page.component";


class Konvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textInput: "",

            // conditional rendering
            isSubmitted: false,

            // fetch
            allSentenceInfos: [],
            allNewWords: [],
        }
    }

    assertSubmit = () => {
        if (this.state.textInput !== "") {
            this.handleSubmit()
        } else {
            window.alert("Enter Text")
        }
    }

    handleSubmit = () => {
        const textInput = this.state.textInput

        fetch(`${process.env.REACT_APP_API_URL}/konvey/sentences?text=${textInput}`, { method: "GET", redirect: "follow" })
            .then(response => response.json())
            .then(data => {
                this.setState({ isSubmitted: true })
                this.renderSentences(data)
            })
            .catch(error => window.alert(error))
    }

    async renderSentences (data) {
        const sentenceList = data.sentence_list
        const entityList = data.entities
        let i
        let allSentenceInfos = []
        let allNewWords = []
        for (i = 0; i < sentenceList.length; i++) {
            const currentSentence = sentenceList[i]
            const sentenceText = currentSentence.text
            const sentenceId = currentSentence.id
            await fetch(`${process.env.REACT_APP_API_URL}/konvey/single?sentence=${sentenceText}&entities=${entityList}&id=${sentenceId}`, { method: "GET", redirect: "follow" })
                .then(response => response.json())
                .then(data => {
                    console.log(i, data)

                    allSentenceInfos.push(data)
                    
                    // add new words to additional list
                    if (data.known !== "true") {
                        let j
                        for (j = 0; j < data.new_words.length; j++) {
                            allNewWords.push(data.new_words[j])
                        }
                    }

                    this.setState({ allSentenceInfos: allSentenceInfos, allNewWords: allNewWords })

                })
                .catch(error => console.log(error))
        }
    }

    render() {
        return (
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                { this.state.isLoading && <p>Loading...</p>}

                { this.state.isSubmitted ? (
                    <ReadingPage info={this.state.allSentenceInfos} newWords={this.state.allNewWords}/>
                ) : (
                    <div>
                        <WordInput inputValue={this.state.textInput} onInputChange={(event) => this.setState({ textInput: event.target.value })} textMainHeadline="" textSubHeadline="" textMain="Enter Text" textOptional="" />
                        <button onClick={this.assertSubmit}>Submit</button>
                    </div>
                )


                }
            </div>
        )
    }
}

export default Konvey