export const tagOptions = [
  {
    name: "British English",
    symbol: "🇬🇧",
  },
  {
    name: "American English",
    symbol: "🇺🇸",
  },
  {
    name: "Slang",
    symbol: "🤟",
  },
  {
    name: "Action",
    symbol: "🔥",
  },
  {
    name: "Movie",
    symbol: "🎬",
  },
  {
    name: "Series",
    symbol: "🗂",
  },
  {
    name: "Drama",
    symbol: "🎭",
  },
  {
    name: "Comedy",
    symbol: "😂",
  },
  {
    name: "Musical",
    symbol: "🎤",
  },
  {
    name: "A2",
    symbol: "📗",
  },
  {
    name: "B1",
    symbol: "📘",
  },
  {
    name: "B2",
    symbol: "📙",
  },
  {
    name: "C1",
    symbol: "📕",
  },
  {
    name: "C2",
    symbol: "📓",
  },
];

function setEmojis(inputArray) {
  let newObj = {};
  for (const e of tagOptions) {
    newObj[e.name] = e.symbol;
  }
  return newObj;
}

export const tagEmojis = setEmojis(tagOptions);
