import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MovieCard from "../../../../pages/movie/movie-components/movie-card.component";

export default function LibraryList() {
  const [allMovies, setAllMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const targetLanguage = useSelector((state) => state.decks.target_language);

  useEffect(() => {
    setIsLoading(true);
    getMovies();
  }, []);

  useEffect(() => {
    getMovies();
  }, [targetLanguage]);

  const getMovies = () => {
    fetch(`${process.env.REACT_APP_API_URL}/library-all/movie`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        setAllMovies(data.items.filter(e => e.text_language === targetLanguage));
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      {isLoading ? (
        <div className="pt-8 flex justify-center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="white"
            color="gray.500"
            size="lg"
          />
        </div>
      ) : (
        <div className="grid gap-y-4 sm:gap-y-6 lg:gap-y-8 gap-x-4 xl:gap-x-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          {allMovies.map((e) => (
            <MovieCard
              image={e.image_url}
              title={e.name}
              url_param={"/app/library/" + e.url_name}
              description={e.description}
              tags={e.tags.split(",")}
              wordCount={e.word_count}
              difficulty={e.difficulty}
              language_level={e.language_level}
            />
          ))}
        </div>
      )}
    </div>
  );
}
