import React from "react";
import TopNavigation from "./app-page-components/top-navigation.component";
import Sidebar from "./sidebar.component";

export default function AppScreen(props) {
  return (
    <div className="bg-yellow-50 max-w-full">
      <div className="min-h-screen relative max-w-screen-2xl mx-auto md:p-6">
        {/* Sidebar / Left Area */}
        <div className="hidden md:flex md:w-64 md:flex-col md:absolute md:inset-y-0 my-6">
          <div className="h-16 flex items-center">
            {/* <Link to="/app">
                        <img className="h-8 w-auto" src={logoSmall} alt="vocab logo small" />
                    </Link> */}
          </div>
          <Sidebar />
        </div>

        {/* Main Area */}
        <div className="md:pl-64 flex flex-col bg-opacity-25">
          {/* Top Area - on mobile also includes sidebar items */}
          <TopNavigation />

          {/* Bottom Area -- main area of the app */}
          <main>
            <div className="pb-12">
              <div className="max-w-7xl mx-auto bg-white md:rounded-xl shadow-md px-4 sm:px-6 lg:px-8 py-8">
                {/* The element above specifies consistent padding for all children - do not add additional padding in child components. */}

                {props.children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
