import React from "react";
import {
  Card,
  Text,
  Group,
  Badge,
  createStyles,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { tagEmojis } from "../tagOptions";

const useStyles = createStyles((theme) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

export default function MovieCard({
  image,
  title,
  url_param,
  description,
  tags,
  wordCount,
  difficulty,
  language_level,
}) {
  const { classes } = useStyles();

  const features = tags.map(
    (tag) =>
      tag !== "" && (
        <Badge color="gray" key={tag} leftSection={tagEmojis[tag]}>
          {tag}
        </Badge>
      )
  );

  return (
    <Link to={url_param}>
      <Card
        withBorder
        radius="md"
        p="md"
        className="bg-white cursor-pointer transition duration-500 ease-in-out -200 transform hover:scale-105 shadow-sm"
      >
        <Card.Section className={classes.section + " bg-gray-50"}>
          <Group position="apart" className="pt-4">
            <Text size={title.length > 25 ? "md" : "lg"} weight={500}>
              {title}
            </Text>
            <Text size="sm" color="dimmed">
              {wordCount} Words
            </Text>
          </Group>
        </Card.Section>
        <Text mt="xs" className={classes.label} color="dimmed">
          Tags
        </Text>
        <Group spacing={7} mt={5}>
          <Badge
            color="gray"
            key={language_level}
            leftSection={tagEmojis[language_level]}
          >
            {language_level}
          </Badge>
          {features}
        </Group>
      </Card>
    </Link>
  );
}
