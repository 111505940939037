import { Group, LoadingOverlay, useMantineTheme } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import React, { useState } from "react";
import { storage } from "../../../../firebase.utils";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function UploadIcon({ status, ...props }) {
  if (status.accepted) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
        />
      </svg>
    );
  }

  if (status.rejected) {
    return <p>Cross Circled Icon</p>;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  );
}

function getIconColor(status, theme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][6]
    : status.rejected
    ? theme.colors.red[6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.black;
}

export default function UploadDropzone({ onUpload, isTranslationLanguage }) {
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = (files) => {
    if (!isTranslationLanguage) {
      window.alert("Set a translation language.");
      return;
    }

    const file = files[0];
    const fileType = file.type.split("/")[1];

    const fileNameOriginal = file.name;

    const uuid = uuidv4();
    const fileName = fileNameOriginal
      .slice(0, file.name.length - 4)
      .replace(/\W/g, " ");

    const mainFolder = "vocab-mine";
    const storageRef = ref(storage, `${mainFolder}/${uuid}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setIsLoading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        window.alert("Unable to process the file. Please try another file.");
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          onUpload(downloadURL, fileType);
        });
      }
    );
  };

  return (
    // See results in console after dropping files to Dropzone
    <div>
      <Dropzone
        onDrop={uploadFile}
        onReject={(files) =>
          window.alert(
            "Your file was rejected. Please make sure it is a PDF or TXT file and fulfills the size requirements.",
            files
          )
        }
        maxSize={5 * 1024 ** 2}
        accept={[MIME_TYPES.pdf, ".txt"]}
        multiple={false}
      >
        {(status) => (
          <Group
            position="center"
            spacing="xs"
            style={{ minHeight: 155, pointerEvents: "none" }}
          >
            <UploadIcon
              status={status}
              style={{
                width: 80,
                height: 80,
                color: getIconColor(status, theme),
              }}
            />
            <div className="flex flex-col justify-center items-center">
              <h2 className="font-medium text-lg text-gray-900">
                Upload Custom Text
              </h2>
              <p className="font-normal text-base text-gray-900 text-center">
                Your file should not exceed 5 MB. Accepting PDF/TXT files.
              </p>
            </div>
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ size: "md", color: "gray" }}
            />
          </Group>
        )}
      </Dropzone>
    </div>
  );
}
