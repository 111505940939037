import React from "react";
import DeckListItem from "./deck-list-item.component";
import { connect } from "react-redux";
import LoadingSkeleton from "../../../universal-components/loading-skeleton.component";

const DeckList = (props) => (
  <div>
    {props.isLoaded && props.targetLanguage ? (
      <ul className="grid gap-6 grid-cols-2 lg:grid-cols-3">
        {props.currentDecks.map((deck) => {
          return (
            deck["study_language"] === props.targetLanguage &&
            (!props.filterDue || deck.words_due_count > 0) && (
              <DeckListItem
                key={deck.id}
                name={deck.name}
                word_count={deck.word_count}
                words_due_count={deck.words_due_count}
                words_in_progress_count={deck.words_in_progress_count}
                words_mastered_count={deck.words_mastered_count}
                language={deck.translation_language}
                id={deck.id}
              />
            )
          );
        })}
      </ul>
    ) : (
      <LoadingSkeleton />
    )}
  </div>
);

DeckList.defaultProps = {
  filterDue: false,
};

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  targetLanguage: decks.target_language,
});

export default connect(mapStateToProps)(DeckList);
