import WordItem from "./dynamic-card-configuration-components/word-item.component";

export default function WordList({
  allWordsLength,
  totalWords,
  isEmpty,
  allWords,
  activeWordKey,
  onUpdateActiveWord,
}) {
  return (
    <div className="overflow-auto h-80 lg:h-screen">
      {allWordsLength === 0 ? (
        <div className="flex justify-center mt-12 font-medium px-12">
          {totalWords > 0 &&
            `Displaying ${allWordsLength} of ${totalWords} total processed words. Make more words visible by adjusting filter.`}
          {isEmpty && `No words-to-add remaining in this text.`}
        </div>
      ) : (
        <ul className="grid gap-3 sm:gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 xl:grid-cols-4 m-8">
          {allWords.map((e) => (
            <WordItem
              id={e.word_pos_key}
              wordDisplay={e.word_display}
              wordPos={e.pos}
              activeWordKey={activeWordKey}
              onWordSelect={() => {
                console.log(e);
                onUpdateActiveWord(e);
              }}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
