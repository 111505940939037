import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useRouter } from "../../hooks/useRouter";
import React from "react";
import { analytics } from "../../firebase.utils";
import { logEvent } from "firebase/analytics";

import Onboarding0 from "./onboarding-subpages/onboarding-0.component";
import Onboarding1 from "./onboarding-subpages/onboarding-1.component";
import Onboarding2 from "./onboarding-subpages/onboading-2.component";

export default function Onboarding({ user }) {
  console.log("user", user);
  const router = useRouter();
  const [userApiId, setUserApiId] = useState(null);
  const [textId, setTextId] = useState("");
  const [deckId, setDeckId] = useState("");

  const headers = new Headers({ Authorization: user.accessToken });
  useEffect(() => {
    if (user.uid) {
      fetch(`${process.env.REACT_APP_API_URL}/api-id/${user.uid}`, {
        method: "GET",
        redirect: "follow",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setUserApiId(data);
        })
        .catch((error) => console.log(error));
    }
  }, [user.uid]);

  return (
    <div className="bg-yellow-50 min-h-screen flex justify-center p-4 sm:p-0">
      <Switch>
        <Route exact path="/onboarding">
          <Onboarding0
            user={user}
            onSubmit={() => {
              logEvent(analytics, "onboarding_started");
              router.push("/onboarding/1");
            }}
            userApiId={userApiId}
          />
        </Route>
        <Route path="/onboarding/1">
          <Onboarding1
            userApiId={userApiId}
            token={user.accessToken}
            onSubmit={(textId_, deckId_) => {
              setTextId(textId_);
              setDeckId(deckId_);
              router.push(
                `/onboarding/2?text_id=${textId_}&deck_id=${deckId_}`
              );
            }}
          />
        </Route>
        <Route path="/onboarding/2">
          {/* TODO: case where text has not yet started processing/no words in text yet */}
          <Onboarding2
            token={user.accessToken}
            onSubmit={(mode) => {
              // if page was reloaded
              let deckId_ = deckId;
              let textId_ = textId;
              if (!deckId_) {
                deckId_ = router.query.deck_id;
              }
              if (!textId_) {
                textId_ = router.query.text_id;
              }

              if (mode === "quiz") {
                router.push(`/app/session/text/${deckId_}/${textId_}/quiz/5`);
              } else if (mode === "text-view") {
                router.push(`/app/text-view/text/${deckId_}/${textId_}`);
              } else if (mode === "dashboard") {
                router.push(`/app`);
              } else {
                window.alert("No mode selected.");
              }
            }}
          />
        </Route>
      </Switch>
    </div>
  );
}
