import React, { Component } from "react";
import Script from "react-load-script";

const SDK_URL = "https://cdn.nolt.io/widgets.js";
const JWT = "eyJhbGciOiJ...";

class NoltWidget extends Component {
  handleScriptCreate() {
    console.log("script created");
  }

  handleScriptError() {
    console.log("script error");
  }

  handleScriptLoad() {
    console.log("script loaded");
    const { nolt } = window;

    // Single Sign-On
    nolt("identify", { jwt: JWT });
  }

  render() {
    return (
      <>
        <a
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          data-nolt="modal"
          href={"https://vocab.nolt.io/"}
        >
          Roadmap and Feedback
        </a>

        <Script
          url={SDK_URL}
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </>
    );
  }
}

export default NoltWidget;