import LevelButton from "../../public-converter/public-converter-components/level-button.component";

const languageLevelOptions = [
  {
    name: "B1",
    symbol: "📘",
  },
  {
    name: "B2",
    symbol: "📙",
  },
  {
    name: "C1",
    symbol: "📕",
  },
];

export default function LanguageLevelSelect({ onSelectChange }) {
  return (
    <div>
      {languageLevelOptions.map((e, i) => (
        <LevelButton
          id={i}
          name={e.name}
          emojiSymbol={e.symbol}
          onButtonClick={() => onSelectChange(e.name)}
        />
      ))}
    </div>
  );
}
