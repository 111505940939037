import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "../../../authentication/logout-button.component";
import MobileNavigation from "./mobile-navigation.component";
import NoltWidget from "./nolt-widget.component";

class TopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdown: false,
      resetDropdown: false,
      isMobileOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  onClick = (event) => {
    if (this.state.isDropdown && !this.state.resetDropdown) {
      this.setState({ resetDropdown: true });
    } else if (this.state.isDropdown && this.state.resetDropdown) {
      this.setState({ isDropdown: false, resetDropdown: false });
    }
  };

  render() {
    return (
      <div className="container flex-shrink-0 flex justify-between h-16 max-w-7xl mx-auto">
        {this.state.isMobileOpen && (
          <MobileNavigation
            onMobileMenuClose={() => this.setState({ isMobileOpen: false })}
          />
        )}
        <div className="flex flex-row items-center">
          {/* Menu Sandwich */}
          <button
            onClick={() => this.setState({ isMobileOpen: true })}
            type="button"
            className="md:hidden px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </button>
          <div className="ml-4">
            <Link
              to="/app"
              className="sr-only md:not-sr-only rounded-full text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </Link>
          </div>
        </div>

        {/* Profile Picture */}
        <div className="flex items-center space-x-3 relative mr-2 md:mr-0">
          {/* NOTIFICATIONS */}
          {/* <button
                type="button"
                className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                <span className="sr-only">View notifications</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button> */}
          {/* PROFILE & DROPDOWN */}
          <button
            onClick={() => this.setState({ isDropdown: true })}
            className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 mr-2"
            id="user-menu"
            aria-haspopup="true"
          >
            <span className="sr-only">Open user menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <div
            className={`${
              !this.state.isDropdown && "hidden"
            } z-50 origin-top-right absolute right-0 mt-80 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <Link
              to="/app/profile"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Profile
            </Link>
            <Link
              to="/app/deck/add"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Add New Deck
            </Link>
            {/* <NoltWidget /> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/CUfjTVCXsC"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Join community chat
            </a>
            <Link
              to="/app/word-overview"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Word Overview
            </Link>
            <Link
              to="/onboarding"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Redo onboarding
            </Link>
            <a
              href="mailto: support@vocab.so?subject=Reset password&body=Email connected to my account: "
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Reset password
            </a>
            <LogoutButton isMobile={false} />
          </div>
        </div>
      </div>
    );
  }
}

export default TopNavigation;
