import { useState } from "react";
import { Link } from "react-router-dom";

export default function SwitchMode({ selected, deckId, textId, itemType }) {
  const [open, setOpen] = useState(false);

  const options = [
    {
      displayName: "Quiz",
      name: "quiz",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
          />
        </svg>
      ),
      url: `/app/session/${itemType}/${deckId}/${textId}/quiz/5`,
    },
    {
      displayName: "Cards",
      name: "flashcards",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
      ),
      url: `/app/session/${itemType}/${deckId}/${textId}/flashcards/10`,
    },
    {
      displayName: "List",
      name: "text",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
          />
        </svg>
      ),
      url: `/app/text-view/${itemType}/${deckId}/${textId}`,
    },
  ];

  /* TODO: instead of filtering, disable them and add a info message on hover */
  const optionsFiltered = options.filter(
    (e) =>
      (e.name !== "text" || itemType !== "all") &&
      (e.name !== "flashcards" || itemType !== "text")
  );

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={`text-sm font-medium ${open && "shadow-sm"}`}
    >
      <div
        className={`${
          open && "bg-yellow-200 rounded-t-lg w-24 cursor-pointer"
        }`}
      >
        {optionsFiltered.map(
          (e) =>
            e.name === selected && (
              <div className="hover:bg-yellow-300 p-2 rounded-t-lg flex flex-row items-center">
                <div>{e.icon}</div>
                {open && <span className="ml-2">{e.displayName}</span>}
              </div>
            )
        )}
      </div>
      {open && (
        <div className="z-50 absolute bg-yellow-200 rounded-b-lg shadow-sm">
          <div className="flex flex-col">
            {optionsFiltered.map(
              (e) =>
                e.name !== selected && (
                  <Link
                    to={e.url}
                    className="hover:bg-yellow-300 p-2 rounded-lg flex flex-row  w-24 items-center"
                  >
                    <div>{e.icon}</div>
                    <span className="ml-2">{e.displayName}</span>
                  </Link>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
