import { useState } from "react";
import CardLayoutButton from "../../app-configuration/card-type-configuration/card-type-configuration-components/card-layout-button.component";

const cardTypeFields = [
  { value: "pos", name: "Part of speech", example: "VERB" },
  { value: "word_root", name: "Word", example: "to play" },
  { value: "translation", name: "Translation", example: "spielen" },
  {
    value: "definition",
    name: "Definition",
    example: "activity for enjoyment",
  },
  { value: "example", name: "Example Sentence", example: "She played sports." },
  { value: "tags", name: "Tags", example: "Informal, British, Slang" },
  {
    value: "synonyms",
    name: "Synonyms",
    example: "have fun, mess around, gamble",
  },
  { value: "cloze", name: "Cloze", example: "She ____ sports." },
];

export default function FlashcardSettings({ onSubmit }) {
  const [selectedFront, setSelectedFront] = useState([]);
  const [selectedBack, setSelectedBack] = useState([]);

  return (
    <div className="pt-6">
      <div className="flex flex-col px-8 space-y-4 sm:flex-row sm:space-x-6 sm:px-0 sm:space-y-0 sm:justify-center sm:mb-12">
        <div className="flex flex-col space-y-3">
          <h2 className="text-lg font-medium text-gray-900">Front</h2>
          {cardTypeFields.map((entry) => {
            const selected = selectedFront.includes(entry.value);
            return (
              <CardLayoutButton
                text={entry.name}
                example={entry.example}
                onButtonClick={(event) => {
                  const field = event.target.value;
                  if (selectedFront.includes(field)) {
                    setSelectedFront((prevState) =>
                      prevState.filter((e) => e !== field)
                    );
                  } else {
                    setSelectedFront((prevState) => [...prevState, field]);
                    setSelectedBack((prevState) =>
                      prevState.filter((e) => e !== field)
                    );
                  }
                }}
                selected={selected}
                value={entry.value}
                disabled={selectedBack.includes(entry.value)}
              />
            );
          })}
        </div>
        <div className="flex flex-col space-y-3">
          <h2 className="text-lg font-medium text-gray-900">Back</h2>
          {cardTypeFields
            .filter((entry) => entry.value !== "cloze")
            .map((entry) => {
              const selected = selectedBack.includes(entry.value);
              return (
                <CardLayoutButton
                  text={entry.name}
                  example={entry.example}
                  onButtonClick={(event) => {
                    const field = event.target.value;
                    if (selectedBack.includes(field)) {
                      setSelectedBack((prevState) =>
                        prevState.filter((e) => e !== field)
                      );
                    } else {
                      setSelectedBack((prevState) => [...prevState, field]);
                      setSelectedFront((prevState) =>
                        prevState.filter((e) => e !== field)
                      );
                    }
                  }}
                  selected={selected}
                  value={entry.value}
                  disabled={selectedFront.includes(entry.value)}
                />
              );
            })}
        </div>
      </div>
      <div className="flex justify-center">
        {selectedBack.length > 0 && selectedFront.length > 0 && (
          <button
            value="flashcards"
            onClick={() => {
              onSubmit(selectedFront, selectedBack);
            }}
            className="rounded-md shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm"
          >
            Start Session
          </button>
        )}
      </div>
    </div>
  );
}
