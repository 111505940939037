import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
} from "@chakra-ui/react";
import WordInput from "../universal-components/form-inputs/word-input.component";
import { languages_dict } from "../app-configuration/dropdown-inputs";
import { connect } from "react-redux";
import {
  setCurrentDecks,
  setCurrentTranslationLanguage,
} from "../../redux/decks/decks.actions";
import Notification from "../universal-components/notification.component";

/* TODO: Share subcomponent with AddDeck */
class AddDeckPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ translationLanguage: this.props.translationLanguage });
  }

  assertSubmit = () => {
    if (this.state.deckName === "") {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Enter a name for your deck",
        notificationStatus: "error",
      });
    } else if (
      this.state.defaultCardType === "" ||
      this.state.defaultCardType === "CT"
    ) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Select at least one default card type",
        notificationStatus: "error",
      });
    } else if (
      !this.state.translationLanguage &&
      this.props.targetLanguage === "en"
    ) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Please select a translation language",
        notificationStatus: "error",
      });
    } else if (!this.props.targetLanguage) {
      this.setState({
        isNotification: true,
        notificationTitle: "Missing fields",
        notificationDescription: "Select at least one default card type",
        notificationStatus: "error",
      });
    } else if (
      this.props.currentDecks.map((d) => d.name).includes(this.state.deckName)
    ) {
      window.alert("A deck with this name exists already.");
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const headers = new Headers({ Authorization: this.props.token });
    const name = this.state.deckName;
    const userApiId = this.props.userApiId;
    let translationLanguage;

    if (this.props.targetLanguage === "en") {
      translationLanguage = this.state.translationLanguage;
    } else {
      translationLanguage = "en";
    }

    // TODO: ADD STORY
    if (["list", "of", "the", "users", "decks"].includes(name)) {
      window.alert("This deck name already exists.");
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/deck/000?name=${name}&study_language=${this.props.targetLanguage}&translation_language=${translationLanguage}&user_id=${userApiId}`,
        { method: "POST", redirect: "follow", headers: headers }
      )
        .then((response) => response.text())
        .then((result) => {
          this.setState({
            isNotification: true,
            notificationTitle: "Added deck successfully!",
            notificationDescription: "",
            notificationStatus: "success",
          });

          console.log("result", result);
          this.handleReset();
        })
        .catch((error) => {
          this.setState({
            isNotification: true,
            notificationTitle: "Error",
            notificationDescription: "An error occurred uploading your deck.",
            notificationStatus: "error",
          });
          this.handleReset();
        });
    }
  };

  handleReset = () => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/decks/${this.props.userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentDecks(data.decks);
        this.props.onSubmit(data.decks);
      })
      .catch((error) => console.error(error));

    this.setState({ isOpen: false });
  };

  handleTranslationLanguageChange = (event) => {
    const translationLanguageNew = event.target.value;
    this.setState({ translationLanguage: translationLanguageNew });

    // send new default to backend
    fetch(
      `${process.env.REACT_APP_API_URL}/user-language/${
        this.props.userApiId
      }?study_language=${""}&translation_language=${translationLanguageNew}`,
      { method: "PUT", redirect: "follow" }
    )
      .then(() => console.log(translationLanguageNew))
      .catch((error) => window.alert(error));

    // update global state
    this.props.setCurrentTranslationLanguage(translationLanguageNew);
  };

  onOpen = () => {
    this.setState({ isOpen: true });
  };

  onSave = () => {
    this.assertSubmit();
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const codeToLanguage = {
      en: "English",
      de: "German",
      es: "Spanish",
      fr: "French",
      it: "Italian",
      pt: "Portuguese",
    };

    return (
      <div>
        {this.state.isNotification && (
          <Notification
            title={this.state.notificationTitle}
            description={this.state.notificationDescription}
            status={this.state.notificationStatus}
            onMount={() => this.setState({ isNotification: false })}
          />
        )}

        <IconButton
          aria-label="Add Deck"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          }
          colorScheme="white"
          variant="solid"
          size="md"
          onClick={this.onOpen}
        />
        <Modal
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          size="xl"
          returnFocusOnClose={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Deck</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="flex flex-col pb-6 space-y-4">
                <WordInput
                  inputValue={this.state.deckName}
                  onInputChange={(event) =>
                    this.setState({ deckName: event.target.value })
                  }
                  textMainHeadline=""
                  textSubHeadline=""
                  textMain="Add Deck Name"
                  textOptional=""
                />
                <div className="max-w-lg space-y-2">
                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      Target Language
                    </p>
                    <p className="text-sm text-gray-900">
                      {codeToLanguage[this.props.targetLanguage]}
                    </p>
                  </div>

                  {this.props.targetLanguage === "en" ? (
                    <div className="space-y-2">
                      <p className="text-sm font-medium text-gray-700">
                        Select Translation Language
                      </p>
                      <select
                        onChange={this.handleTranslationLanguageChange}
                        value={this.state.translationLanguage}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
                      >
                        {!this.state.translationLanguage && (
                          <option hidden="true">
                            Select Translation Language
                          </option>
                        )}
                        {languages_dict[
                          this.props.targetLanguage
                        ].translations.map((lang) => (
                          <option value={lang.code}>{lang.name}</option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <p className="text-sm font-medium text-gray-700">
                        Translation Language
                      </p>
                      <p className="text-sm text-gray-900">English</p>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="gray" mr={3} onClick={this.onSave}>
                Save
              </Button>
              <Button variant="ghost" onClick={this.onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
  setCurrentTranslationLanguage: (target_language) =>
    dispatch(setCurrentTranslationLanguage(target_language)),
});

const mapStateToProps = ({ decks, user }) => ({
  userApiId: decks.user_api_id,
  targetLanguage: decks.target_language,
  translationLanguage: decks.translation_language,
  currentDecks: decks.currentDecks,
  token: user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDeckPopup);
