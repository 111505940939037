import React from "react";
import { auth } from "../../../firebase.utils";

export default function Profile() {
  const user = auth.currentUser;

  let name, email;
  if (user !== null) {
    user.providerData.forEach((profile) => {
      name = profile.displayName;
      email = profile.email;
    });
  }

  return (
    user && (
      <div className="flex flex-row pt-6">
        <div className="px-6 flex flex-row items-center space-x-4">
          <div>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-indigo-800 text-xs font-medium bg-indigo-100 rounded-full">
              Beta User
            </span>
          </div>
          <h2>
            <b>Username: </b>
            {name}
          </h2>
          <p>
            <b>Email: </b>
            {email}
          </p>
        </div>
      </div>
    )
  );
}
