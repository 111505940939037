import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, InformationCircleIcon } from '@heroicons/react/outline'
import Emoji from "../../../components/universal-components/emoji.component";
import WaitlistRegistration from './waitlist-registration-form.component';

export default function AdditionalInfo() {
  const [open, setOpen] = useState(true)
  const [bannerOpen, setBannerOpen] = useState(false)

  return (
    <div>
      {bannerOpen && (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
          <div className="max-w-4xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-yellow-100 shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-yellow-200">
                    <InformationCircleIcon
                      className="h-6 w-6 text-gray-800"
                      aria-hidden="true"
                    />
                  </span>
                  <p className="ml-3 font-medium text-gray-800 truncate">
                    <span className="md:hidden">
                      Tell us more about how we can help....
                    </span>
                    <span className="hidden md:inline">
                      Tell us a little more about how we can help you.
                    </span>
                  </p>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <div
                    onClick={() => {
                      setOpen(true);
                      setBannerOpen(false);
                    }}
                    className="cursor-pointer flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-yellow-200"
                  >
                    Submit Form
                  </div>
                </div>
                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                  <button
                    onClick={() => setBannerOpen(false)}
                    type="button"
                    className="-mr-1 flex p-2 rounded-md hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={open}
          onClose={() => {
            setOpen(false);
            setBannerOpen(true);
          }}
          unmount={false}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-8 bg-yellow-50 shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-8">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-gray-900">
                          <h1 className="text-2xl font-medium">
                            You are on the waitlist!{" "}
                            <Emoji symbol="😊" label="cap" />
                          </h1>
                          <h2 className="mt-2 font-normal">
                            Tell us a little more about how we can help you.
                          </h2>
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="bg-yellow-50 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                            onClick={() => {
                              setOpen(false);
                              setBannerOpen(true);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6 z-50">
                      <WaitlistRegistration
                        onFormSubmit={() => {
                          setOpen(false);
                          setBannerOpen(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}