import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { connect } from "react-redux";

class SidebarStats extends React.Component {
  render() {
    let dueWords = 0;
    let totalWords = 0;
    for (let x of this.props.currentDecks) {
      if (x.study_language === this.props.targetLanguage) {
        dueWords += x.words_due_count;
        totalWords += x.word_count;
      }
    }

    return (
      <div className="flex flex-row justify-center space-x-4 text-yellow-900">
        <Tooltip
          hasArrow
          label="Total words due"
          bg="gray.500"
          openDelay={300}
          placement="top"
        >
          <div className="flex flex-row space-x-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-yellow-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-lg">{dueWords}</p>
          </div>
        </Tooltip>
        <Tooltip
          hasArrow
          label="Total words added"
          bg="gray.500"
          openDelay={300}
          placement="top"
        >
          <div className="flex flex-row space-x-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-yellow-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-lg">{totalWords}</p>
          </div>
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  targetLanguage: decks.target_language,
});

export default connect(mapStateToProps)(SidebarStats);
