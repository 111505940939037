import { Component } from "react";
import { connect } from "react-redux";
import SectionHeading from "../../../pages/app/app-page-components/section-heading.component";
import AllTextsListItem from "./all-texts-components/all-texts-list-item.component";
import LoadingSkeleton from "../../universal-components/loading-skeleton.component";

class TextList extends Component {
  findDeckName = (id) => {
    for (let x of this.props.currentDecks) {
      if (x.id === id) {
        return x.name;
      }
    }
    return "Missing deck!";
  };

  render() {
    return (
      <div>
        <SectionHeading
          headingText="Select A Text"
          isButton={true}
          buttonText="Add New"
          buttonLink="/app/upload/settings"
        />
        {this.props.isLoaded && this.props.targetLanguage ? (
          <ul className="grid gap-4 grid-cols-1">
            {this.props.currentUploads.map((e) => {
              return (
                e["text_language"] === this.props.targetLanguage && (
                  <AllTextsListItem
                    textInfo={e}
                    deckName={this.findDeckName(e.deck_param)}
                  />
                )
              );
            })}
          </ul>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
  currentUploads: decks.currentUploads,
  targetLanguage: decks.target_language,
});

export default connect(mapStateToProps)(TextList);
