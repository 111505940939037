import SidebarMenuItem from "./app-page-components/sidebar-menu-item.component";
import LanguageSelector from "./app-page-components/language-selector.component";
import SidebarStats from "./app-page-components/sidebar-stats.component";

const sidebarOptions = [
  {
    text: "Upload Text",
    link: "/app/upload/settings",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    ),
  },
  {
    text: "My Texts",
    link: "/app/texts",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
      />
    ),
  },
  {
    text: "My Decks",
    link: "/app/decks",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
      />
    ),
  },
  /* {
    text: "Library", // "Explore"
    link: "/app/library",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
      />
    ),
  }, */
];

export default function Sidebar() {
  return (
    <div className="flex flex-col content-center text-xs font-medium mr-6 space-y-6 py-6 px-6">
      {/* <LanguageSelector /> */}
      <div className="space-y-4">
        {sidebarOptions.map((e) => (
          <SidebarMenuItem
            menuItemText={e.text}
            menuItemLink={e.link}
            menuItemIcon={e.icon}
          />
        ))}
      </div>
      <SidebarStats />
    </div>
  );
}
