import React from "react";
import Emoji from "../../../components/universal-components/emoji.component";

class SingleExampleSentence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSent: 0,
            activeSentDict: {},
            sentStart: false,
            sentPreWord: "",
            word: "",
            sentEnd: false,
            sentPostWord: "",
            listLength: this.props.sentenceList.length,
            listLengthOne: false, 
        }
    }

    componentDidMount() {
        this.handleNextSentence()
        if (this.props.sentenceList.length === 1) {
            this.setState({ listLengthOne: true })
        }
    }

    handleNextSentence = () => {
        let fullSentenceInfo

        if (this.state.activeSent === (this.state.listLength - 1)) {
            fullSentenceInfo = this.props.sentenceList[0]
            this.setState({ activeSent: 0 })
        } else {
            fullSentenceInfo = this.props.sentenceList[this.state.activeSent + 1]
            this.setState({ activeSent: this.state.activeSent + 1 })
        }

        const sentPreWord = fullSentenceInfo["sent-pre-token"]
        const sentPostWord = fullSentenceInfo["sent-post-token"]
        const lengthPreWord = sentPreWord.length
        const lengthPostWord = sentPostWord.length
        let stringPreWord, stringPostWord
        if (lengthPreWord < 26) {
            stringPreWord = sentPreWord
            this.setState( { sentStart: true })
        } else {
            stringPreWord = sentPreWord.substr((lengthPreWord - 25), lengthPreWord)
        }
        if (lengthPostWord < 26) {
            stringPostWord = sentPostWord
            this.setState( { sentEnd: true })
        } else {
            stringPostWord = sentPostWord.substr(0, 25)
        }

        const word = fullSentenceInfo["word"]
        
        this.setState({ sentPreWord: stringPreWord, sentPostWord: stringPostWord, word: word })
    }

    render() {
        return(
            <td className="sr-only sm:not-sr-only px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 max-w-lg pr-4">
                    {!this.state.sentStart && "..."}{this.state.sentPreWord} <b>{this.state.word}</b> {this.state.sentPostWord}{!this.state.sentEnd && "..."}
                </div>
                <div className="text-sm text-gray-500 flex items-stretch">
                    Sentence {this.state.activeSent + 1} out of {this.state.listLength} {this.state.listLengthOne ? "occurance" : "occurances"}
                    { !this.state.listLengthOne && 
                    <button onClick={this.handleNextSentence} className="px-1 self-center">
                        <svg className="h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                    }
                    
                </div>
            </td>
        )
    }
}

export default SingleExampleSentence;