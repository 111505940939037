import { Component } from "react";
import { connect } from "react-redux";
import SingleLanguageRow from "./single-language-row.component";

class DeckLanguageInformation extends Component {
  render() {
    const languageNames = {
      en: "English",
      af: "Afrikaans",
      ar: "Arabic",
      bg: "Bulgarian",
      bn: "Bangla",
      bs: "Bosnian (Latin)",
      ca: "Catalan",
      "zh-Hans": "Chinese Simplified",
      cs: "Czech",
      cy: "Welsh",
      da: "Danish",
      de: "German",
      el: "Greek",
      es: "Spanish",
      et: "Estonian",
      fa: "Persian",
      fi: "Finnish",
      ht: "Faroese",
      fr: "French",
      he: "Hebrew",
      hi: "Hindi",
      hr: "Croatian",
      hu: "Hungarian",
      id: "Indonesian",
      is: "Icelandic",
      it: "Italian",
      ja: "Japanese",
      ko: "Korean",
      lt: "Lithuanian",
      lv: "Latvian",
      mt: "Maltese",
      ms: "Malay",
      mww: "Hmong Daw",
      nl: "Dutch",
      nb: "Norwegian",
      pl: "Polish",
      pt: "Portuguese (Brazil)",
      ro: "Romanian",
      ru: "Russian",
      sk: "Slovak",
      sl: "Slovenian",
      "sr-Latn": "Serbian (Latin)",
      sv: "Swedish",
      sw: "Swahili",
      ta: "Tamil",
      th: "Thai",
      "tlh-Latn": "Klingon (Latin)",
      tr: "Turkish",
      uk: "Ukrainian",
      ur: "Urdu",
      vi: "Vietnamese",
    };

    const deckArray = this.props.currentDecks;
    let studyLanguage;
    let translationLanguage;
    for (let i = 0; i < deckArray.length; i++) {
      if (parseInt(deckArray[i].id) === parseInt(this.props.deckId)) {
        studyLanguage = languageNames[deckArray[i].study_language];
        translationLanguage = languageNames[deckArray[i].translation_language];
      }
    }

    return (
      <div className="space-y-1">
        <SingleLanguageRow
          infoText="Text Language:"
          langText={studyLanguage}
          deckLink={`/app/deck/settings/${this.props.deckId}`}
        />
        <SingleLanguageRow
          infoText="Translation Language:"
          langText={translationLanguage}
          deckLink={`/app/deck/settings/${this.props.deckId}`}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ decks }) => ({
  currentDecks: decks.currentDecks,
});

export default connect(mapStateToProps)(DeckLanguageInformation);
