import { LoadingOverlay } from "@mantine/core";
import { useState } from "react";
import Emoji from "../../../components/universal-components/emoji.component";
import UploadDropzone from "./onboarding-subpages-components/upload-dropzone.component";
import { useToast } from "@chakra-ui/react";
import OnboardingProgress from "./onboarding-progress.component";
import { textOptions } from "./text-options";
import { useSelector } from "react-redux";

const axios = require("axios").default;

export default function Onboarding1({ userApiId, onSubmit }) {
  const [optionIsLoading, setOptionIsLoading] = useState("");
  const toast = useToast();

  const token = useSelector((state) => state.user.token);

  const uploadText = (submissionMode, fileType, name, url = "") => {
    setOptionIsLoading(name);
    // const url = encodeURIComponent(url);
    axios.defaults.headers.common["Authorization"] = token;
    axios
      .post(`${process.env.REACT_APP_API_URL}/convert-file/1`, {
        name: name,
        submission_mode: submissionMode,
        url: url,
        text: "",
        deck_param: "",
        user_id: userApiId,
        text_language: "en",
        file_type: fileType,
      })
      .then((response) => {
        toast({
          title: "Saved Text",
          description: "Your text is waiting in your library.",
          status: "success",
          duration: 15000,
          isClosable: true,
          position: "top-right",
        });
        onSubmit(response.data.text_id, response.data.deck_id);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-8 justify-center items-center">
      <div className="flex flex-col space-y-4 max-w-3xl">
        <OnboardingProgress
          steps={[
            { name: "Step 1", link: "", status: "current" },
            { name: "Step 2", link: "", status: "upcoming" },
          ]}
        />
        <h1 className="text-3xl sm:text-5xl font-extrabold text-gray-900 text-center">
          Select your first text
        </h1>
        <h2 className="text-xl sm:text-2xl font-medium text-gray-700 text-center">
          Vocab works by either uploading custom texts or choosing from the
          library. Let's start by doing just that.
        </h2>
      </div>
      <div className="grid grid-cols-2 gap-3 max-w-2xl">
        {textOptions.map((e) => (
          <div
            onClick={() => {
              uploadText("file", e.fileType, e.name, e.url);
            }}
            className="sm:h-52 bg-white p-4 cursor-pointer rounded-md shadow-md transition duration-500 ease-in-out -200 transform hover:scale-105"
          >
            <p className="font-medium text-base sm:text-lg text-gray-900">
              {e.name} <Emoji symbol={e.symbol} />
            </p>
            <p className="font-normal text-sm sm:text-base text-gray-900">
              {e.description}
            </p>
            <LoadingOverlay
              visible={optionIsLoading === e.name}
              loaderProps={{ size: "md", color: "gray" }}
            />
          </div>
        ))}
        <div className="bg-white p-2 rounded-md shadow-md">
          <UploadDropzone
            onUpload={(url, fileType) =>
              uploadText("file", fileType, "My 1st Text", url)
            }
            isTranslationLanguage={true}
          />
        </div>
      </div>
    </div>
  );
}
