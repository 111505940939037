import React from "react";
import { Link } from "react-router-dom";

const SidebarMenuItem = ({ menuItemLink, menuItemText, menuItemIcon }) => (
  <Link
    to={menuItemLink}
    className="text-yellow-900 text-sm hover:bg-yellow-100 hover:bg-opacity-30 p-3 rounded-md flex flex-col items-center border border-yellow-900"
  >
    {menuItemIcon && (
      <svg
        class="text-yellow-900 h-6 w-6 mb-1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {menuItemIcon}
      </svg>
    )}
    <span>{menuItemText}</span>
  </Link>
);

export default SidebarMenuItem;
