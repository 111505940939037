import { useEffect, useState } from "react";

export default function Examples({ wordInfo, showSource }) {
  const [activeSent, setActiveSent] = useState(0);
  const [sentenceListLength, setSentenceListLength] = useState(0);
  const [sentenceDisplay, setSentenceDisplay] = useState(["", "", ""]);

  useEffect(() => {
    setSentence(wordInfo.original_words_list[0]);
    setSentenceListLength(wordInfo.original_words_list.length);
  }, [wordInfo]);

  const setSentence = (sentenceInfo) => {
    setSentenceDisplay([
      sentenceInfo["sent_pre_token"],
      sentenceInfo["word_original"],
      sentenceInfo["sent_post_token"],
    ]);
  };

  const handleNextSentence = () => {
    const list = wordInfo.original_words_list;
    let fullSentenceInfo;

    if (activeSent === list.length - 1) {
      fullSentenceInfo = list[0];
      setActiveSent(0);
    } else {
      fullSentenceInfo = list[activeSent + 1];
      setActiveSent(activeSent + 1);
    }

    setSentence(fullSentenceInfo);
  };

  return (
    <>
      <div
        className={`pr-4 ${sentenceListLength >= 2 && "cursor-pointer"}`}
        onClick={() => handleNextSentence()}
      >
        <p className="text-base text-gray-900 inline mr-2">
          {sentenceDisplay[0]}
          <b>{sentenceDisplay[1]}</b>
          {sentenceDisplay[2]}
        </p>
        <p className={`text-sm text-gray-500 ${!showSource && "inline"}`}>
          Sentence {activeSent + 1}/{sentenceListLength}
          {showSource &&
            ` | Text: ${wordInfo.original_words_list[activeSent]["source_name"]}`}
        </p>
      </div>
    </>
  );
}
