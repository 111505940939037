import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function MovieAdminList() {
  const [allMovies, setAllMovies] = useState([]);
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/library-all/movie`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        setAllMovies(data.items);
      })
      .catch((error) => console.error(error));
    console.log("Movie List updated");
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      {allMovies.map((e) => (
        <Link to={`/app/movie-admin/edit/${e.url_name}`}>
          <button className="border-2 border-gray-700">{e.name}</button>
        </Link>
      ))}
    </div>
  );
}
