import { Highlight } from "@mantine/core";
import { useEffect, useState } from "react";

export default function ExamplesNew({ exampleList, onChange, examples }) {
  const [listLength, setListLength] = useState(3);
  useEffect(() => {
    if (exampleList.length === 1) {
      onChange([exampleList[0]["sent"]]);
    }
  }, [exampleList]);

  return (
    <div className="flex flex-col space-y-1.5 items-start">
      {exampleList.slice(0, listLength).map((e, i) => (
        <button
          onClick={() => {
            if (examples.includes(e.sent)) {
              onChange(examples.filter((v) => v !== e.sent));
            } else {
              onChange([...examples, e.sent]);
            }
          }}
          value={i.toString()}
          className={`text-left text-gray-900 ml-0.5 ${
            examples.includes(e.sent)
              ? "border-l-4 pl-1.5 -ml-2 border-gray-400"
              : ""
          }`}
        >
          <Highlight highlight={e.word_original}>{e.sent}</Highlight>
        </button>
      ))}
      <div className="flex justify-center">
        {exampleList.length > listLength && (
          <button
            className="text-sm font-bold text-gray-500 p-1 rounded-md hover:bg-gray-50"
            onClick={() => setListLength((prev) => prev + 3)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}
