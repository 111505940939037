import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.utils";

export default function LogoutButton({ isMobile }) {
  const logout = async () => {
    await signOut(auth);
  };
  return (
    <div
      className={`cursor-pointer block px-4 py-2 hover:bg-gray-100 ${
        isMobile
          ? "text-base font-medium text-gray-500 hover:text-gray-800"
          : " text-sm text-gray-700"
      }`}
      onClick={logout}
    >
      Log Out
    </div>
  );
}
