export default function SingleDefinition({
  text,
  source,
  onSuggestionClick,
  onEditClick,
  isEdit,
  isSelected,
}) {
  const buttonStyle = isSelected
    ? "text-left font-medium border-2 text-gray-700 bg-gray-50 p-2 rounded-md hover:bg-gray-100 shadow-sm focus:ring-gray-400 w-full border-green-600"
    : "text-left font-medium border-2 text-gray-700 bg-gray-50 p-2 rounded-md hover:bg-gray-100 w-full";

  return (
    <div className="flex flex-row space-x-2 text-sm">
      {isEdit ? (
        <textarea
          placeholder="No definitions found. Add your own..."
          onChange={(event) => {
            console.log(event.target.value);
          }}
          value={text}
          className="text-sm text-left font-medium border-2 text-gray-700 bg-gray-50 p-2 rounded-md hover:bg-gray-100 shadow-sm focus:ring-gray-400 w-full border-green-700"
        ></textarea>
      ) : (
        <button
          className={buttonStyle}
          onClick={() => {
            onSuggestionClick();
          }}
        >
          {isSelected && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-green-500 mr-2 inline pb-0.5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {text}
          <span className="italic text-xs ml-2">...from {source}</span>
        </button>
      )}
      {/* TODO: Add editing */}
      {/* {isSelected && (
        <button onClick={() => onEditClick()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-700"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )} */}
    </div>
  );
}
