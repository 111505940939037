export default function Chip({ value, isChecked, onCheck }) {
  return (
    <button
      onClick={() => onCheck(isChecked)}
      className={`inline-flex items-center px-3 py-1 rounded-xl text-sm font-medium border-2 text-gray-700 ${
        isChecked ? "border-green-600" : "bg-gray-100 px-6"
      }`}
    >
      {isChecked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-green-600 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      )}
      {value}
    </button>
  );
}
