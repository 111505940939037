import { DecksActionTypes } from "./decks.types"

export const setCurrentDecks = decks => (
    {
        type: DecksActionTypes.SET_CURRENT_DECKS,
        payload: decks
    }
);

export const setUserApiId = user_ap_id => (
    {
        type: DecksActionTypes.SET_USER_API_ID,
        payload: user_ap_id
    }
)

export const setCurrentCardTypes = card_types => (
    {
        type: DecksActionTypes.SET_CURRENT_CARD_TYPES,
        payload: card_types
    }
)

export const setCurrentUploads = uploads => (
    {
        type: DecksActionTypes.SET_CURRENT_UPLOADS,
        payload: uploads
    }
);

export const setCurrentTargetLanguage = target_language => (
    {
        type: DecksActionTypes.SET_CURRENT_TARGET_LANGUAGE,
        payload: target_language
    }
);

export const setCurrentTranslationLanguage = translation_language => (
    {
        type: DecksActionTypes.SET_CURRENT_TRANSLATION_LANGUAGE,
        payload: translation_language
    }
);