import React from "react";
import { Link } from "react-router-dom";

export default function AllTextsListItem({
  textInfo: { id, name, deck_param, word_count },
  deckName,
}) {
  return (
    <div className="flex flex-row space-x-2">
      <Link
        to={`/app/text-view/text/${deck_param}/${id}`}
        className="flex-grow h-14 flex flex-rows justify-between bg-white rounded-lg shadow-sm border-2 hover:bg-gray-50 items-center px-4 py-1"
      >
        <div className="flex flex-row justify-center items-end space-x-2">
          <h2 className="font-medium text-md">{name}</h2>
          <p className="text-sm font-normal text-gray-700">
            {word_count} words
          </p>
        </div>
        <div className="flex justify-end">
          <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-gray-800">
            {deckName}
          </span>
        </div>
      </Link>
      <Link
        to={`/app/session/text/${deck_param}/${id}/quiz/5`}
        className="flex h-14 w-14 items-center justify-center bg-gray-50 rounded-lg shadow-sm border-2 hover:bg-gray-100"
      >
        <div className="space-x-2 py-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
            />
          </svg>
        </div>
      </Link>
    </div>
  );
}
