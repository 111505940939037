import React from "react";
import LandingHeader from "./landing-header.component";
import allDone from "../../assets/alldonescroller.png";
import Footer from "./footer.component";
import learnAndExplore from "../../assets/learn_and_explore.png";
import whatElse from "../../assets/what_else_you_got.png";
import teacher from "../../assets/teacher.png";

const WhyPage = () => (
  <div>
    <div className="relative bg-yellow-50 overflow-hidden">
      <div className="relative pt-6 pb-48">
        <LandingHeader />
        <div className="relative px-4 sm:px-6 lg:px-8  mt-8 md:mt-12">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-red-400 font-semibold tracking-wide uppercase">
                Asking why
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Why learn a language?
              </span>
            </h1>
            <p className="mt-12 text-xl text-gray-600 leading-8">
              For us, "Why learn a language?" and "Why live?" have the same
              answer.
            </p>
            <p className="mt-4 text-xl text-gray-700 font-bold leading-8 text-right">
              To explore.
            </p>
          </div>
          <div className="mt-6 prose prose-yellow prose-lg text-gray-500 mx-auto">
            <p>
              People seem to think they should learn a language to be miserable
              for 12 months and feel like an alien when starting to use it. But
              that doesn't seem right?{" "}
            </p>
            <p>
              We believe the real reason for learning languages is to{" "}
              <strong>enable curiosity</strong>. To communicate. To write, to
              read, to listen, to speak.
            </p>
            <p>
              Communication is the gateway to pursue your innate curiosity, seek
              understanding and share your ideas with the world.
            </p>
            <p>
              So why wouldn’t you improve that ability{" "}
              <strong>every damn day?</strong>
            </p>
            <p>
              Well. Many people <i>start.</i>
            </p>

            <div className="flex flex-col items-center justify-center">
              <img
                width="240"
                className="relative max-w-xs"
                src={teacher}
                alt="Scribble"
              />
            </div>
            <p>
              <strong>And then they stop.</strong> That's because traditional
              learning isn't driven by curiosity, but arbitrary fluff the
              teacher thinks is a good choice. Maybe for Tom - but for
              everyone?!
            </p>
            <p>
              Learning and improving should be driven by what excites you.
              Modernist literature? Public speaking? Ancient cultures? Design
              Podcasts? Biographies? Hemingway?
            </p>
            <p>All good answers. What else you got?</p>
            <div className="flex flex-col items-center justify-center">
              <img
                width="240"
                className="relative max-w-xs"
                src={whatElse}
                alt="Scribble"
              />
            </div>
            <p>
              The point is, you shouldn't have to wait around chipping away at
              textbooks until you are allowed to follow your heart. (Or stop
              chipping altogether because revising stuff takes ages.)
            </p>
            <h3 className="text-center">
              You should be able to learn and explore at the same time.
            </h3>
            <div className="flex flex-col items-center justify-center">
              <img
                className="relative max-w-xs"
                src={learnAndExplore}
                alt="Learn and explore circle"
              />
            </div>
            <p>
              If increasing our collective ability to be curious excites you -
              you are one of us.
            </p>
            <p>
              If you want to be part of our mission, please reach out to{" "}
              <a href="mailto:julius@vocab.so">julius@vocab.so</a>. We are
              looking for curious people like you.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default WhyPage;
