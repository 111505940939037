import React from "react";

export default function MultipleChoiceButton({
  value,
  onSelect,
  isDisabled,
  isCorrect,
  isFalse,
  isVerb,
}) {
  let bg = "hover:bg-gray-50";
  if (isCorrect) {
    bg = "bg-green-200";
  }
  if (isFalse) {
    bg = "bg-red-200";
  }
  return (
    <button
      value={value}
      onClick={onSelect}
      type="button"
      className={`inline-flex items-center px-4 py-2 border border-gray-300 ${bg} shadow-sm text-left text-base font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ${
        isDisabled && "cursor-not-allowed"
      }`}
      disabled={isDisabled}
    >
      {isVerb && "to "}
      {value}
    </button>
  );
}
