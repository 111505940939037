import { Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRouter } from "../../../hooks/useRouter";
import OnboardingProgress from "./onboarding-progress.component";

const axios = require("axios").default;

const options = [
  {
    name: "quiz",
    description: "Learn with quizzes",
    descriptionShort: "Quiz",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
        />
      </svg>
    ),
  },
  {
    name: "text-view",
    description: "Explore and manage words",
    descriptionShort: "Text",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
        />
      </svg>
    ),
  },
  {
    name: "flashcards",
    description: "Learn with flashcards",
    descriptionShort: "Flashcards",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
        />
      </svg>
    ),
  },
];

const CustomButton = ({
  text,
  onClick,
  disabled,
  color = "yellow",
  baseIntensity = 300,
  loading,
  loadingText,
}) => (
  <button
    onClick={onClick}
    className={`items-center px-4 py-2 justify-center border-2 rounded-md shadow-sm text-base font-medium text-white ${
      disabled
        ? `border-gray-${
            baseIntensity + 100
          } bg-gray-${baseIntensity} bg-transparent opacity-70`
        : loading
        ? `flex flex-row justify-center items-center space-x-2 border-${color}-${
            baseIntensity + 300
          } bg-${color}-${baseIntensity + 200} hover:bg-${color}-${
            baseIntensity + 300
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-${
            baseIntensity + 300
          } bg-transparent opacity-70 cursor-not-allowed`
        : `border-${color}-${baseIntensity + 300} bg-${color}-${
            baseIntensity + 200
          } hover:bg-${color}-${
            baseIntensity + 300
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-${
            baseIntensity + 300
          }`
    }`}
    disabled={disabled || loading}
  >
    {loading && <Spinner size="sm" />}{" "}
    <span>{loading ? loadingText : text}</span>
  </button>
);

const OptionCard = ({
  name,
  icon,
  description,
  descriptionShort,
  selected,
  onClick,
}) => {
  return (
    <button
      className={`px-8 py-4 rounded-lg shadow-sm border-2 border-gray-300 flex flex-col items-center space-y-2 ${
        selected
          ? "bg-white"
          : "bg-gray-50 bg-transparent opacity-70 hover:bg-gray-100"
      }`}
      onClick={() => onClick()}
    >
      {icon}
      <h3 className="sr-only sm:not-sr-only text-lg font-medium tracking-tight text-gray-900">
        {description}
      </h3>
      <h3 className="sm:sr-only text-lg font-medium tracking-tight text-gray-900">
        {descriptionShort}
      </h3>
    </button>
  );
};

export default function Onboarding2({ onSubmit, token }) {
  const [selected, setSelected] = useState("quiz");
  const [processingStarted, setProcessingStarted] = useState(false);
  let mounted = false;
  const router = useRouter();

  useEffect(() => {
    mounted = true;
    initialFetch();
    return () => {
      mounted = false;
    };
  }, [token]);

  const initialFetch = (isRepeat = 0) => {
    if (!mounted) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/convert-file/${router.query.text_id}`,
        { headers: { Authorization: token } }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 204 && mounted) {
          if (isRepeat > 4) {
            axios.put(
              `${process.env.REACT_APP_API_URL}/convert-file/${router.query.text_id}`,
              { headers: { Authorization: token } }
            );
            setTimeout(() => initialFetch(), 20000);
            window.alert(
              "It seems your text has failed processing. Restarting processing."
            );
          } else {
            setTimeout(() => initialFetch((isRepeat = isRepeat + 1)), 2000);
          }
        } else if (response.status === 206 && mounted) {
          if (response.data.words.words_todo.length > 10) {
            setProcessingStarted(true);
          } else {
            setTimeout(() => initialFetch(), 2000);
          }
        } else {
          console.log(response, "200");
          setProcessingStarted(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col space-y-8 justify-center items-center max-w-4xl px-1 sm:px-4">
      <div className="flex flex-col space-y-4 ">
        <OnboardingProgress
          steps={[
            { name: "Step 1", link: "/onboarding/1", status: "complete" },
            { name: "Step 2", link: "", status: "current" },
          ]}
        />
        <h1 className="text-3xl sm:text-5xl font-extrabold text-gray-900 text-center">
          Next steps
        </h1>
        <h2 className="text-xl sm:text-2xl font-medium text-gray-700 text-center">
          After importing a text, you can switch seamlessly between different
          learning modes. Explore them below, then choose one to get started.
        </h2>
      </div>

      <div className="grid grid-cols-5 gap-8 sm:h-96">
        <div className="col-span-2 flex flex-col space-y-2">
          {options.map((e) => (
            <OptionCard
              name={e.name}
              description={e.description}
              icon={e.icon}
              selected={selected === e.name}
              onClick={() => setSelected(e.name)}
              descriptionShort={e.descriptionShort}
            />
          ))}
        </div>

        <div className="col-span-3">
          {selected === "quiz" && (
            <div>
              <h2 className="text-lg font-bold">Learn with quizzes</h2>
              <div className="max-w-xl text-base font-normal pb-4 space-y-2">
                <p className="mt-2">
                  <b>Lets you get started in one click. </b>
                  If you don't want to search and add words manually, the quiz
                  mode allows you to start immediately. It creates exercises
                  automatically and gets continuously better at doing so the
                  more you learn.
                </p>
              </div>
              <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
                <CustomButton
                  text="Start Quiz"
                  onClick={() => {
                    onSubmit("quiz");
                  }}
                  loading={!processingStarted}
                  loadingText="Loading Quiz"
                />
                <CustomButton
                  text="Dashboard"
                  onClick={() => {
                    onSubmit("dashboard");
                  }}
                  color="gray"
                  baseIntensity={200}
                />
              </div>
            </div>
          )}
          {selected === "text-view" && (
            <div className="space-y-2">
              <h2 className="text-lg font-bold">Explore and manage words</h2>
              <div className="max-w-xl text-base font-normal pb-4 space-y-2">
                <p>
                  <b>
                    When you upload a text, it is processed into a vocabulary
                    list that includes every word in the text.
                  </b>
                  The “Text View” is where you can access, filter, and manage
                  that vocabulary list.
                </p>
                <p>
                  <b>From the Text View, you can:</b>
                </p>
                <ul className="list-disc ml-8 space-y-0.5">
                  <li>
                    filter words by difficulty, part-of-speech, and more.{" "}
                  </li>
                  <li>see all occurrences of the word in the text. </li>
                  <li>
                    add words to your “learning” vocabulary so that it shows up
                    in flashcards and quizzes immediately.{" "}
                  </li>
                  <li>
                    view and add word information such as definitions,
                    translations, or synonyms to your word.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
                <CustomButton
                  text="View Words"
                  onClick={() => {
                    onSubmit("text-view");
                  }}
                  loading={false}
                  loadingText="Loading Words"
                />
                <CustomButton
                  text="Dashboard"
                  onClick={() => {
                    onSubmit("dashboard");
                  }}
                  color="gray"
                  baseIntensity={200}
                />
              </div>
            </div>
          )}
          {selected === "flashcards" && (
            <div className="space-y-2">
              <h2 className="text-lg font-bold">Learn with flashcards</h2>
              <div className="max-w-xl text-base font-normal pb-4 space-y-2">
                <p>
                  <b>
                    Flashcards let you customize the fields you want to learn,{" "}
                  </b>
                  including definitions, translations, synonyms, and more.
                </p>
                <p>
                  <b>Words learned with flashcards must first be added. </b>
                  This happens automatically when you don't know a word while
                  learning via Quizzes or by adding new words directly from the
                  Text View.
                </p>
              </div>
              <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
                <CustomButton
                  text="Add via Quiz"
                  onClick={() => {
                    onSubmit("quiz");
                  }}
                  loading={!processingStarted}
                  loadingText="Loading Quiz"
                />
                <CustomButton
                  text="Add via Text"
                  onClick={() => {
                    onSubmit("text-view");
                  }}
                  loading={false}
                  loadingText="Loading Words"
                />
                <CustomButton
                  text="Dashboard"
                  onClick={() => {
                    onSubmit("dashboard");
                  }}
                  color="gray"
                  baseIntensity={200}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
