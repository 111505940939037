import { Component } from "react";
import { Redirect } from "react-router-dom";
import ConfirmDeletePopup from "./confirm-delete-popup.component";
import { connect } from "react-redux";
import { setCurrentDecks } from "../../redux/decks/decks.actions";

class DeckDeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: false,
      renderRedirectDashboard: false,
      responseMessage: "",
      confirmDeletePopup: false,
    };
  }

  submitDelete = () => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/deck/${this.props.deckId}`, {
      method: "DELETE",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => this.refresh(data.message))
      .catch((error) => window.alert(error));
  };

  refresh = (message) => {
    const headers = new Headers({ Authorization: this.props.token });
    fetch(`${process.env.REACT_APP_API_URL}/decks/${this.props.userApiId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.setCurrentDecks(data.decks);
      })
      .then(
        this.setState({
          renderRedirectDashboard: true,
          responseMessage: message,
        })
      )
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <div>
        <button
          onClick={() => this.setState({ confirmDeletePopup: true })}
          type="button"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Delete Deck
        </button>
        {this.state.renderRedirectDashboard && (
          <Redirect to={`/app?message=${this.state.responseMessage}`} />
        )}
        {this.state.confirmDeletePopup && (
          <ConfirmDeletePopup
            popUpHeadline="Delete this deck and all its words"
            popUpText="If you click delete, all connected texts and words will be removed."
            deleteButtonText="Delete Deck"
            onDelete={this.submitDelete}
            onCancel={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
});

const mapStateToProps = ({ decks }) => ({
  userApiId: decks.user_api_id,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeckDeleteButton);
