import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditWordPopup from "./edit-word-popup.component";

export default function DeckWordList({ deckId }) {
  const [words, setWords] = useState([]);
  const [tags, setTags] = useState([]);
  const [activeTags, setActiveTags] = useState([]);

  const token = useSelector((state) => state.user.token);
  useEffect(() => {
    if (token) {
      getWords();
    }
  }, token);

  const getWords = () => {
    const headers = new Headers({ Authorization: token });
    fetch(`${process.env.REACT_APP_API_URL}/words/${deckId}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setWords(data.words);
        setTags(data.tags.filter((tag) => tag));
      })
      .catch((error) => console.error(error));
  }

  const [isOpen, setIsOpen] = useState(false);
  const [wordId, setWordId] = useState(false);

  return (
    words &&
    tags && (
      <div>
        <div>
          <label className="text-sm font-medium mr-2">Filter by tags:</label>
          {tags.map((tag) => (
            <button
              onClick={() => {
                if (activeTags.includes(tag)) {
                  setActiveTags((prev) => prev.filter((e) => e !== tag));
                } else {
                  setActiveTags((prev) => [...prev, tag]);
                }
              }}
              className={`mt-3 mr-1 inline-flex items-center px-2 py-0.5 rounded-md text-sm font-medium ${
                activeTags.includes(tag)
                  ? "bg-gray-700 text-white"
                  : "bg-gray-300 text-gray-800 hover:bg-gray-400"
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
        {words
          .filter(
            (w) =>
              activeTags.length === 0 ||
              w.tags.split(", ").some((e) => activeTags.includes(e))
          )
          .map((w) => (
            <button
              onClick={() => {
                setWordId(w.id);
                setIsOpen(true);
              }}
              className="mt-3 mr-1 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-yellow-100"
            >
              {w.word_root}
            </button>
          ))}
        <EditWordPopup
          isOpen={isOpen}
          onDelete={() => getWords()}
          onClose={() => setIsOpen(false)}
          wordId={wordId}
          removeEventListener={() => console.log("")}
          resumeEventListener={() => console.log("")}
        />
      </div>
    )
  );
}
