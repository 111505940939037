import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeckTextList from "./deck-text-list.component";
import DeckWordList from "./deck-word-list.component";
import StartLearningSection from "./start-learning-section.component";
import Stats from "./stats.component";

export default function DeckDetailScreen(props) {
  const deck = useSelector((state) =>
    state.decks.currentDecks.find(
      (deck) => deck.id === parseInt(props.match.params.deckId)
    )
  );

  return (
    <div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-2">
        <h3 className="text-xl font-medium text-gray-900">{deck?.name}</h3>
        <div className="flex flex-row items-center justify-center space-x-2">
          <div className="flex-shrink-0">
            <button
              onClick={props.history.goBack}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-gray-400 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-700 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              Back
            </button>
          </div>
          <Link
            to={`/app/deck/settings/${props.match.params.deckId}`}
            className="flex flex-row"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </Link>
        </div>
      </div>
      <main className="divide-y">
        <div className="py-4 mb-1">
          <h3 className="font-medium text-gray-900">Start Learning</h3>
          <StartLearningSection
            deckId={props.match.params.deckId}
            deckLang={deck?.study_language}
          />
        </div>
        <div className="py-4 mb-1">
          <h3 className="text-base font-medium text-gray-900 mb-1">
            Daily Reviews
          </h3>
          <Stats deckId={props.match.params.deckId} />
        </div>

        <div className="py-4">
          <h3 className="font-medium text-gray-900">Texts</h3>
          <DeckTextList deckId={props.match.params.deckId} />
        </div>
        {deck?.word_count > 0 && (
          <div className="py-4">
            <h3 className="font-medium text-gray-900">Words</h3>
            <DeckWordList deckId={props.match.params.deckId} />
          </div>
        )}
      </main>
    </div>
  );
}
