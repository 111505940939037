export default function WordLevel({ interval }) {
  let level = 0;
  if (interval > 28) {
    level = 5;
  } else if (interval > 14) {
    level = 4;
  } else if (interval > 7) {
    level = 3;
  } else if (interval > 3) {
    level = 2;
  } else if (interval > 1) {
    level = 1;
  }
  const arr = [1, 2, 3, 4, 5];
  return (
    <div className="flex flex-row space-x-2 pl-4 pt-4">
      {arr.map((e) => (
        <div
          className={`h-2 w-2 rounded-sm ${
            e <= level ? "bg-yellow-500" : "bg-gray-500"
          }`}
        ></div>
      ))}
    </div>
  );
}
