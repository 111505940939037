import { auth } from "../firebase.utils";
import logoSmall from "../assets/logo_small.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useRouter } from "../hooks/useRouter";
import { useEffect } from "react";
import { analytics } from "../firebase.utils";
import { logEvent } from "firebase/analytics";

export default function SignUp() {
  const [registerName, setRegisterName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState([false, ""]);
  const [emailDisabled, setEmailDisabled] = useState(true);

  const router = useRouter();

  useEffect(() => {
    setRegisterEmail(router.query.email);
    setRegisterName(router.query.name);
  }, [router]);

  const register = () => {
    setIsIncorrect([false, ""]);

    if (["slclogin.com"].includes(registerEmail.split("@")[1])) {
      window.alert(
        "This domain has been flagged for unusual activity. If this is a mistake please contact info@vocab.so."
      );
      return;
    }

    createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // user.updateProfile({ displayName: registerName });

        updateProfile(user, {
          displayName: registerName,
        })
          .then(() => {
            console.log("profile update: ", "profile updated");
          })
          .catch((error) => {
            console.log("profile update: ", error);
          });

        logEvent(analytics, "registration_completed");

        fetch(
          `${process.env.REACT_APP_API_URL}/register?username=${user.email}&id_token=${user.accessToken}`,
          { method: "POST", redirect: "follow" }
        )
          .then((response) => response.json())
          .then((res) => {
            setIsRegistered(true);
          })
          .catch((error) => {
            console.log(error);
          });
        fetch(
          // TODO: once deployed to production update zapier
          `https://hooks.zapier.com/hooks/catch/9899583/bikgqix?email=${registerEmail}&name=${registerName}`,
          { method: "POST", redirect: "follow" }
        )
          .then((response) => response.json())
          .then((data) => console.log("Success", data))
          .catch((error) => console.log("Error", error));
      })
      .catch((error) => {
        try {
          const errorMessage = error.code.split("/")[1].split("-").join(" ");
          setIsIncorrect([
            true,
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1),
          ]);
        } catch (error) {
          setIsIncorrect([true, error.code]);
        }
      });
  };

  return (
    <div className="min-h-full flex flex-col justify-center pb-12 px-6 lg:px-8 bg-yellow-50 h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <img className="h-8 w-auto" src={logoSmall} alt="vocab logo small" />
        </Link>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Create your Vocab Account
        </h2>
        <h3 className="mt-2 text-1xl font-bold text-gray-500">
          Learning <p className="inline">10 new words a day for a week</p>{" "}
          expands your vocabulary like{" "}
          <p className="inline bg-yellow-200">living abroad for a month.</p>
        </h3>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="space-y-3">
          {/* <p className="mt-2 text-center text-sm text-gray-600">Make sure to use the email you used to sign up for the waiting list.</p> */}
          {isIncorrect[0] && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{isIncorrect[1]}</AlertTitle>
            </Alert>
          )}

          {router.query.email && (
            <div>
              <label
                htmlFor="email"
                className="block text-md font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 flex relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="e.g. quinn@email.com"
                  required
                  className={`${
                    emailDisabled && "bg-gray-100"
                  } appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md`}
                  value={registerEmail}
                  onChange={(event) => setRegisterEmail(event.target.value)}
                  disabled={emailDisabled}
                />
                {emailDisabled && (
                  <button
                    className="absolute top-0 right-0 h-full mr-2 p-2"
                    onClick={() => setEmailDisabled(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="email"
              className="block text-md font-medium text-gray-700"
            >
              Name
            </label>
            <div className="mt-1">
              <input
                id="name"
                name="name"
                type="name"
                autoComplete="name"
                placeholder="e.g. Quinn"
                required
                className="appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md focus:border-2"
                value={registerName}
                onChange={(event) => setRegisterName(event.target.value)}
                autoFocus
              />
            </div>
          </div>
          {!router.query.email && (
            <div>
              <label
                htmlFor="email"
                className="block text-md font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="e.g. quinn@email.com"
                  required
                  className="appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md"
                  value={registerEmail}
                  onChange={(event) => setRegisterEmail(event.target.value)}
                />
              </div>
            </div>
          )}

          <div>
            <label
              htmlFor="password"
              className="block text-md font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="e.g. **********"
                required
                className="appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md"
                value={registerPassword}
                onChange={(event) => setRegisterPassword(event.target.value)}
              />
            </div>
          </div>

          <div className="pt-3">
            <button
              onClick={register}
              className={`w-full flex justify-center py-2.5 px-4 border-2 rounded-md shadow-sm text-md font-medium text-white ${
                registerName && registerEmail && registerPassword
                  ? "border-yellow-600 bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  : "border-gray-400 bg-gray-300"
              }`}
              disabled={!registerName || !registerEmail || !registerPassword}
            >
              Create Account
            </button>
          </div>

          {isRegistered && <Redirect to="/onboarding" />}
        </div>
      </div>
    </div>
  );
}
