import QuizAssess from "./quiz-components/quiz-assess.component";
import QuizCloze from "./quiz-components/quiz-cloze.component";
import ProgressBar from "./session-components/progress-bar.component";
import { useRouter } from "../../../hooks/useRouter";
import { useSelector } from "react-redux";
import { useState } from "react";
import QuizFeedback from "./quiz-components/quiz-feedback.component";
import QuizAssessByList from "./quiz-components/quiz-assess-by-list.component";
import TextViewAdd from "../../app-dynamic-upload/text-view-add.component";
import WordLevel from "./session-components/exercise-components/flashcard-components/word-level.component";
import { Tooltip } from "@chakra-ui/react";

export default function QuizSession({
  onNext,
  exercise,
  itemsDone,
  itemsOpen,
  isDisableEventListener,
  onFeedback,
}) {
  const router = useRouter();
  const userApiId = useSelector((state) => state.decks.user_api_id);
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState(["", ""]);

  const token = useSelector((state) => state.user.token);

  const handleAction = (feedbackType, answer) => {
    if (feedbackType) {
      setIsFeedback(true);
      setFeedbackContent([feedbackType, answer]);
      onFeedback([feedbackType, answer]);
    } else {
      handleNext();
    }
  };

  const handleNext = () => {
    setIsFeedback(false);
    onNext();
  };

  return (
    <div className="container mx-auto max-w-xl space-y-4 pt-12">
      <ProgressBar itemsDone={itemsDone} itemsOpen={itemsOpen}>
        {(exercise["type"] === "assess_by_list" ||
          exercise["type"] === "assess") && (
          <Tooltip
            hasArrow
            label="This exercise improves our assessment of your vocabulary."
            bg="gray.600"
            openDelay={100}
          >
            <div className="text-green-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Tooltip>
        )}
        {exercise["type"] === "cloze" && (
          <div className="mb-2">
            <WordLevel interval={exercise["word_info"]["interval"]} />
          </div>
        )}
        {exercise["type"] === "add" && (
          <Tooltip hasArrow label="New word!" bg="gray.600" openDelay={100}>
            <div className="text-yellow-600 opacity-90">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
              </svg>
            </div>
          </Tooltip>
        )}
      </ProgressBar>

      <div className="pt-8">
        {exercise["type"] === "assess" && (
          <QuizAssess
            key={itemsDone}
            exercise={exercise}
            field={exercise["field"]}
            incorrectAnswers={exercise["other_options"]}
            correctAnswer={exercise["answer"]}
            wordInfo={exercise["word_info"]}
            deckId={router.match.params.deckId}
            userApiId={userApiId}
            onAction={(feedbackType, answer) =>
              handleAction(feedbackType, answer)
            }
          />
        )}

        {exercise["type"] === "add" && (
          <>
            <TextViewAdd
              onSuccessfulCardSubmit={() => handleAction(false, "")}
              onWordsRemove={() => handleAction(false, "")}
              deckId={router.match.params.deckId}
              activeWordInfo={exercise["word_info"]}
              token={token}
            />
            {/* <QuizAdd
            key={itemsDone}
            wordInfo={exercise["word_info"]}
            field={exercise["field"]}
            onAction={() => handleAction(false, "")}
          /> */}
          </>
        )}

        {exercise["type"] === "cloze" && (
          <QuizCloze
            key={itemsDone}
            field={exercise["field"]}
            wordInfo={exercise["word_info"]}
            onAction={(feedbackType, answer) =>
              handleAction(feedbackType, answer)
            }
            sessionUUID={exercise["session_uuid"]}
          />
        )}

        {exercise["type"] === "assess_by_list" && (
          <QuizAssessByList
            key={itemsDone}
            words={exercise["words"]}
            deckId={router.match.params.deckId}
            userApiId={userApiId}
            onAction={(feedbackType, answer) =>
              handleAction(feedbackType, answer)
            }
          />
        )}
      </div>
      {isFeedback && (
        <div className="pt-4">
          <QuizFeedback
            feedback={feedbackContent}
            onAction={() => handleNext()}
            exercise={exercise}
          />
        </div>
      )}
    </div>
  );
}
