import React from "react";
import HubspotForm from "../../waitlist/hubspot-form.component"

class WaitlistRegistration extends React.Component {
    componentDidMount() {
        window.addEventListener('message', this.hubSpotEventHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.hubSpotEventHandler);
    }

    hubSpotEventHandler = (event) => {
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
            if (event.data.id === 'b797eca7-2ec7-46e1-a4ef-8bdc135864ec') {
                this.props.onFormSubmit()
            }
        }
    }

    render() {
        return (
            <div className="p-6">
                <div className="max-w-2xl mx-auto sm:flex-grow">
                    <HubspotForm
                        portalId='14504668'
                        formId='b797eca7-2ec7-46e1-a4ef-8bdc135864ec'
                        onSubmit={() => console.log("submitted")}
                        onReady={() => console.log("ready")}
                        loading={<h2 className="text-base font-semibold text-red-400 tracking-wide">Loading...</h2>}
                    />
                </div>
            </div>
        )
    }
}

export default WaitlistRegistration;