import React from "react";
import HubspotForm from "./hubspot-form.component"
import flowers from "../../assets/lander-bottom.png"
import { Link } from "react-router-dom"

class WaitlistRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formReady: false,
            formSubmit: false,
        }
    }

    render() {
        return(
            <div className="py-16 bg-yellow-50 overflow-hidden lg:py-24 min-h-screen">

                <div className="max-w-7xl mx-auto py-4 sm:py-8 px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                    <h2 className="text-base font-semibold text-red-400 tracking-wide uppercase">Join our waitlist</h2>
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Learn From Context.</p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                    </p>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row sm:justify-center sm:items-center sm:space-x-12">
                    
                    <div className="sr-only sm:not-sr-only max-w-2xl mx-auto sm:flex-none">
                        <img    className={`relative mx-auto ${this.state.formSubmit ? "" : "-mt-36"}`} 
                                width="320" src={flowers} alt="" />
                        { this.state.formSubmit && 
                            <div className="mt-8 text-gray-900 text-lg space-y-2 text-center">
                                <p>Thanks for registering.</p>
                                <p>You'll receive an email soon.</p>
                                <Link to="/"><p className="italic text-base mt-6">back...</p></Link>
                            </div>
                        }
                    </div>

                    { !this.state.formSubmit && 
                        <div className="max-w-2xl mx-auto sm:flex-grow">
                                <HubspotForm
                                    portalId='14504668'
                                    formId='b797eca7-2ec7-46e1-a4ef-8bdc135864ec'
                                    onSubmit={() => this.setState({ formSubmit: true })}
                                    onReady={(form) => this.setState({ formReady: true})}
                                    loading={<h2 className="text-base font-semibold text-red-400 tracking-wide">Loading...</h2>}
                                />
                        </div>
                    }
                    

                </div>
            </div>
            
        )
    }
}

export default WaitlistRegistration;