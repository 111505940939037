import React from "react";

class SingleTranslation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTranslation: 0,
            translationSuggestion: [],
            listLength: 0,

            showTranslation: false,
            placeholderText: ""
        }
        this.showTranslation = this.showTranslation.bind(this);
    }

    componentDidMount = () => {
        if (this.props.tokenPosition < 5) {
            this.showTranslation()
        }
    }

    showTranslation = () => {
        this.setState({ showTranslation: true, placeholderText: "loading" })

        const word = this.props.word
        const sourceLanguage = this.props.sourceLanguage
        const targetLanguage = this.props.targetLanguage
        const posTag = this.props.pos // weirdly only works when const set before fetching

        fetch(`${process.env.REACT_APP_API_URL}/translation?word=${word}&source=${sourceLanguage}&target=${targetLanguage}`, {method: "GET", redirect: "follow"})
            .then(response => response.json())
            .then(data => 
                {   let translationArray = data.translations.filter(function (translation) {return translation.posTag === posTag})
                    let translations = translationArray.map(translation => translation.displayTarget)
                    this.setState({ translationSuggestion: translations,  listLength: translations.length, placeholderText:"n/a"});
                    ;
                })
            .catch(error => this.setState({ placeholderText: error }))
    }

    nextTranslation = () => {
        if (this.state.activeTranslation === (this.state.listLength - 1)) {
            this.setState({ activeTranslation: 0 })
        } else {
            this.setState({ activeTranslation: (this.state.activeTranslation + 1)})
        }
    }

    render() {
        return(
            
            <td className="px-6 py-4 whitespace-nowrap">
                { this.state.showTranslation ? 
                    (<div>
                        
                        <div className="text-sm text-gray-900 max-w-lg pr-4">
                            { (this.state.listLength === 0) ? this.state.placeholderText : this.state.translationSuggestion[this.state.activeTranslation] }
                        </div>
                        
                            { (this.state.listLength > 1) && 
                            <div className="text-sm text-gray-500 flex items-stretch">
                                <p>
                                    {this.state.activeTranslation + 1}/{this.state.listLength} meanings
                                </p>
                                <button onClick={this.nextTranslation} className="px-1 self-center">
                                    <svg className="h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            }

                    </div>
                    ) : (
                        <button className="text-sm text-gray-500" onClick={this.showTranslation}>Show</button>
                    )}

            </td>
                
                
            
        )
    }
}

export default SingleTranslation;