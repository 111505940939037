import React from "react";

const SingleLanguageRow = ({ infoText, langText, deckLink }) => (
  <div className="flex flex-row space-x-1">
    <p className="text-sm font-medium text-gray-500">{infoText}</p>
    <p className="text-sm text-gray-900">{langText}</p>
    {/* IMPLEMENT REACT ROUTER DOM REDIRECTS TO PREVIOUS PAGE IN DECK SETTINGS (CANCEL/SAVE) FIRST */}
    {/* <Link to={deckLink}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mt-1 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
        </Link> */}
  </div>
);

export default SingleLanguageRow;
