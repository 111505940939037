import React from "react";
import { Link } from "react-router-dom";
import DeckTextList from "../deck-text-list.component";

const DashboardButton = () => {
  return (
    <Link to="/app">
      <button
        type="button"
        className="inline-flex justify-center w-full rounded-md border-2 border-gray-300 bg-gray-100 px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 hover:opacity-90"
      >
        Go back to dashboard
      </button>
    </Link>
  );
};

const ScreenContent = ({ heading, subHeading, children }) => {
  return (
    <div className="mt-5 text-center flex flex-col items-center">
      <h3 class="text-3xl font-bold text-gray-900">{heading}</h3>
      <h3 className="text-xl font-base mt-2 mb-2 max-w-sm">{subHeading}</h3>
      <div className="flex flex-row items-center justify-center">
        {children}
      </div>
    </div>
  );
};

export default function EndScreen({
  noNewExercises,
  onNewSession,
  deckId,
  itemType,
}) {
  return (
    <div className="flex flex-col h-full">
      <div className="container m-auto max-w-2xl">
        <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-yellow-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            />
          </svg>
        </div>
        {noNewExercises ? (
          <>
            {itemType === "deck" ? (
              <ScreenContent
                heading="Phew, all done!"
                subHeading="You've completed all reviews for today. To continue, add more words:"
              >
                <div className="flex flex-row items-center justify-center">
                  {/* TODO: this isn't rerendering because the <Session> component doesn't update the router props properly */}
                  <DeckTextList deckId={deckId} isLink={false} />
                </div>
              </ScreenContent>
            ) : (
              <ScreenContent
                heading="All done!"
                subHeading="You've review all of today's words for this text. Bravo!"
              >
                <DashboardButton />
              </ScreenContent>
            )}
          </>
        ) : (
          <ScreenContent
            heading="Nice work!"
            subHeading="All done for this session. Continue learning below!"
          >
            <div className="flex flex-col space-y-4 mt-10">
              <button
                onClick={onNewSession}
                type="button"
                className="inline-flex justify-center w-full rounded-md border-2 border-yellow-200 px-4 py-2 text-base font-medium text-gray-900 hover:bg-opacity-90 hover:bg-yellow-200 hover:opaciy-95 bg-yellow-200"
              >
                Keep going!
              </button>
              <DashboardButton />
            </div>
          </ScreenContent>
        )}
      </div>
    </div>
  );
}
