import { auth } from "../firebase.utils";
import { signInWithEmailAndPassword } from "firebase/auth";
import logoSmall from "../assets/logo_small.png";
import { useState } from "react";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function SignIn() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isIncorrect, setIsIncorrect] = useState([false, ""]);

  const login = async () => {
    setIsIncorrect([false, ""]);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);
    } catch (error) {
      const errorMessage = error.code.split("/")[1].split("-").join(" ");
      setIsIncorrect([
        true,
        errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1),
      ]);
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center pb-12 px-6 lg:px-8 bg-yellow-50 h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <img className="h-8 w-auto" src={logoSmall} alt="vocab logo small" />
        </Link>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Learning{" "}
          <p className="inline">10 new words a day for a week</p>{" "}
          expands your vocab like{" "}
          <p className="inline bg-yellow-200">living abroad for a month.</p>
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="space-y-3">
          {isIncorrect[0] && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{isIncorrect[1]}</AlertTitle>
            </Alert>
          )}
          <div>
            <label
              htmlFor="email"
              className="block text-md font-medium text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                value={loginEmail}
                onChange={(event) => setLoginEmail(event.target.value)}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-md font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none block w-full px-3 py-2.5 border-2 border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-md"
                value={loginPassword}
                onChange={(event) => setLoginPassword(event.target.value)}
              />
            </div>
          </div>

          <div className="py-3">
            <button
              onClick={login}
              className="w-full flex justify-center py-2.5 px-4 border-2 border-yellow-600 rounded-md shadow-sm text-md font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Sign Back In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
