import React from "react";
import { Link } from "react-router-dom";

const SectionHeading = ({ headingText, isButton, buttonText, buttonLink }) => (
  <div class="flex flex-row justify-between items-center border-b border-gray-200 mb-6 pb-2">
    <h3 class="text-xl font-medium text-gray-900">{headingText}</h3>
    {isButton && (
      <div class="flex-shrink-0">
        <Link to={buttonLink}>
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-gray-400 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            {buttonText}
          </button>
        </Link>
      </div>
    )}
  </div>
);

export default SectionHeading;
