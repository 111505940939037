import React from "react";
import Session from "../../components/app-page/learn/session.component";
import {
  setCurrentDecks,
  setUserApiId,
  setCurrentCardTypes,
  setCurrentUploads,
  setCurrentTranslationLanguage,
  setCurrentTargetLanguage,
} from "../../redux/decks/decks.actions";
import { connect } from "react-redux";
import TextView from "../../components/app-dynamic-upload/text-view.component";
import AppScreen from "./app-screen.component";
import { analytics } from "../../firebase.utils";
import { logEvent } from "firebase/analytics";
import MovieAdmin from "../movie/movie-admin/movie-admin.page";
import Scan from "../../components/app-extension/scan.component";
import Extension from "../../components/app-extension/extension.component";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../../components/app-page/dashboard/dashboard.component";
import AddDeck from "../../components/app-configuration/deck-configuration/add-deck/add-deck.component";
import DeckSettings from "../../components/app-configuration/deck-configuration/deck-settings/deck-settings.component";
import Upload from "../../components/app-dynamic-upload/upload.component";
import Profile from "../../components/app-header/profile/profile.component";
import TextSettings from "../../components/app-dynamic-upload/text-settings.component";
import TextList from "../../components/app-page/all-texts/text-list.component";
import AddSentence from "../../components/app-add-sentence/add-sentence.component";
import DeckDetailScreen from "../../components/app-page/learn/deck-detail-screen.component";
import MyDecks from "../../components/app-page/my-decks/my-decks.component";
import Library from "../../components/app-page/library/library.component";
import LibraryItem from "../../components/app-page/library/library-components/library-item.component";
import WordOverview from "../../components/word-overview.component";

class AppPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    const {
      setUserApiId,
      setCurrentDecks,
      setCurrentUploads,
      setCurrentTargetLanguage,
      setCurrentTranslationLanguage,
    } = this.props;

    // use access token from App.js props to avoid waiting for redux
    const headers = new Headers({ Authorization: this.props.user.accessToken });

    fetch(`${process.env.REACT_APP_API_URL}/api-id/${this.props.user.uid}`, {
      method: "GET",
      redirect: "follow",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setUserApiId(data);

        fetch(
          `${process.env.REACT_APP_API_URL}/decks/${this.props.user_api_id}`,
          { method: "GET", redirect: "follow", headers: headers }
        )
          .then((response) => response.json())
          .then((data) => {
            setCurrentDecks(data.decks);
          })
          .catch((error) => console.log(error));

        fetch(
          `${process.env.REACT_APP_API_URL}/all-uploads/${this.props.user_api_id}`,
          { method: "GET", redirect: "follow", headers: headers }
        )
          .then((response) => response.json())
          .then((data) => {
            setCurrentUploads(data.uploads);
          })
          .catch((error) => console.log(error));

        setCurrentTargetLanguage("en");

        fetch(
          `${process.env.REACT_APP_API_URL}/user-language/${this.props.user_api_id}`,
          { method: "GET", redirect: "follow", headers: headers }
        )
          .then((response) => response.json())
          .then((data) => {
            // TODO: I have not cleaned up any of the language selection stuff, only hard coded to select target language "en" above and commented out the language select
            // to find references where this is still used, search decks.target_language (--> usage sof redux to access current language)
            // setCurrentTargetLanguage(data.study_language);
            setCurrentTranslationLanguage(data.translation_language);
          })
          .catch((error) => console.log(error));
        this.setState({ isLoaded: true });
      })
      .catch((error) => console.log(error));

    logEvent(analytics, "start_app_session");
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/app/session/:itemType/:deckId/:textId/:sessionType/:sessionLength"
            render={(routeProps) => (
              <Session {...routeProps} token={this.props.user.accessToken} />
            )}
          />
          <Route
            path="/app/text-view/:itemType/:deckId/:textId/"
            render={(routeProps) => (
              <TextView {...routeProps} token={this.props.user.accessToken} />
            )}
          />
          <Route path="/app/movie-admin">
            <MovieAdmin />
          </Route>
          <Route path="/app/scan">
            <Scan
              token={this.props.user.accessToken}
              isLoaded={this.state.isLoaded}
            />
          </Route>
          <Route path="/app/extension">
            <Extension token={this.props.user.accessToken} />
          </Route>

          <AppScreen>
            <Route exact path="/app">
              <Dashboard
                isLoaded={this.state.isLoaded}
                user={this.props.user}
              />
            </Route>
            <Route path="/app/deck/add" component={AddDeck} />
            <Route path="/app/deck/settings/:deckId" component={DeckSettings} />
            <Route path="/app/sentence" component={AddSentence} />
            <Route path="/app/upload/settings" component={Upload} />
            <Route
              path="/app/text/settings/:textId"
              render={(routeProps) => (
                <TextSettings
                  {...routeProps}
                  token={this.props.user.accessToken}
                />
              )}
            />
            <Route path="/app/profile">
              <Profile />
            </Route>
            <Route path="/app/texts">
              <TextList isLoaded={this.state.isLoaded} />
            </Route>
            <Route path="/app/decks">
              <MyDecks isLoaded={this.state.isLoaded} />
            </Route>
            <Route exact path="/app/library">
              <Library />
            </Route>
            <Route path="/app/library/:itemTitle">
              <LibraryItem />
            </Route>
            <Route
              path="/app/learn/:deckId"
              render={(routeProps) => (
                <DeckDetailScreen
                  {...routeProps}
                  token={this.props.user.accessToken}
                />
              )}
            />
            <Route path="/app/word-overview">
              <WordOverview isLoaded={this.state.isLoaded} />
            </Route>
          </AppScreen>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({
  user: { currentUser },
  decks: { user_api_id, currentDecks, currentCardTypes, currentUploads },
}) => ({
  currentUser,
  user_api_id,
  currentDecks,
  currentCardTypes,
  currentUploads,
});

const mapDispatchToProps = (dispatch) => ({
  setUserApiId: (user_api_id) => dispatch(setUserApiId(user_api_id)),
  setCurrentDecks: (decks) => dispatch(setCurrentDecks(decks)),
  setCurrentCardTypes: (card_types) =>
    dispatch(setCurrentCardTypes(card_types)),
  setCurrentUploads: (uploads) => dispatch(setCurrentUploads(uploads)),
  setCurrentTargetLanguage: (target_language) =>
    dispatch(setCurrentTargetLanguage(target_language)),
  setCurrentTranslationLanguage: (translation_language) =>
    dispatch(setCurrentTranslationLanguage(translation_language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);
