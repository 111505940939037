export default function QuizSummary({ quizHistory }) {
  return (
    <div className="bg-gray-50 rounded-lg p-6 relative shadow-sm text-center space-y-8">
      <p className="font-bold text-xl">Results</p>
      <div>
        <p className="font-bold text-lg bg-green-100 mb-2">
          Words with correct answers:
        </p>
        {quizHistory
          .filter((e) => e[1])
          .map((e) => (
            <p className="text-lg">{e[0]}</p>
          ))}
      </div>
      <div>
        <p className="font-bold text-lg bg-red-100 mb-2">
          Words with incorrect answers
        </p>
        {quizHistory
          .filter((e) => !e[1])
          .map((e) => (
            <p className="text-lg">{e[0]}</p>
          ))}
      </div>
    </div>
  );
}
