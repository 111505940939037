import React from "react";

const Privacy = () => (
  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-8">
    <div className="bg-yellow-50 overflow-hidden shadow rounded-lg">
      <div className="px-8 py-10 space-y-4">
        <p className="font-bold text-3xl">Privacy Policy</p>
        <p>
          3z digital GmbH (3z digital Sagl) (3z digital Ltd liab. Co) (3z
          digital Sàrl)
        </p>
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit https://vocab.so (the
          “Site”).
        </p>
        <p className="font-bold">1 Who we are</p>
        <p>Our website address is: https://vocab.so</p>
        <p className="font-bold">
          2 What personal data we collect and why we collect it
        </p>
        <p>Contact forms</p>
        <p>
          We may use your personal information to contact you. We save your
          contact data for contacting you and to process your request. We will
          not share this information without your consent.
        </p>
        <p>Cookies</p>
        <p>
          If you leave a comment on our site you may opt-in to saving your name,
          email address and website in cookies. These are for your convenience
          so that you do not have to fill in your details again when you leave
          another comment. These cookies will last for one year. If you have an
          account and you log in to this site, we will set a temporary cookie to
          determine if your browser accepts cookies. This cookie contains no
          personal data and is discarded when you close your browser. When you
          log in, we will also set up several cookies to save your login
          information and your screen display choices. Login cookies last for
          two days, and screen options cookies last for a year. If you select
          “Remember Me”, your login will persist for two weeks. If you log out
          of your account, the login cookies will be removed. If you edit or
          publish an article, an additional cookie will be saved in your
          browser. This cookie includes no personal data and simply indicates
          the post ID of the article you just edited. It expires after 1 day.
        </p>
        <p>Embedded content from other websites</p>
        <p>
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites behaves
          in the exact same way as if the visitor has visited the other website.
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </p>
        <p>Analytics</p>
        <p>
          This website uses Google Analytics, a web analysis service of Google
          Inc, (1600 Amphitheatre Parkway Mountain View, CA 94043, USA;
          &quot;Google&quot;). The use includes the &quot;Universal
          Analytics&quot; operating mode. This facilitates the assignment of
          data, sessions, and interactions across several devices to a
          pseudonymous user ID and thus the analysis of a user’s activities
          across devices. This data protection notice is provided by
          https://www.intersoft-consulting.de target www.intersoft-consulting.de
          Google Analytics uses cookies which are text files placed on your
          computer, to allow the website operator to analyze how users use the
          site. The information generated by the cookie about your use of this
          website is usually transferred to a Google server in the USA and
          stored there. However, if IP anonymization is activated on this
          website, Google will reduce your IP address within Member States of
          the European Union or in other states party to the Agreement on the
          European Economic Area beforehand. Only in exceptional cases will the
          full IP address be transmitted to a Google server in the USA and
          shortened there. The IP address transmitted by your browser in the
          context of Google Analytics is not merged with other Google data. On
          behalf of the operator of this website, Google will use this
          information to evaluate your use of the website, to compile reports on
          website activity and to provide the website operator with other
          services related to website and Internet use. Our legitimate interest
          in data processing also lies in these purposes. The legal basis for
          the use of Google Analytics is § 15 para. 3 TMG and Art. 6 para. 1
          lit. f GDPR. Sessions and campaigns are terminated after a certain
          period of time. By default, sessions are closed after 30 minutes
          without activity and campaigns after six months. The time limit for
          campaigns may not exceed two years. For more information on terms of
          use and data protection, please visit
          https://www.google.com/analytics/terms/gb.html target
          https://www.google.com/analytics/terms/gb.html or
          https://policies.google.com/?hl=en target
          https://policies.google.com/?hl=en You may refuse the use of cookies
          by selecting the appropriate settings on your browser, however, please
          note that if you do this you may not be able to use the full
          functionality of this website. You can also prevent Google from
          collecting the data generated by the cookie and relating to your use
          of the website (including your IP address) and from processing this
          data by Google by downloading and installing the
          https://tools.google.com/dlpage/gaoptout?hl=en Browser Add-Opt-out
          cookies prevent the future collection of your data when you visit this
          website. To prevent Universal Analytics from collecting data across
          several devices, you must opt-out on all systems used. Google AdWords
          Conversions Google AdWords Conversions enable us to determine how
          clicks on adverts or information on Google platforms effectively lead
          to transactions and similar user actions on our website. These
          features use temporary cookies from Google and do not process user
          data. Google provides options to prevent the use of these cookies;
          alternatively, you can change the settings in your browser. Google Tag
          Manager We use Google Tag Manager to embed tags on our website. When
          an incoming http request is detected, it collects the IP address and
          stores it temporarily in order to correct any connection errors.
        </p>
        <p>Hotjar</p>
        <p>
          {" "}
          We use Hotjar in order to better understand our users’ needs and to
          optimize this service and experience. Hotjar is a technology service
          that helps us better understand our users’ experience (e.g. how much
          time they spend on which pages, which links they choose to click, what
          users do and don’t like, etc.) and this enables us to build and
          maintain our service with user feedback. Hotjar uses cookies and other
          technologies to collect data on our users’ behavior and their devices.
          This includes a device's IP address (processed during your session and
          stored in a de-identified form), device screen size, device type
          (unique device identifiers), browser information, geographic location
          (country only), and the preferred language used to display our
          website. Hotjar stores this information on our behalf in a
          pseudonymized user profile. Hotjar is contractually forbidden to sell
          any of the data collected on our behalf. For further details, please
          see the ‘about Hotjar’ section of Hotjar’s support site.
        </p>
        <p className="font-bold">3 Who we share your data with</p>
        <p>
          As far as personal data (for example name or e-mail addresses) is
          entered on our side, this takes place, as far as possible, on a
          voluntary basis. This data will not be disclosed to third parties
          without your explicit consent. Please note that data transmission over
          the Internet (for example, when communicating via e-mail) may have
          security vulnerabilities. Complete protection of the data from access
          by third parties is not possible.
        </p>

        <p className="font-bold">4 What rights you have over your data</p>
        <p>
          If you have an account on this site, or have left comments, you can
          request to receive an exported file of the personal data we hold about
          you, including any data you have provided to us. You can also request
          that we erase any personal data we hold about you. This does not
          include any data we are obliged to keep for administrative, legal, or
          security purposes.
        </p>
        <p className="font-bold">5 Additional information</p>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <p className="font-bold">6 Contact us</p>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at info@vocab.so
        </p>
      </div>
    </div>
  </div>
);

export default Privacy;
