import { Spinner } from "@chakra-ui/react";
import { Title } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import Emoji from "../../components/universal-components/emoji.component";
import LanguageLevelSelect from "./movie-components/language-level-select.component";
import MovieCard from "./movie-components/movie-card.component";
import MovieRequest from "./movie-components/movie-request.component";
import { SearchBar } from "./movie-components/search-bar.component";
import TagSelect from "./movie-components/tag-select.component";

export default function MovieLibrary() {
  const [allMovies, setAllMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [languageLevels, setLanguageLevels] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getMovies();
  }, []);

  const movieResults = useMemo(() => {
    return allMovies.filter(
      (movieEntry) =>
        (!searchTerm ||
          movieEntry.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (tags.length === 0 ||
          tags.some((tag) => movieEntry.tags.split(", ").includes(tag))) &&
        (languageLevels.length === 0 ||
          languageLevels.includes(movieEntry.language_level))
    );
  }, [allMovies, searchTerm, languageLevels, tags]);

  const getMovies = () => {
    fetch(`${process.env.REACT_APP_API_URL}/library-all/movie`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        setAllMovies(data.items);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="bg-yellow-50 min-h-screen">
      <div class="mx-auto py-8 px-4 sm:px-6 lg:px-8 space-y-8 max-w-7xl">
        <div className="space-y-2 flex flex-col lg:items-center">
          <div className="max-w-3xl lg:text-center">
            <Title order={1}>
              Movie Vocab Lists <Emoji symbol="🍿" />
            </Title>
            <p className="font-medium text-lg">
              Search for movies and series by entering the title into the search
              bar. Use the difficulty filter to find movies that fit your current
              language level.
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-4 lg:items-center">
          <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4 md:items-center">
            <SearchBar
              onChange={(value) => {
                setSearchTerm(value);
              }}
            />
            <LanguageLevelSelect
              onSelectChange={(value) => {
                if (languageLevels.includes(value)) {
                  setLanguageLevels((current) =>
                    current.filter((e) => e !== value)
                  );
                } else {
                  setLanguageLevels((current) => [...current, value]);
                }
              }}
            />
          </div>
          {/* <TagSelect onChange={setTags} /> */}
        </div>

        {movieResults.length === 0 ? (
          <div>
            {isLoading ? (
              <div className="pt-8 flex justify-center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="white"
                  color="gray.500"
                  size="lg"
                />
              </div>
            ) : (
              <MovieRequest />
            )}
          </div>
        ) : (
          <div className="grid gap-y-4 sm:gap-y-6 lg:gap-y-8 gap-x-4 xl:gap-x-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            {movieResults.map((e) => (
              <MovieCard
                image={e.image_url}
                title={e.name}
                url_param={"/movie/" + e.url_name}
                description={e.description}
                tags={e.tags.split(",")}
                wordCount={e.word_count}
                difficulty={e.difficulty}
                language_level={e.language_level}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
