export default function WordSelectionList({
  words,
  selectedWords,
  setSelectedWords,
  componentStyle,
}) {
  let wordStyle, divStyle, wordBackground, wordBackgroundSelected;
  if (componentStyle === "app") {
    wordStyle =
      "mr-2 mt-2 px-2 py-1 rounded-md text-base font-medium text-gray-900";
    divStyle = "flex flex-row flex-wrap justify-start";
    wordBackground = "bg-gray-200";
    wordBackgroundSelected = "bg-yellow-100";
  } else if (componentStyle === "quiz") {
    wordStyle =
      "mr-2 mt-2 px-2 py-1 rounded-md text-sm font-medium text-gray-900 border-2 border-gray-300";
    divStyle = "flex flex-row flex-wrap justify-start";
    wordBackground = "bg-gray-50";
    wordBackgroundSelected = "bg-red-100";
  } else {
    wordStyle =
      "mr-1.5 mt-1.5 px-2.5 py-0.5 rounded-full text-base font-medium text-gray-900 shadow-md";
    divStyle = "flex flex-row flex-wrap justify-center";
    wordBackground = "bg-white";
    wordBackgroundSelected = "bg-red-200";
  }
  return (
    <div className={divStyle}>
      {words.map((word) => (
        <button
          onClick={() => {
            if (!selectedWords.includes(word.word_pos_key)) {
              setSelectedWords([...selectedWords, word.word_pos_key]);
            } else {
              setSelectedWords(
                selectedWords.filter(
                  (word_pos_key) => word_pos_key !== word.word_pos_key
                )
              );
            }
          }}
          className={`${wordStyle} ${
            selectedWords.includes(word.word_pos_key)
              ? wordBackgroundSelected
              : wordBackground
          } `}
        >
          {word.word_display ? word.word_display : word.word_root}
        </button>
      ))}
    </div>
  );
}
