import { TextInput, Textarea, Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useRouter } from "../../../../hooks/useRouter";

const axios = require("axios").default;

export default function MovieAdminEdit({ isEdit }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState([]);
  const [text, setText] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [tags, setTags] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/library/${router.match.params.movieTitle}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setTitle(data.info.name);
          setDescription(data.info.description);
          setImageUrl(data.info.image_url);
          setIsLoading(false);
          setTags(data.info.tags);
        })
        .catch((error) => {
          window.alert(error);
          setIsLoading(false);
        });
    }
  }, [router]);

  const handleSubmit = () => {
    let data = {
      description: description,
      text: text,
      tags: tags,
      image_url: imageUrl,
      text_language: "en",
    };
    axios({
      method: isEdit ? "put" : "post",
      url: `${process.env.REACT_APP_API_URL}/library/${title}`,
      data: data,
    })
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        window.alert(
          "Something went wrong processing your file (check console for logs)"
        );
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/library/${title}`)
      .then((response) => {
        router.history.goBack()
      })
      .catch((error) => {
        window.alert("Error:", error);
      });
  };

  const handleReprocessing = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/library-reprocess/${title}`)
      .then((response) => {
        router.history.goBack();
      })
      .catch((error) => {
        window.alert("Error:", error);
      });
  }

  return (
    <>
      {isLoading && <h1 className="text-3xl">!!! Loading</h1>}
      {isEdit ? (
        <p>{title}</p>
      ) : (
        <TextInput
          placeholder="Title"
          label="Title"
          size="md"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
      )}
      <Textarea
        placeholder="Description"
        label="Description"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />
      <TextInput
        placeholder="Image URL"
        label="Image URL"
        size="md"
        value={imageUrl}
        onChange={(event) => setImageUrl(event.target.value)}
      />
      {!isEdit && (
        <Textarea
          placeholder="Text"
          label="Text"
          minRows={8}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      )}
      <TextInput
        placeholder="Movie, Action, British English"
        label="Tags -- separate by comma!!"
        size="md"
        value={tags}
        onChange={(event) => setTags(event.target.value)}
      />
      <Button onClick={handleSubmit}>Submit</Button>
      {isEdit && (
        <div className="mt-12 space-x-4">
          <Button color="red" onClick={handleDelete}>
            Delete
          </Button>
          <Button color="orange" onClick={handleReprocessing}>
            Reprocess
          </Button>
        </div>
      )}
    </>
  );
}
