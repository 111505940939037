import { useState } from "react";
import { useSelector } from "react-redux";
import FlashcardSettings from "./flashcard-settings.component";
import Flashcard from "./session-components/exercise-components/flashcard-components/flashcard.component";
import ProgressBar from "./session-components/progress-bar.component";
import { analytics } from "../../../firebase.utils";
import { logEvent } from "firebase/analytics";

export default function FlashcardSession({
  onNext,
  exercise,
  itemsDone,
  itemsOpen,
  isDisableEventListener,
}) {
  const userApiId = useSelector((state) => state.decks.user_api_id);

  const [flashcardSettings, setFlashcardSettings] = useState(null);

  const handleAction = (result) => {
    const subTask =
      flashcardSettings[0].toString() + "_" + flashcardSettings[1].toString();
    fetch(
      `${process.env.REACT_APP_API_URL}/history?main_task=${exercise["exercise_type"]}&sub_task=${subTask}&action=${result}&word_id=${exercise["word_info"]["id"]}&user_id=${userApiId}&session_uuid=${exercise["session_uuid"]}`,
      { method: "POST", redirect: "follow" }
    );

    // TODO: add language
    logEvent(analytics, "word_learned", {
      mode: "flashcards",
    });

    onNext();
  };

  return (
    <div>
      {flashcardSettings ? (
        <div className="container m-auto max-w-xl space-y-10 pb-40">
          <ProgressBar itemsDone={itemsDone} itemsOpen={itemsOpen} />
          <Flashcard
            key={exercise["session_id"]}
            exerciseInfo={exercise}
            front={flashcardSettings[0]}
            back={flashcardSettings[1]}
            onUserAction={(result) => handleAction(result)}
            disableEventListener={isDisableEventListener}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center pt-4">
          <div className="max-w-lg">
            <p className="text-base text-gray-900 font-medium p-4 bg-yellow-100 rounded-lg shadow-sm">
              Configure the fields you want to learn this session. You can
              select mutliple fields per side.
            </p>
            <FlashcardSettings
              onSubmit={(front, back) => {
                setFlashcardSettings([front, back]);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}