import React from "react";
import SingleTranslation from "./single-translation.component"
import SingleExampleSentence from "./single-example-sentence.component";

class VocabularyList extends React.Component {
    render() {
        const vocabArray = this.props.vocab
        return(
            <div className="flex flex-col mt-12">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                
                <div className="sm:sr-only rounded-md bg-yellow-100 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                                Limited Funcionality On Mobile
                            </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                            <p>
                            Please view on desktop to view all example sentences and export your vocabulary list.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>


                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Word
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Count
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    POS
                                </th>
                                { !(this.props.language === "en") && 
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-sm">
                                    Translation
                                </th>
                                }
                                <th scope="col" className="sr-only sm:not-sr-only px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Sentence
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {vocabArray.map(entry => 
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate max-w-xs">
                                        {entry.root}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                            {entry["word-count"]}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            {entry.pos}
                                        </span>
                                    </td>
                                    { !(this.props.language === "en") && 
                                    <SingleTranslation  tokenPosition={(entry.words[0]["token-position"])} 
                                                        word={entry.root} 
                                                        pos={entry.pos} sourceLanguage={this.props.language} targetLanguage="en"/>
                                    }
                                    
                                    <SingleExampleSentence sentenceList={entry.words} />        
                                    
                                </tr>
                            )}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VocabularyList;