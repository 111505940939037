import SectionHeading from "../../../pages/app/app-page-components/section-heading.component";
import LibraryList from "./library-components/library-list.component";

export default function Library() {
  return (
    <div>
      <SectionHeading
        headingText="Library"
        isButton={false}
        buttonText=""
        buttonLink=""
      />
      <LibraryList />
    </div>
  );
}