import React from "react";
import { PosLabel } from "../../../../../universal-components/pos-label.component";
import EditDefinitions from "./edit-definitions.component";
import EditTranslations from "./edit-translations.component";

const FlashcardContent = ({ activeFields, contentInfo }) => (
  <div
    className={`flex flex-col space-y-4 text-md ${
      activeFields.length <= 2 && "text-center"
    }`}
  >
    {activeFields.includes("pos") && <PosLabel pos={contentInfo["pos"]} />}

    {activeFields.includes("tags") && (
      <div>
        <span className="text-xs font-medium text-gray-900 bg-yellow-400 px-2.5">
          {contentInfo["tags"] ? contentInfo["tags"] : "No tags"}
        </span>
      </div>
    )}

    {activeFields.includes("word_root") && (
      <div>
        <span class="text-lg font-medium text-gray-900 bg-yellow-100 px-2.5">
          {contentInfo["pos"] === "VERB" && "to "}
          {contentInfo["word_root"]}
        </span>
      </div>
    )}

    {activeFields.includes("cloze") && (
      <div className="flex flex-col px-2.5">
        {activeFields.length > 1 && <p className="font-bold">Cloze:</p>}
        <p>
          {contentInfo["example_pre_word"]}
          <span className="px-8 border-b-2 mr-2 ml-1 border-gray-700"></span>
          {contentInfo["example_post_word"]}
        </p>
      </div>
    )}

    {activeFields.includes("translation") && (
      <div>
        {contentInfo["translation"] ? (
          <span class="bg-green-50 px-2.5 font-medium">
            {contentInfo["translation"]}
          </span>
        ) : (
          <EditTranslations
            id={contentInfo["id"]}
            word={contentInfo["word_root"]}
            pos={contentInfo["pos"]}
            deckId={contentInfo["deck_id"]}
          />
        )}
      </div>
    )}

    {activeFields.includes("synonyms") && (
      <div>
        <span className="text-xs font-medium text-gray-900 bg-blue-100 px-2.5">
          {contentInfo["synonyms"] ? contentInfo["synonyms"] : "No synonyms"}
        </span>
      </div>
    )}

    {activeFields.includes("definition") && (
      <>
        {contentInfo["definition"] &&
        JSON.parse(contentInfo["definition"]).length > 0 ? (
          <div className="flex flex-col px-2.5">
            {activeFields.length > 2 && (
              <p className="text-md font-bold">Definition:</p>
            )}
            <p className="italic">
              {JSON.parse(contentInfo["definition"]).map((e, i) => (
                <div className="">
                  <b>{i + 1}.</b> {e[0]}
                </div>
              ))}
            </p>
          </div>
        ) : (
          <EditDefinitions
            id={contentInfo["id"]}
            word={contentInfo["word_root"]}
            pos={contentInfo["pos"]}
          />
        )}
      </>
    )}

    {activeFields.includes("example") && (
      <div className="flex flex-col px-2.5">
        {activeFields.length > 1 && <p className="font-bold">Example:</p>}
        <p>
          {contentInfo["example_pre_word"]}
          <span class="bg-yellow-100 italic">
            {contentInfo["word_original"]}
          </span>{" "}
          {contentInfo["example_post_word"]}
        </p>
      </div>
    )}
  </div>
);

export default FlashcardContent;
