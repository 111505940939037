import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";

const DeckListItem = ({
  key,
  name,
  word_count,
  words_due_count,
  words_in_progress_count,
  words_mastered_count,
  id,
}) => (
  <Link to={`/app/learn/${id}`}>
    <li
      key={key}
      className="col-span-1 flex flex-col bg-white rounded-lg shadow-md hover:bg-gray-50"
    >
      <div className="m-2">
        {words_due_count > 0 ? (
          <div className="flex flex-row justify-end">
            <span className="px-2 py-0.5 rounded text-xs font-medium bg-red-200 text-yellow-800 mb-2">
              Due {words_due_count}
            </span>
          </div>
        ) : (
          <div className="h-6" />
        )}

        <div class="flex-1 flex flex-col pb-5 px-6 font-medium text-lg text-gray-900">
          {name}
          <p className="text-sm font-normal text-gray-700">
            {word_count} words
          </p>
        </div>
      </div>

      <div className="overflow-hidden h-3 text-xs flex bg-gray-200 rounded-b-lg">
        {/* TODO: Change this to "new"? --> words that haven't been learned yet */}
        <Tooltip
          label={`${words_due_count} words due now`}
          placement="top"
          openDelay={200}
        >
          <div
            style={{ width: `${(words_due_count / word_count) * 100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-200"
          ></div>
        </Tooltip>
        {/* TODO: Change this to "in progress"? --> based on easiness */}
        <Tooltip
          label={`${words_in_progress_count} words due within two weeks`}
          placement="top"
          openDelay={200}
        >
          <div
            style={{
              width: `${(words_in_progress_count / word_count) * 100}%`,
            }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-200"
          ></div>
        </Tooltip>
        {/* TODO: Change this to "mastered"? --> based on easiness */}
        <Tooltip
          label={`${words_mastered_count} words due in over two weeks`}
          placement="top"
          openDelay={200}
        >
          <div
            style={{ width: `${(words_mastered_count / word_count) * 100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-200"
          ></div>
        </Tooltip>
      </div>
    </li>
  </Link>
);

export default DeckListItem;
