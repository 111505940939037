import { Radio, Stack, RadioGroup, Button } from "@chakra-ui/react";
import { useState } from "react";

export default function ExampleSelect({ wordInfo, onSubmit }) {
  const [value, setValue] = useState(null);

  return (
    <div>
      <RadioGroup
        onChange={(event) => {
          console.log(event);
          setValue(event);
          onSubmit(event);
        }}
        value={value}
      >
        <Stack direction="column">
          {wordInfo.original_words_list.map((e, i) => (
            <Radio value={i.toString()}>{e.sent}</Radio>
          ))}
        </Stack>
      </RadioGroup>
      {/* {value && <Button onClick={() => onSubmit(value)}>Submit</Button>} */}
    </div>
  );
}
