import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import MultiOptionField from "./multi-option-field.component";

export default function Translations({
  wordInput,
  sourceLanguage,
  targetLanguage,
  wordPos,
  onTranslationChange
}) {
  const [translationSuggestions, setTranslationSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // TODO: word_root used for both word_original and word_root (think about what we want to translate)
    fetch(
      `${process.env.REACT_APP_API_URL}/translation?word_original=${wordInput}&source=${sourceLanguage}&target=${targetLanguage}&pos=${wordPos}&word_root=${wordInput}`,
      { method: "GET", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        setTranslationSuggestions(data.map((e) => e.word));
        setIsLoading(false);
      })
      .catch((error) => window.alert(error));
  }, []);

  return (
    <div className="flex flex-row flex-wrap items-center">
      {isLoading ? (
        <div className="pb-2 mr-2">
          <Spinner size="sm" />
        </div>
      ) : (
        <MultiOptionField
          isSuggestions
          suggestions={translationSuggestions}
          onChange={(value) => onTranslationChange(value)}
          placeholderText="Add a translation"
        />
      )}
    </div>
  );
}
