import React from "react";
import { Search } from "tabler-icons-react";
import { Input } from "@mantine/core";

export function SearchBar({onChange}) {
  return (
    <Input
      icon={<Search size={18} />}
      color="orange"
      placeholder="Search by title"
      size="md"
      radius="md"
      onChange={(event) => onChange(event.target.value)}
      className="md:w-2/5 lg:w-96"
    />
  );
}
