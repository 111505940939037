import { combineReducers } from "redux";
import decksReducer from "./decks/decks.reducer";

import userReducer from "./user/user.reducer";

export default combineReducers(
    {
        user: userReducer,
        decks: decksReducer
    }
);

// this combines all reducers