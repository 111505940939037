import MovieAdminEdit from "./movie-admin-components/movie-admin-edit.component";
import { Switch, Route, Link } from "react-router-dom";
import MovieAdminList from "./movie-admin-components/movie-admin-list.component";

export default function MovieAdmin() {
  return (
    <div class="max-w-7xl mx-auto px-6 pt-12 space-y-6">
      <Switch>
        <Route exact path="/app/movie-admin">
          <Link to="/app/movie-admin/add">Add New</Link>
          <MovieAdminList />
        </Route>
        <Route path="/app/movie-admin/add">
          <MovieAdminEdit isEdit={false} />
        </Route>
        <Route path="/app/movie-admin/edit/:movieTitle">
          <MovieAdminEdit isEdit={true} />
        </Route>
      </Switch>
    </div>
  );
}
