import React from "react";
import { Skeleton, Stack } from "@chakra-ui/react";

// Written this weird so that all rows start the animation at the same time
function LoadingSkeleton({ rows, height }) {
  const skeleton = [...Array(rows)].map(() => <Skeleton height={height} />);
  return <Stack>{skeleton}</Stack>;
}

LoadingSkeleton.defaultProps = {
  rows: 3,
  height: "20px",
};

export default LoadingSkeleton;
