import { Progress } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "../../hooks/useRouter";
import SectionHeading from "../../pages/app/app-page-components/section-heading.component";

export default function Scan() {
  const [text, setText] = useState("");
  const [progress, setProgress] = useState(null);
  const [running, setRunning] = useState(false);
  const divRef = useRef(null);

  const router = useRouter();
  const userApiId = useSelector((state) => state.decks.user_api_id);

  useEffect(() => {
    if (userApiId && router.query && !running) {
      setRunning(true);
      updateText();
    }
  }, [router, userApiId]);

  const token = useSelector((state) => state.user.token);

  const updateText = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/text-layover/${router.query.layover_id}`,
      { method: "GET" }
    )
      .then((res) => res.json())
      .then((data) => {
        setText(data.text);
        setProgress(data.status);
        if (data.status < 1) {
          setTimeout(updateText, 5000);
        } else {
          setTimeout(processText, 3000);
        }
        divRef.current.scrollIntoView({ behavior: "smooth" });
      });
  };

  const processText = () => {
    const headers = new Headers({ Authorization: token });

    const submissionMode = "layover";

    fetch(
      `${process.env.REACT_APP_API_URL}/convert-file/0?submission_mode=${submissionMode}&layover_id=${router.query.layover_id}&user_id=${userApiId}`,
      { method: "POST", headers: headers }
    )
      .then((res) => res.json())

      .then((data) =>
        router.push(`/app/text-view/text/${data.deck_id}/${data.text_id}`)
      )
      .catch((error) => console.log(error));
  };

  return (
    <div className="bg-yellow-50 max-w-full px-12 h-screen flex flex-col justify-center pb-6">
      <div className="max-w-7xl mx-auto mb-8 py-2 px-4 flex flex-col justify-start space-y-2 border-l-4 border-gray-700">
        <h1 className="text-2xl font-bold">
          Please do not close the previous window!
        </h1>
        <p className="text-base font-medium">
          Please do not close or reload read.amazon.com -{" "}
          <b>otherwise the scanning process will be stopped.</b> Below you can
          follow the scanning progress. Once the scanning is completed the text
          will be added to your account automatically.
        </p>
      </div>
      <div className="max-w-7xl mx-auto w-full">
        <Progress value={progress * 100} colorScheme="yellow" />
        <div className="max-w-7xl mx-auto bg-white shadow-md pt-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <SectionHeading
              headingText={router.query.title}
              isButton={false}
              buttonText=""
              buttonLink=""
            />
          </div>
          <p className="h-96 overflow-auto px-4 sm:px-6 lg:px-8">
            {text}
            <div ref={divRef} className="pb-8" />
          </p>
        </div>
      </div>
    </div>
  );
}
