import { getHotkeyHandler } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WordLevel from "../session-components/exercise-components/flashcard-components/word-level.component";
import { editDistance } from "../../../../utils";
import { analytics } from "../../../../firebase.utils";
import { logEvent } from "firebase/analytics";
import ClozeInput from "./cloze-components/cloze-input.component";

export default function QuizCloze({ wordInfo, field, onAction, sessionUUID }) {
  const [answer, setAnswer] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [correct, setCorrect] = useState(true);
  const [exampleIndex, setExampleIndex] = useState(0);

  const userApiId = useSelector((state) => state.decks.user_api_id);

  useEffect(() => {
    setAnswer(wordInfo["word_original"]);
  }, [wordInfo, exampleIndex]);

  const handleAction = (clozeInput) => {
    let action = 0;
    if (clozeInput.toLowerCase() === answer.toLowerCase()) {
      if (clozeInput !== answer) {
        onAction("correct", "Well done!");
      } else {
        onAction("correct", "Exactly!");
      }
      setCorrect(true);
      action = 5;
    } else if (
      editDistance(clozeInput.toLowerCase(), answer.toLowerCase()) <= 1
    ) {
      onAction("correct", "That's almost it!");
      setCorrect(true);
      action = 4;
    } else if (
      clozeInput.toLowerCase() === wordInfo["word_root"].toLowerCase()
    ) {
      onAction("incorrect", "Correct word, but the form is wrong.");
      setCorrect(false);
      action = 3;
    } else if (
      editDistance(
        clozeInput.toLowerCase(),
        wordInfo["word_root"].toLowerCase()
      ) <= 1
    ) {
      onAction("incorrect", "Close, but the form is wrong.");
      setCorrect(false);
      action = 2;
    } else {
      onAction("incorrect", answer);
      setCorrect(false);
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/history?main_task=cloze&sub_task=${field}&action=${action}&word_id=${wordInfo["id"]}&user_id=${userApiId}&session_uuid=${sessionUUID}`,
      { method: "POST", redirect: "follow" }
    );
    logEvent(analytics, "word_added", {
      language: "en",
      session_type: "quiz",
      exercise_type: "cloze",
    });
    setIsSubmitted(true);
  };

  return (
    <div>
      <div className="flex justify-start mx-4">
        {field === "translation" && (
          <div className="flex space-x-2 items-center">
            {/* <p className="font-medium">Translations:</p> */}
            <span class="bg-green-50 px-2.5 font-medium text-xl">
              {wordInfo["translation"]}
            </span>
          </div>
        )}
        {field === "definition" && (
          <div className="flex flex-col p-1 rounded-md">
            <p className="italic text-lg">
              {JSON.parse(wordInfo["definition"]).map((e, i) => (
                <div>
                  <b>{i + 1}.</b> {e[0]}
                </div>
              ))}
            </p>
          </div>
        )}
      </div>
      <ClozeInput
        key={1}
        preClozeWord={wordInfo["example_pre_word"]}
        postClozeWord={wordInfo["example_post_word"]}
        clozeWord={wordInfo["word_original"]}
        isSubmitted={isSubmitted}
        isCorrect={correct}
        onSubmit={(value) => handleAction(value)}
        isLight
      >
        {/*         {wordInfo.original_words_list.length > 1 ? (
          <div className="flex justify-end mt-3 mr-2">
            <button
              onClick={() => {
                if (wordInfo.original_words_list.length <= exampleIndex + 1) {
                  setExampleIndex(0);
                } else {
                  setExampleIndex((prev) => prev + 1);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div className="h-4"></div>
        )} */}
      </ClozeInput>
    </div>
  );
}
